import eventBus from '@/lib/event_bus';
import { get, isEmpty } from '@/lib/helpers';
import { getNavigationRoute, updateLayoutConfig } from '@/lib/layout_config';
import { layoutConfig } from '@/lib/layout_consts';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { isMicroappsEnabled } from '@/lib/utils';
import { retrieveBigCookie } from '@/store/actions/customer';
import { authState } from '@/store/getters/auth';

const beforeEnter = async (to: any, from: any, next: any) => {
  if (isMicroappsEnabled()) {
    updateLayoutConfig('GPAY_LAYOUT');
  } else {
    updateLayoutConfig('DEFAULT_LAYOUT');
  }
  try {
    eventBus.$emit('loader', {
      show: true,
      message: 'Loading...',
    });
    if (isCustomerAuthenticatedSync(authState())) {
      await retrieveBigCookie(false, 'gpayQualRoutes:beforeEnter');
    } else {
      if (isMicroappsEnabled()) {
        next();
      } else {
        next({
          path: '/qual',
        });
      }
    }
    let navigationRoute;
    if (to && to.name) {
      navigationRoute = getNavigationRoute(undefined, undefined, to.name);
    } else {
      navigationRoute = getNavigationRoute();
    }
    const { targetRoute = '', query = {}, params = {} } = navigationRoute;
    if (targetRoute && ['qual', 'gpay-qual'].includes(targetRoute)) {
      if (isMicroappsEnabled()) {
        next();
      } else {
        next({ path: '/qual' });
      }
    } else if (targetRoute !== to.name) {
      next({
        name: targetRoute,
        query: !isEmpty(query) ? query : undefined,
        params: !isEmpty(params) ? params : undefined,
      });
    } else {
      if (isMicroappsEnabled()) {
        next();
      } else {
        next({ path: '/qual' });
      }
    }
  } catch (err) {
    console.log('Error while getting customer details', err);
    if (isMicroappsEnabled()) {
      next();
    } else {
      next({ path: '/qual' });
    }
  } finally {
    eventBus.$emit('loader');
  }
};

export const gpayQualRoutes = {
  path: '/gq',
  component: () =>
    import(/* webpackChunkName: "gpay-qual" */ '@/layouts/GpayQualLayout.vue'),
  meta: { requiresAuth: true },
  beforeEnter,
  children: [
    {
      path: '',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.qualHome', ''),
      redirect: {
        name: get(layoutConfig, 'GPAY_LAYOUT.routes.quickProfile', ''),
      },
    },
    {
      path: 'quick-profile',
      meta: { phoneRequired: false },
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.quickProfile', ''),
      component: () =>
        import(
          /* webpackChunkName: "gp-quick-prof" */ '@/views/qual/QuickProfile.vue'
        ),
      props: { phoneRequired: false },
    },
    {
      path: 'residence-details',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.residenceDetails', ''),
      component: () =>
        import(
          /* webpackChunkName: "gp-resi-deti" */ '@/views/qual/ResidenceDetails.vue'
        ),
    },
    {
      path: 'financial-details',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.financialDetails', ''),
      component: () =>
        import(
          /* webpackChunkName: "gp-fin-deti" */ '@/views/qual/FinancialDetails.vue'
        ),
    },
    {
      path: 'submit-application',
      meta: { gpayPhoneRequest: true },
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.submitApplication', ''),
      component: () =>
        import(
          /* webpackChunkName: "gp-submit-app" */ '@/views/qual/SubmitApplication2.vue'
        ),
      props: { gpayPhoneRequest: true },
    },
    {
      path: 'rejected',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.rejectedApplication', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "dl-reject-app" */ '@/views/qual/RejectScreen.vue'
        ),
    },
    {
      path: 'approved',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.approvedApplication', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "gp-approve-app" */ '@/views/qual/ApprovedApplication.vue'
        ),
    },
    {
      path: 'perfios',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.perfiosValidation', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "gp-perfios" */ '@/views/qual/PerfiosValidation.vue'
        ),
    },
    {
      path: 'credit-eligibility',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.creditEligibility', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "gp-credit-eligible" */ '@/views/qual/CreditEligibility.vue'
        ),
    },
    {
      path: 'no-response',
      name: get(layoutConfig, 'GPAY_LAYOUT.routes.noResponse', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "gp-no-resp" */ '@/views/qual/NoResponse.vue'
        ),
    },
  ],
};
