import { setLanguage } from '@/store/actions/app_config';
import { currentLanguage } from '@/store/getters/app_config';
import { isEmpty } from '@/lib/helpers';

export const supportedLanguages = ['en'];

export function updateLanguage(language: string) {
  setLanguage({
    payload: {
      language,
    },
  });
}

export async function getString(stringKey: string, args: any) {
  const language = currentLanguage();
  // TODO: Revisit for perf improvement probably store in localstorage
  const fileName = args.fileName;
  const stringConstantsFile = await import(`@/locales/${language}/${fileName}`);
  const stringConstants = stringConstantsFile.default;
  if (!isEmpty(args.params)) {
    return stringConstants[stringKey](args.params);
  }
  return stringConstants[stringKey];
}
