type Nullable<T> = T | null;
import { isEmpty } from '@/lib/helpers';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import Address from './address';
import Duration from './duration';
export default class BusinessCard {
  public static getInstance() {
    if (!BusinessCard.instance || !isCustomerAuthenticatedSync(authState())) {
      BusinessCard.instance = new BusinessCard();
    }
    return BusinessCard.instance;
  }
  private static instance: BusinessCard | null;

  private _name?: Nullable<string>;
  private _employeeID?: string;
  private _emailByOCR?: string;
  private _officeEmail?: string;
  private _companyName?: string;
  private _companyType?: string;
  private _employmentType?: string;
  private _jobTitle?: string;
  private _jobType?: string;
  private _joiningDate?: string;
  private _address?: Address;
  private _currentExperienceDuration?: Duration;
  private _totalExperienceDuration?: Duration;
  private _officePhone?: string;
  private _lineOfBusiness?: string;
  private _isWorkHomeAddressSame?: string;
  private _minMonthlyTurnover?: number;
  private _maxMonthlyTurnover?: number;

  private constructor() {
    this._name = null;
    this._employeeID = '';
    this._emailByOCR = '';
    this._officeEmail = '';
    this._companyName = '';
    this._companyType = '';
    this._employmentType = '';
    this._jobTitle = '';
    this._jobType = '';
    this._joiningDate = '';
    this._address = new Address();
    this._currentExperienceDuration = new Duration(0, 0);
    this._totalExperienceDuration = new Duration(0, 0);
    this._officePhone = '';
    this._lineOfBusiness = '';
    this._isWorkHomeAddressSame = '';
    this._minMonthlyTurnover = 0;
    this._maxMonthlyTurnover = 0;
  }

  public get name(): Nullable<string> {
    return this._name || '';
  }

  public set name(name: Nullable<string>) {
    this._name = name;
  }

  public get employeeID(): string {
    return this._employeeID || '';
  }

  public set employeeID(employeeID: string) {
    this._employeeID = employeeID;
  }

  public get emailByOCR(): string {
    return this._emailByOCR || '';
  }

  public set emailByOCR(emailByOCR: string) {
    this._emailByOCR = emailByOCR;
  }

  public get officeEmail(): string {
    return this._officeEmail || '';
  }

  public set officeEmail(officeEmail: string) {
    this._officeEmail = officeEmail;
  }

  public get companyName(): string {
    return this._companyName || '';
  }

  public set companyName(companyName: string) {
    this._companyName = companyName;
  }

  public get companyType(): string {
    return this._companyType || '';
  }

  public set companyType(companyType: string) {
    this._companyType = companyType;
  }

  public get employmentType(): string {
    return this._employmentType || '';
  }

  public set employmentType(employmentType: string) {
    this._employmentType = employmentType;
  }

  public get jobTitle(): string {
    return this._jobTitle || '';
  }

  public set jobTitle(jobTitle: string) {
    this._jobTitle = jobTitle;
  }

  public get jobType(): string {
    return this._jobType || '';
  }

  public set jobType(jobType: string) {
    this._jobType = jobType;
  }

  public get joiningDate(): string {
    return this._joiningDate || '';
  }

  public set joiningDate(joiningDate: string) {
    this._joiningDate = joiningDate;
  }

  public get address(): Address {
    return this._address || new Address();
  }

  public set address(address: Address) {
    this._address = address;
  }

  public get currentExperienceDuration(): Duration {
    return this._currentExperienceDuration || new Duration(0, 0);
  }

  public set currentExperienceDuration(currentExperienceDuration: Duration) {
    this._currentExperienceDuration = currentExperienceDuration;
  }

  public get totalExperienceDuration(): Duration {
    return this._totalExperienceDuration || new Duration(0, 0);
  }

  public set totalExperienceDuration(totalExperienceDuration: Duration) {
    this._totalExperienceDuration = totalExperienceDuration;
  }

  public get officePhone(): string {
    return this._officePhone || '';
  }

  public set officePhone(officePhone: string) {
    this._officePhone = officePhone;
  }

  public get lineOfBusiness(): string {
    return this._lineOfBusiness || '';
  }

  public set lineOfBusiness(lineOfBusiness: string) {
    this._lineOfBusiness = lineOfBusiness;
  }

  public get isWorkHomeAddressSame(): string {
    return this._isWorkHomeAddressSame || '';
  }

  public set isWorkHomeAddressSame(isWorkHomeAddressSame: string) {
    this._isWorkHomeAddressSame = isWorkHomeAddressSame;
  }

  public get minMonthlyTurnover(): number {
    return this._minMonthlyTurnover || 0;
  }

  public set minMonthlyTurnover(minMonthlyTurnover: number) {
    this._minMonthlyTurnover = minMonthlyTurnover;
  }

  public get maxMonthlyTurnover(): number {
    return this._maxMonthlyTurnover || 0;
  }

  public set maxMonthlyTurnover(maxMonthlyTurnover: number) {
    this._maxMonthlyTurnover = maxMonthlyTurnover;
  }

  public clearInstance() {
    BusinessCard.instance = null;
  }

  public setBusinessCardDetails(businessDetails: BusinessCard) {
    if (!isEmpty(businessDetails)) {
      this._name = businessDetails.name;
      this._employeeID = businessDetails.employeeID;
      this._emailByOCR = businessDetails.emailByOCR;
      this._officeEmail = businessDetails.officeEmail;
      this._companyName = businessDetails.companyName;
      this._companyType = businessDetails.companyType;
      this._employmentType = businessDetails.employmentType;
      this._jobTitle = businessDetails.jobTitle;
      this._jobType = businessDetails.jobType;
      this._joiningDate = businessDetails.joiningDate;
      this._address = businessDetails.address;
      this._currentExperienceDuration = new Duration().setDuration(
        businessDetails.currentExperienceDuration
      );
      this._totalExperienceDuration = new Duration().setDuration(
        businessDetails.totalExperienceDuration
      );
      this._officePhone = businessDetails.officePhone;
      this._lineOfBusiness = businessDetails.lineOfBusiness;
      this._isWorkHomeAddressSame = businessDetails.isWorkHomeAddressSame;
      this._minMonthlyTurnover = businessDetails.minMonthlyTurnover;
      this._maxMonthlyTurnover = businessDetails.maxMonthlyTurnover;
    }
  }
}
