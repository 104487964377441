export default class GeoLocation {
  private _latitude?: number;
  private _longitude?: number;

  public get latitude(): number {
    return this._latitude || 0;
  }

  public set latitude(latitude: number) {
    this._latitude = latitude;
  }

  public get longitude(): number {
    return this._longitude || 0;
  }

  public set longitude(longitude: number) {
    this._longitude = longitude;
  }

  constructor(latitude?: number, longitude?: number) {
    this._latitude = latitude || NaN;
    this._longitude = longitude || NaN;
  }
}
