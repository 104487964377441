import { magicLinkApiClient, magicLinkActionApiClient } from './fetch_helper';
import { uuid } from '@/lib/utils';
import { EnachProvider } from '@/interfaces';
import { fetchHandler } from './utils';
import { magicLinkState } from '@/store/getters/magic_link';
import { get } from '@/lib/helpers';
import { store } from '@/store';
import { getLocalKey } from '@/store/local_store';
import { CURRENT_ACTION_ID, LINK_ID } from '@/store/store_keys';
import BankDetail from '@/business/models/bank_detail';

/**
 * GET Get relevant basic information for display
 * @param {String} linkId
 * @returns {Promise}
 */
export function fetchCustomerData(linkId: string): Promise<any> {
  return magicLinkApiClient.fetch(`/fetch?token=${linkId}`);
}

/**
 * POST Submit terms and conditions
 * @param {Object} payload
 * @returns {Promise}
 */
export function submitTermsAndConditions(payload: any): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/doc/tnc', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * POST Submit moneytap terms and conditions
 * @param {Object} payload
 * @returns {Promise}
 */
export function submitMoneyTapTnc(payload: any): Promise<any> {
  return magicLinkActionApiClient.fetch('/partner/lead/tnc', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * POST Get Esign document for m-otp signing
 * @returns {Promise}
 */
export function getEsignDocument(): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/doc/esign', {
    method: 'POST',
  });
}

/**
 * POST Send OTP to customer
 * @param {Object} payload
 * @returns {Promise}
 */
export function sendOtp(payload: any): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/otp/send', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * POST Verify otp entered by the customer
 * @param {Object} postData
 * @returns {Promise}
 */
export function verifyOtp(postData: any): Promise<any> {
  return magicLinkActionApiClient.fetch(`/v1/customer/otp/verify`, {
    method: 'POST',
    body: JSON.stringify(postData),
  });
}

/**
 * GET Get info to start okyc
 */
export function initiateAadhaarOkyc() {
  return magicLinkActionApiClient.fetch('/v1/okyc/init');
}

/**
 * POST Process okyc response
 * @param payload
 */
export function processOkycResponse(payload: any): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/okyc/process', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Initiate E-mandate
 * @param {EnachProvider} payload
 * @returns {Promise}
 */
export function initiateEmandate(payload: EnachProvider): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/repayment/init', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Handle Emandate success
 * @param {any} payload
 * @returns {Promise}
 */
export function intimateEmandateSuccess(payload: any): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/repayment/handle', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Update customer beneficiary details
 * @param {Object} payload
 * @returns {Promise}
 */
export function updateBeneficiary(payload: any): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/beneficiary', {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(payload),
  });
}

export function getHeaders() {
  const { access_token: accessToken } = magicLinkState();
  const state: any = store.getState();
  let linkId = get(state, 'magicLink.linkId', '');
  if (!linkId) {
    linkId = getLocalKey(LINK_ID);
  }
  let currentActionId = get(state, 'magicLink.currentActionId', '');
  if (!currentActionId) {
    currentActionId = getLocalKey(CURRENT_ACTION_ID);
  }

  const headers: any = {
    Authorization: `Bearer ${accessToken}`,
    MagicLinkId: linkId,
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
    'mt-request-id': uuid(),
  };
  if (currentActionId) {
    headers.MagicLinkActionId = currentActionId;
  }
  return headers;
}

/**
 * POST Upload document
 * @param {Object} payload
 * @param {String} source
 * @param {String} action
 * @returns {Promise}
 */
export async function uploadFile(
  payload: any,
  source: string = 'APPUSER',
  action: string = 'kyc_upload_pending'
): Promise<any> {
  const headers: any = getHeaders();
  const response: any = await fetch(
    `${process.env.VUE_APP_API_BASE_URL}/v1/customer/magic_link/upload/files?source=${source}&action=${action}`,
    {
      method: 'POST',
      body: payload,
      headers,
    }
  );
  return fetchHandler(response);
}

/**
 * POST Upload selfie
 * @param {Object} payload
 * @returns {Promise}
 */
export async function uploadAvatar(payload: any): Promise<any> {
  const headers: any = getHeaders();
  const response: any = await fetch(
    `${process.env.VUE_APP_API_BASE_URL}/v1/customer/magic_link/file/avatar`,
    {
      method: 'POST',
      body: payload,
      headers,
    }
  );
  return fetchHandler(response);
}

/**
 * GET Get limit increase variables
 * @returns {Promise}
 */
export function fetchLimitIncreaseVars(): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/limit-increase/fetch');
}

/**
 * POST Submit moneytap terms and conditions
 * @param {Object} payload
 * @returns {Promise}
 */
export function submitLimitIncreaseConsent(payload: any): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/limit-increase/consent', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * POST Get access token
 * Used for all subsequent requests
 * @returns {Promise}
 */
export function getAccessToken(): Promise<any> {
  const formData = `grant_type=client_credentials`;
  const authHeader = btoa(
    `${process.env.VUE_APP_MAGIC_LINK_ID}:${process.env.VUE_APP_MAGIC_LINK_KEY}`
  );
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/oauth/token`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${authHeader}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
      'mt-request-id': uuid(),
    },
    body: formData,
  }).then(fetchHandler);
}

/**
 * POST Get access token for refresh token
 * Used for all subsequent requests
 * @param {String} refreshToken
 * @returns {Promise}
 */
export function updateAccessToken(refreshToken: string): Promise<any> {
  const formData = `grant_type=refresh_token&refresh_token=${refreshToken}`;
  const authHeader = btoa(
    `${process.env.VUE_APP_MAGIC_LINK_ID}:${process.env.VUE_APP_MAGIC_LINK_KEY}`
  );
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/oauth/token`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${authHeader}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
      'mt-request-id': uuid(),
    },
    body: formData,
  }).then(fetchHandler);
}

/**
 * GET Returns application data based on application id
 * @param applicationId
 * @param sections
 * @returns {Promise}
 */
export function getApplicationData(applicationId: string): Promise<any> {
  const queryParams = {
    sections: 'dataCollection',
  };
  return magicLinkActionApiClient.fetch(
    `/v3/portal/applications/${applicationId}/customer-data?${new URLSearchParams(
      queryParams
    )}`
  );
}

/**
 * POST Save required data
 * @param payload
 * @returns {Promise}
 */
export function updateApplicationData(payload: any): Promise<any> {
  return magicLinkActionApiClient.fetch(
    '/v2/customer/data-collection/customer-details',
    {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(payload),
    }
  );
}

/**
 * GET Process okyc MetaData
 * @param linkId
 */
export function getOkycMetaData(linkId: any): Promise<any> {
  return magicLinkActionApiClient.fetch(
    `/v1/magic_link/fetch-kyc-metadata?token=${linkId}`
  );
}

/**
 * GET Process finance report MetaData
 * @param linkId
 */
export function getFinanceReportMetaData(linkId: any): Promise<any> {
  return magicLinkActionApiClient.fetch(
    `/v1/magic_link/fetch-bank-statement-metadata?token=${linkId}`
  );
}

/**
 * POST Initiate hyperverge okyc
 * @param payload
 * @returns {Promise}
 */
export function initiateHypervergeOkyc(
  payload: Record<string, string>
): Promise<any> {
  return magicLinkActionApiClient.fetch('/kyc/v1/okyc/initiate', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Verify captcha and send otp
 * @param payload
 * @returns {Promise}
 */
export function verifyCaptchaAndSendOtp(
  payload: Record<string, string>
): Promise<any> {
  return magicLinkActionApiClient.fetch('/kyc/v1/okyc/get-otp', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Verify otp and get aadhaar details
 * @param payload
 * @returns {Promise}
 */
export function verifyOtpAndGetAadhaarDetails(
  payload: Record<string, string>
): Promise<any> {
  return magicLinkActionApiClient.fetch('/kyc/v1/okyc/get-aadhaar-details', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}
/**
 * GET Get city and state from pincode
 * @param pincode
 * @returns {Promise}
 */
export function lookupPincode(pincode: string): Promise<any> {
  if (pincode && pincode.length === 6) {
    return magicLinkActionApiClient.fetch(`/lookup/pincode/${pincode}`);
  }
  return Promise.reject('Pincode cannot be empty');
}

/**
 * Get Requirement Details Config
 * @returns {Promise}
 */
export function getRequirementDetailsConfig(applicationId: any): Promise<any> {
  return magicLinkActionApiClient.fetch(
    `/v3/portal/config/${applicationId}/requirement-details`
  );
}

/**
 * GET Get hyperverge access token
 * @returns {Promise}
 */
export async function getHypervergeToken(): Promise<any> {
  return magicLinkActionApiClient.fetch('/kyc/v1/akyc/auth');
}

/**
 * POST Get perfios encrypted payload
 * @param {any} payload
 * @returns {Promise}
 */
export function getPerfiosRequestPayload(payload: any): Promise<any> {
  return magicLinkActionApiClient.post(
    `/v2/customer/netbanking/payload`,
    payload
  );
}

/**
 * POST Get status of transaction for a given transaction id
 * @param {any} payload
 * @returns {Promise}
 */
export function getPerfiosTxnStatus(payload: any): Promise<any> {
  return magicLinkActionApiClient.post('/v2/customer/netbanking/txnStatus', {
    transactionId:
      get(payload, 'txnId', '') ||
      get(payload, 'perfiosProcessPayload.transactionId', ''),
  });
}

/**
 * POST Get AA payload
 * @param {any} payload
 * @returns {Promise}
 */
export function getAARequestPayload(payload: any): Promise<any> {
  return magicLinkActionApiClient.post(
    '/v2/accountaggregator/create-consent',
    payload
  );
}

/**
 * POST Get status of transaction for a given transaction id
 * @param {any} payload
 * @returns {Promise}
 */
export function getAATxnStatus(payload: any): Promise<any> {
  return magicLinkActionApiClient.post(
    '/v2/accountaggregator/getStatus',
    payload
  );
}

/**
 * GET Get financial details for a given customer
 * @returns {Promise}
 */
export function getFinanceDetails(): Promise<any> {
  return magicLinkActionApiClient.fetch(`/v1/customer/netbanking`);
}

/**
 * POST Submit selected bank account details for a given customer
 * @param {BankDetail} bankAccount
 * @returns {Promise}
 */
export function submitSelectedBankAccount(
  bankAccount: BankDetail
): Promise<any> {
  return magicLinkActionApiClient.post(
    `/v1/customer/netbanking/salaryaccount`,
    bankAccount
  );
}

/**
 * GET Get additional details for magic link
 * @param {string} linkId
 * @returns {Promise}
 */
export function getAdditionalDetails(
  linkId: string,
  action: string
): Promise<any> {
  return magicLinkActionApiClient.fetch(
    `/v1/magic_link/fetch-additional-data?token=${linkId}&action=${action}`
  );
}

/**
 * GET Get kyc metadata
 * @returns {Promise}
 */
export function getKycMetadata(): Promise<any> {
  return magicLinkActionApiClient.fetch('/v1/customer/unified_kyc/metadata');
}

/**
 * POST Get kyc details for digi kyc
 * @returns {Promise}
 */
export function initiateDigiKyc(payload: any): Promise<any> {
  return magicLinkActionApiClient.post(
    '/kyc/v1/dkyc/initiate',
    {
      ...payload,
    },
    {
      credentials: 'same-origin',
    }
  );
}

/**
 * POST Get digi KYC status
 * @returns {Promise}
 */
export function getDigiKycStatus(payload: any): Promise<any> {
  return magicLinkActionApiClient.post(
    '/kyc/v1/dkyc/get-status',
    {
      ...payload,
    },
    {
      credentials: 'same-origin',
    }
  );
}
