import { hasOwnProperty } from '@/lib/helpers';
import Vue from 'vue';
declare const Moengage: any;

const moengageEventBus = new Vue({
  created() {
    this.$on('processEvent', this.handleEvent);
    this.$on('capture-id', this.captureId);
    this.$on('user-logout', this.logoutSession);
    this.$on('capture-user-info', this.captureUserInfo);
    this.$on('capture-qual-flow-type', this.captureQualFlow);
  },
  beforeDestroy() {
    this.$off('processEvent');
    this.$off('capture-id');
    this.$off('user-logout');
    this.$off('capture-user-info');
    this.$off('capture-qual-flow-type');
  },
  methods: {
    handleEvent(eventName: string, eventAttributes: any) {
      const phone = hasOwnProperty(eventAttributes, 'phone')
        ? eventAttributes.phone
        : '';
      const { nonInteraction = false, ...attributes } = eventAttributes;
      try {
        Moengage.track_event(eventName, {
          eventCategory: eventName,
          eventAction: phone,
          ...attributes,
          nonInteraction,
        });
      } catch (err) {
        console.log('Error sending events to moengage', err);
      }
    },
    captureId(customerId: string) {
      if (customerId) {
        Moengage.add_unique_user_id(customerId);
      }
    },
    logoutSession() {
      Moengage.destroy_session();
    },
    captureUserInfo(userInfo: Record<string, any>) {
      Moengage.add_user_attribute('ENVIRONMENT', process.env.NODE_ENV);
      Moengage.add_user_attribute('WEB_URL', window.location.hostname);
      userInfo.firstName && Moengage.add_first_name(userInfo.firstName);
      userInfo.lastName && Moengage.add_last_name(userInfo.lastName);
      userInfo.email && Moengage.add_email(userInfo.email);
      userInfo.phone && Moengage.add_mobile(userInfo.phone);
      userInfo.gender && Moengage.add_gender(userInfo.gender);
      userInfo.dateOfBirth && Moengage.add_birthday(userInfo.dateOfBirth);
      userInfo.userName && Moengage.add_user_name(userInfo.fullName);
      userInfo.utmSource &&
        Moengage.add_user_attribute('UTM_SOURCE', userInfo.utmSource);
      userInfo.utmMedium &&
        Moengage.add_user_attribute('UTM_MEDIUM', userInfo.utmMedium);
      userInfo.utmCampaign &&
        Moengage.add_user_attribute('UTM_CAMPAIGN', userInfo.utmCampaign);
      userInfo.utmContent &&
        Moengage.add_user_attribute('UTM_CONTENT', userInfo.utmContent);
      userInfo.sessionId &&
        Moengage.add_user_attribute('SESSION_ID', userInfo.sessionId);
    },
    captureQualFlow(qualFlowType: String) {
      Moengage.add_user_attribute('QUAL_FLOW_TYPE', qualFlowType);
    },
  },
});

export default moengageEventBus;
