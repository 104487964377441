import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { Nullable } from '@/types';

export default class LoanRequest {
  public static getInstance(): LoanRequest {
    if (!LoanRequest.instance || !isCustomerAuthenticatedSync(authState())) {
      LoanRequest.instance = new LoanRequest();
    }
    return LoanRequest.instance;
  }

  private static instance: LoanRequest | null;

  private _id?: Nullable<number>;
  private _userAskAmount?: Nullable<number>;
  private _isTcAccepted?: Nullable<boolean>;
  private _tcAcceptedAt?: Nullable<string>;
  private _isPrivacyPolicyAccepted?: Nullable<boolean>;
  private _privacyPolicyAcceptedAt?: Nullable<string>;
  private _loanRequirementReason?: Nullable<string>;
  private _otherReason?: Nullable<string>;

  private constructor() {
    this._id = null;
    this._userAskAmount = null;
    this._isTcAccepted = null;
    this._tcAcceptedAt = null;
    this._isPrivacyPolicyAccepted = null;
    this._privacyPolicyAcceptedAt = null;
    this._loanRequirementReason = null;
    this._otherReason = null;
  }

  public setLoanRequest(loanRequest: LoanRequest) {
    if (loanRequest) {
      this._id = loanRequest.id;
      this._userAskAmount = loanRequest.userAskAmount;
      this._isTcAccepted = loanRequest.isTcAccepted;
      this._tcAcceptedAt = loanRequest.tcAcceptedAt;
      this._isPrivacyPolicyAccepted = loanRequest.isPrivacyPolicyAccepted;
      this._privacyPolicyAcceptedAt = loanRequest.privacyPolicyAcceptedAt;
      this._loanRequirementReason = loanRequest.loanRequirementReason;
      this._otherReason = loanRequest.otherReason;
    }
  }

  public get id(): Nullable<number> {
    return this._id || null;
  }

  public set id(id: Nullable<number>) {
    this._id = id;
  }

  public get userAskAmount(): Nullable<number> {
    return this._userAskAmount || null;
  }

  public set userAskAmount(userAskAmount: Nullable<number>) {
    this._userAskAmount = userAskAmount;
  }

  public get isTcAccepted(): boolean {
    return this._isTcAccepted || false;
  }

  public set isTcAccepted(isTcAccepted: boolean) {
    this._isTcAccepted = isTcAccepted;
  }

  public get tcAcceptedAt(): Nullable<string> {
    return this._tcAcceptedAt || null;
  }

  public set tcAcceptedAt(tcAcceptedAt: Nullable<string>) {
    this._tcAcceptedAt = tcAcceptedAt;
  }

  public get isPrivacyPolicyAccepted(): boolean {
    return this._isPrivacyPolicyAccepted || false;
  }

  public set isPrivacyPolicyAccepted(isPrivacyPolicyAccepted: boolean) {
    this._isPrivacyPolicyAccepted = isPrivacyPolicyAccepted;
  }

  public get privacyPolicyAcceptedAt(): Nullable<string> {
    return this._privacyPolicyAcceptedAt || null;
  }

  public set privacyPolicyAcceptedAt(
    privacyPolicyAcceptedAt: Nullable<string>
  ) {
    this._privacyPolicyAcceptedAt = privacyPolicyAcceptedAt;
  }

  public get loanRequirementReason(): Nullable<string> {
    return this._loanRequirementReason || null;
  }

  public set loanRequirementReason(loanRequirementReason: Nullable<string>) {
    this._loanRequirementReason = loanRequirementReason;
  }

  public get otherReason(): Nullable<string> {
    return this._otherReason || null;
  }

  public set otherReason(otherReason: Nullable<string>) {
    this._otherReason = otherReason;
  }

  public clearInstance() {
    LoanRequest.instance = null;
  }
}
