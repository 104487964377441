import { Options } from '@/interfaces/app_config';

export default class CreditLineOptions {
  private _maxOptions!: number;
  private _options!: Options[];

  public get maxOptions(): number {
    return this._maxOptions;
  }

  public set maxOptions(maxOptions: number) {
    this._maxOptions = maxOptions;
  }

  public get options(): Options[] {
    return this._options;
  }

  public set options(options: Options[]) {
    this._options = options;
  }
  // TODO: implement other stuff here
}
