import { Nullable } from '@/types';
import { isEmpty } from '@/lib/helpers';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';

export default class CreditProfile {
  public static getInstance(): CreditProfile {
    if (!CreditProfile.instance || !isCustomerAuthenticatedSync(authState())) {
      CreditProfile.instance = new CreditProfile();
    }
    return CreditProfile.instance;
  }
  private static instance: CreditProfile | null;

  private _firstCredit?: Nullable<string>;
  private _openCredit?: Nullable<string>;
  private _hasCreditCard?: Nullable<string>;
  private _hasLoan?: Nullable<string>;

  private constructor() {
    this._firstCredit = null;
    this._openCredit = null;
    this._hasCreditCard = null;
    this._hasLoan = null;
  }

  public clearInstance() {
    CreditProfile.instance = null;
  }

  public get firstCredit(): Nullable<string> {
    return this._firstCredit || null;
  }

  public set firstCredit(firstCredit: Nullable<string>) {
    this._firstCredit = firstCredit;
  }

  public get openCredit(): Nullable<string> {
    return this._openCredit || null;
  }

  public set openCredit(openCredit: Nullable<string>) {
    this._openCredit = openCredit;
  }

  public get hasCreditCard(): Nullable<string> {
    return this._hasCreditCard || null;
  }

  public set hasCreditCard(hasCreditCard: Nullable<string>) {
    this._hasCreditCard = hasCreditCard;
  }

  public get hasLoan(): Nullable<string> {
    return this._hasLoan || null;
  }

  public set hasLoan(hasLoan: Nullable<string>) {
    this._hasLoan = hasLoan;
  }

  public isFirstCreditNoGo(): boolean {
    if (this._firstCredit) {
      return ['NEVER', 'RECENT'].includes(this._firstCredit);
    }
    return false;
  }

  public isOpenCreditNoGo(): boolean {
    if (this._openCredit) {
      return this._openCredit === 'NO';
    }
    return false;
  }

  public isNoGo(): boolean {
    return this.isFirstCreditNoGo() || this.isOpenCreditNoGo();
  }

  public isValid(): boolean {
    return !isEmpty(this._firstCredit) && !isEmpty(this._openCredit);
  }

  public setCreditProfile(creditProfile: CreditProfile) {
    if (creditProfile) {
      this._firstCredit = creditProfile.firstCredit || null;
      this._openCredit = creditProfile.openCredit || null;
      this._hasCreditCard = creditProfile.hasCreditCard || null;
      this._hasLoan = creditProfile.hasLoan || null;
    }
  }
}
