import { Nullable } from '@/types';
import ApplicationState from './application_state';
import LoanTerm from './loan_term';
import CreditLineOptions from './credit_line_options';
import UserDataErrors from './user_data_errors';
import { isEmpty } from '@/lib/helpers';
import CreditInquiry from './credit_enquiry';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';

export default class LoanResponse {
  public static readonly ESIGN_STATUS_WAITING: string = 'WAITING';
  public static readonly ESIGN_STATUS_COMPLETE = 'COMPLETE';
  public static readonly ESIGN_STATUS_ELIGIBLE = 'ELIGIBLE';
  public static readonly ESIGN_STATUS_NOT_ELIGIBLE = 'NOT_ELIGIBLE';
  public static readonly ESIGN_STATUS_ERROR = 'ERROR';

  public static readonly NETBANKING_LOGIN = 'NETBANKING';
  public static readonly STATEMENT_UPLOAD = 'STATEMENT_UPLOAD';

  public static getInstance(): LoanResponse {
    if (!LoanResponse.instance || !isCustomerAuthenticatedSync(authState())) {
      LoanResponse.instance = new LoanResponse();
    }
    return LoanResponse.instance;
  }

  private static instance: LoanResponse | null;

  private _id: Nullable<number>;
  private _preApprovalStatus: Nullable<string>;
  private _loanStatus: Nullable<string>;
  private _applicationId: Nullable<string>;
  private _statusMessage: Nullable<string>;
  private _loanAmount: Nullable<number>;
  private _bankName: Nullable<string>;
  private _goNogoStatus: Nullable<boolean>;
  private _tuRequestStatus: Nullable<boolean>;
  private _tuCompleteStatus: Nullable<boolean>;
  private _loanUpdateNumber: Nullable<string>;
  private _customerId: Nullable<string>;
  private _saRemarks: Nullable<string>;
  private _saStatus: Nullable<string>;
  private _haRemarks: Nullable<string>;
  private _opsStatus: Nullable<string>;
  private _approvalStatus: Nullable<string>;
  private _raw: Nullable<string>;
  private _selectedAmount: Nullable<number>;
  private _loanAccepted: Nullable<boolean>;
  private _bankerApprovedAmount: Nullable<number>;
  private _bankStatementsWaived: Nullable<boolean>;
  private _loanTerms: LoanTerm[];
  private _applicationState: ApplicationState;
  private _creditLineOptions: CreditLineOptions;
  private _userDataErrors: UserDataErrors[];
  private _bankStatementsOptional: Nullable<boolean>;
  private _expectedMaximumLoanAmount: Nullable<number>;
  private _creditInquiry: CreditInquiry;
  private _isCustomerInteractionReqd: Nullable<boolean>;
  private _financeReportRequiredPostAip: Nullable<boolean>;
  private _lineType: Nullable<string>;
  private _lineTypeVariant: Nullable<string>;

  private constructor() {
    this._id = null;
    this._preApprovalStatus = '';
    this._loanStatus = '';
    this._applicationId = '';
    this._statusMessage = '';
    this._loanAmount = null;
    this._bankName = '';
    this._goNogoStatus = null;
    this._tuRequestStatus = null;
    this._tuCompleteStatus = null;
    this._loanUpdateNumber = '';
    this._customerId = '';
    this._saRemarks = '';
    this._haRemarks = '';
    this._saStatus = '';
    this._opsStatus = '';
    this._approvalStatus = '';
    this._raw = '';
    this._selectedAmount = null;
    this._loanAccepted = null;
    this._bankerApprovedAmount = null;
    this._bankStatementsWaived = null;
    this._loanTerms = [];
    this._applicationState = ApplicationState.getInstance();
    this._creditLineOptions = new CreditLineOptions();
    this._userDataErrors = [];
    this._bankStatementsOptional = null;
    this._expectedMaximumLoanAmount = null;
    this._creditInquiry = new CreditInquiry();
    this._isCustomerInteractionReqd = null;
    this._financeReportRequiredPostAip = null;
    this._lineTypeVariant = '';
    this._lineType = '';
  }

  public setLoanResponse(loanResponse: LoanResponse) {
    if (!isEmpty(loanResponse)) {
      this._id = loanResponse.id || null;
      this._preApprovalStatus = loanResponse.preApprovalStatus || null;
      this._loanStatus = loanResponse.loanStatus || '';
      this._applicationId = loanResponse.applicationId || '';
      this._statusMessage = loanResponse.statusMessage || '';
      this._loanAmount = loanResponse.loanAmount || null;
      this._bankName = loanResponse.bankName || '';
      this._goNogoStatus = loanResponse.goNogoStatus || null;
      this._tuRequestStatus = loanResponse.tuRequestStatus || null;
      this._tuCompleteStatus = loanResponse.tuCompleteStatus || null;
      this._loanUpdateNumber = loanResponse.loanUpdateNumber || '';
      this._customerId = loanResponse.customerId || '';
      this._saRemarks = loanResponse.saRemarks || '';
      this._haRemarks = loanResponse.haRemarks || '';
      this._saStatus = loanResponse.saStatus || '';
      this._opsStatus = loanResponse.opsStatus || '';
      this._approvalStatus = loanResponse.approvalStatus || '';
      this._raw = loanResponse.raw || '';
      this._selectedAmount = loanResponse.selectedAmount || null;
      this._loanAccepted = loanResponse.loanAccepted || null;
      this._bankerApprovedAmount = loanResponse.bankerApprovedAmount || null;
      this._bankStatementsWaived = loanResponse.bankStatementsWaived || null;
      this._loanTerms = loanResponse.loanTerms || [];
      this._applicationState =
        loanResponse.applicationState || ApplicationState.getInstance();
      this._creditLineOptions =
        loanResponse.creditLineOptions || new CreditLineOptions();
      this._userDataErrors = loanResponse.userDataErrors || [];
      this._bankStatementsOptional =
        loanResponse.bankStatementsOptional || null;
      this._expectedMaximumLoanAmount =
        loanResponse.expectedMaximumLoanAmount || null;
      this._creditInquiry = loanResponse.creditInquiry || new CreditInquiry();
      this._isCustomerInteractionReqd =
        loanResponse.isCustomerInteractionReqd || null;
      this._financeReportRequiredPostAip =
        loanResponse.financeReportRequiredPostAip || null;
      this._lineTypeVariant = loanResponse.lineTypeVariant || '';
      this._lineType = loanResponse.lineType || '';
    }
  }
  get id(): Nullable<number> {
    return this._id;
  }

  set id(id: Nullable<number>) {
    this._id = id;
  }

  get preApprovalStatus(): Nullable<string> {
    return this._preApprovalStatus;
  }

  set preApprovalStatus(preApprovalStatus: Nullable<string>) {
    this._preApprovalStatus = preApprovalStatus;
  }

  get loanStatus(): Nullable<string> {
    return this._loanStatus;
  }

  set loanStatus(loanStatus: Nullable<string>) {
    this._loanStatus = loanStatus;
  }

  get applicationId(): Nullable<string> {
    return this._applicationId;
  }

  set applicationId(applicationId: Nullable<string>) {
    this._applicationId = applicationId;
  }

  get statusMessage(): Nullable<string> {
    return this._statusMessage;
  }

  set statusMessage(statusMessage: Nullable<string>) {
    this._statusMessage = statusMessage;
  }

  get loanAmount(): Nullable<number> {
    return this._loanAmount;
  }

  set loanAmount(loanAmount: Nullable<number>) {
    this._loanAmount = loanAmount;
  }

  get bankName(): Nullable<string> {
    return this._bankName;
  }

  set bankName(bankName: Nullable<string>) {
    this._bankName = bankName;
  }

  get goNogoStatus(): Nullable<boolean> {
    return this._goNogoStatus;
  }

  set goNogoStatus(goNogoStatus: Nullable<boolean>) {
    this._goNogoStatus = goNogoStatus;
  }

  get tuRequestStatus(): Nullable<boolean> {
    return this._tuRequestStatus;
  }

  set tuRequestStatus(tuRequestStatus: Nullable<boolean>) {
    this._tuRequestStatus = tuRequestStatus;
  }

  get tuCompleteStatus(): Nullable<boolean> {
    return this._tuCompleteStatus;
  }

  set tuCompleteStatus(tuCompleteStatus: Nullable<boolean>) {
    this._tuCompleteStatus = tuCompleteStatus;
  }

  get loanUpdateNumber(): Nullable<string> {
    return this._loanUpdateNumber;
  }

  set loanUpdateNumber(loanUpdateNumber: Nullable<string>) {
    this._loanUpdateNumber = loanUpdateNumber;
  }

  get customerId(): Nullable<string> {
    return this._customerId;
  }

  set customerId(customerId: Nullable<string>) {
    this._customerId = customerId;
  }

  get saRemarks(): Nullable<string> {
    return this._saRemarks;
  }

  set saRemarks(saRemarks: Nullable<string>) {
    this._saRemarks = saRemarks;
  }

  get saStatus(): Nullable<string> {
    return this._saStatus;
  }

  set saStatus(saStatus: Nullable<string>) {
    this._saStatus = saStatus;
  }

  get haRemarks(): Nullable<string> {
    return this._haRemarks;
  }

  set haRemarks(haRemarks: Nullable<string>) {
    this._haRemarks = haRemarks;
  }

  get opsStatus(): Nullable<string> {
    return this._opsStatus;
  }

  set opsStatus(opsStatus: Nullable<string>) {
    this._opsStatus = opsStatus;
  }

  get approvalStatus(): Nullable<string> {
    return this._approvalStatus;
  }

  set approvalStatus(approvalStatus: Nullable<string>) {
    this._approvalStatus = approvalStatus;
  }

  get raw(): Nullable<string> {
    return this._raw;
  }

  set raw(raw: Nullable<string>) {
    this._raw = raw;
  }

  get selectedAmount(): Nullable<number> {
    return this._selectedAmount;
  }

  set selectedAmount(selectedAmount: Nullable<number>) {
    this._selectedAmount = selectedAmount;
  }

  get loanAccepted(): Nullable<boolean> {
    return this._loanAccepted;
  }

  set loanAccepted(loanAccepted: Nullable<boolean>) {
    this._loanAccepted = loanAccepted;
  }

  get bankerApprovedAmount(): Nullable<number> {
    return this._bankerApprovedAmount;
  }

  set bankerApprovedAmount(bankerApprovedAmount: Nullable<number>) {
    this._bankerApprovedAmount = bankerApprovedAmount;
  }

  get bankStatementsWaived(): Nullable<boolean> {
    return this._bankStatementsWaived;
  }

  set bankStatementsWaived(bankStatementsWaived: Nullable<boolean>) {
    this._bankStatementsWaived = bankStatementsWaived;
  }

  get loanTerms(): LoanTerm[] {
    return this._loanTerms;
  }

  set loanTerms(loanTerms: LoanTerm[]) {
    this._loanTerms = loanTerms;
  }

  get applicationState(): ApplicationState {
    return this._applicationState;
  }

  set applicationState(applicationState: ApplicationState) {
    this._applicationState = applicationState;
  }

  get creditLineOptions(): CreditLineOptions {
    return this._creditLineOptions;
  }

  set creditLineOptions(creditLineOptions: CreditLineOptions) {
    this._creditLineOptions = creditLineOptions;
  }

  get userDataErrors(): UserDataErrors[] {
    return this._userDataErrors;
  }

  set userDataErrors(userDataErrors: UserDataErrors[]) {
    this._userDataErrors = userDataErrors;
  }

  get bankStatementsOptional(): Nullable<boolean> {
    return this._bankStatementsOptional;
  }

  set bankStatementsOptional(bankStatementsOptional: Nullable<boolean>) {
    this._bankStatementsOptional = bankStatementsOptional;
  }

  get expectedMaximumLoanAmount(): Nullable<number> {
    return this._expectedMaximumLoanAmount;
  }

  set expectedMaximumLoanAmount(expectedMaximumLoanAmount: Nullable<number>) {
    this._expectedMaximumLoanAmount = expectedMaximumLoanAmount;
  }

  get creditInquiry(): CreditInquiry {
    return this._creditInquiry;
  }

  set creditInquiry(value: CreditInquiry) {
    this._creditInquiry = value;
  }

  get isCustomerInteractionReqd(): Nullable<boolean> {
    return this._isCustomerInteractionReqd;
  }

  set isCustomerInteractionReqd(value: Nullable<boolean>) {
    this._isCustomerInteractionReqd = value;
  }

  get financeReportRequiredPostAip(): Nullable<boolean> {
    return this._financeReportRequiredPostAip;
  }

  set financeReportRequiredPostAip(value: Nullable<boolean>) {
    this._financeReportRequiredPostAip = value;
  }

  get lineTypeVariant(): Nullable<string> {
    return this._lineTypeVariant;
  }

  set lineTypeVariant(value: Nullable<string>) {
    this._lineTypeVariant = value;
  }

  get lineType(): Nullable<string> {
    return this._lineType;
  }

  set lineType(value: Nullable<string>) {
    this._lineType = value;
  }

  // public getActiveCreditLine() {
  //   let activeLine: any = null;
  //   if (!isEmpty(this._creditLineInfo)) {
  //     this._creditLineInfo.forEach((creditLine: CreditLineInfo) => {
  //       if (isNil(activeLine)) {
  //         activeLine = creditLine;
  //       } else if (creditLine.priority < activeLine.priority) {
  //         activeLine = creditLine;
  //       }
  //     });
  //   }
  //   return activeLine;
  // }

  public clearInstance() {
    LoanResponse.instance = null;
  }
}
