import { parse } from 'qs';
import { setLayoutConfig } from '@/store/actions/app_config';
import ApplicationState from '@/business/models/application_state';
import router from '@/router';
import LoanResponse from '@/business/models/loan_response';
import { get, isEmpty } from './helpers';
import {
  IMPS_ELIGIBLE_LINE_TYPES,
  REPAYMENT_ELIGIBLE_PARTNERS,
  AIP_LINE_ELIGIBLE_PARTNERS,
  CONFIN_ELIGIBLE_PARTNERS,
  LINE_TYPES,
} from './app_consts';
import { isMicroappsEnabled, openInNewTab } from './utils';
import BigCookie from '@/business/models/big_cookie';
import CreditLineManager from '@/business/manager/credit_line_manager';
import { layoutConfig, eligibleRoutes } from './layout_consts';

export function updateLayoutConfig(layout: string) {
  setLayoutConfig({
    payload: {
      ...layoutConfig[layout],
    },
  });
}

export function getNavigationRoute(
  applicationState?: ApplicationState,
  loanResponse?: LoanResponse,
  routeName?: string
): any {
  let appState = applicationState;
  let currentLoanResponse = loanResponse;
  if (!appState) {
    appState = BigCookie.getInstance().applicationState;
  }
  if (!currentLoanResponse) {
    currentLoanResponse = BigCookie.getInstance().loanResponseDetails;
  }
  const activeCreditProduct =
    CreditLineManager.getInstance().getActiveCreditLine();
  const actionables = BigCookie.getInstance().actionables;
  const microappsEnabled = isMicroappsEnabled();
  const targetLayout = microappsEnabled
    ? layoutConfig.GPAY_LAYOUT
    : layoutConfig.DEFAULT_LAYOUT;
  const routeNames = Object.values(targetLayout.routes);
  const searchString = window.location.search;
  let query = {};
  if (searchString) {
    query = parse(searchString, { ignoreQueryPrefix: true });
    history.replaceState(
      null,
      '',
      window.location.pathname + window.location.hash
    );
  }
  if (appState.isAipAddressReq()) {
    return {
      targetRoute: get(
        targetLayout,
        'routes.residenceAddress',
        'residenceAddress'
      ),
    };
  }
  if (appState.isBankerPortalRejected()) {
    return {
      targetRoute: get(
        targetLayout,
        'routes.bankerPortalRejected',
        'bankerPortalRejected'
      ),
    };
  }
  if (appState.isPerfiosRequired() || appState.isPerfiosSubmitted()) {
    return {
      targetRoute: get(
        targetLayout,
        'routes.perfiosValidation',
        'perfiosValidation'
      ),
    };
  }
  if (
    (currentLoanResponse.isCustomerInteractionReqd &&
      !appState.isCustomerInteractionOver()) ||
    appState.isCustomerInteractionReqd()
  ) {
    return {
      targetRoute: get(
        targetLayout,
        'routes.creditEligibility',
        'creditEligibility'
      ),
    };
  }
  if (
    appState.isQualBlock(appState.qualBlockMilestones) ||
    appState.isAipRejected()
  ) {
    return {
      targetRoute: get(
        targetLayout,
        'routes.rejectedApplication',
        'rejectedApplication'
      ),
    };
  }
  if (
    appState.isAipSuccess() &&
    !appState.isUserInterested(actionables) &&
    ((activeCreditProduct &&
      activeCreditProduct.lineType === LINE_TYPES.CF &&
      CONFIN_ELIGIBLE_PARTNERS.includes(appState.currentPartnerCode)) ||
      !AIP_LINE_ELIGIBLE_PARTNERS.includes(appState.currentPartnerCode))
  ) {
    return {
      targetRoute: get(
        targetLayout,
        'routes.approvedApplication',
        'approvedApplication'
      ),
    };
  }
  if (
    appState.isCreditLineManagement() &&
    activeCreditProduct &&
    IMPS_ELIGIBLE_LINE_TYPES.includes(activeCreditProduct.lineType)
  ) {
    const { action = '', expiry_Date = '' } = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (
      appState &&
      REPAYMENT_ELIGIBLE_PARTNERS.includes(appState.currentPartnerCode) &&
      action &&
      action === 'request_payment'
    ) {
      return {
        targetRoute: get(
          layoutConfig,
          'CREDIT_MANAGEMENT_LAYOUT.routes.emiRepayment',
          ''
        ),
        query: { expiry_Date, action },
      };
    }
    const cmLayout = layoutConfig.CREDIT_MANAGEMENT_LAYOUT;
    const cmRouteNames = Object.values(cmLayout.routes);
    if (routeName) {
      const isRoutePresent = cmRouteNames.includes(routeName);
      if (isRoutePresent && isEligibleRoute(appState, routeName)) {
        return {
          targetRoute: routeName,
          query,
        };
      }
    }
    const currentRouteName = router.currentRoute.name || '';
    if (currentRouteName) {
      const isCurrentRoutePresent = cmRouteNames.includes(currentRouteName);
      if (
        isCurrentRoutePresent &&
        isEligibleRoute(appState, currentRouteName)
      ) {
        return {
          targetRoute: currentRouteName,
          query,
        };
      }
    }
    return {
      targetRoute: get(targetLayout, 'routes.cmHome', 'cm-home'),
      query,
    };
  }
  if (appState.isAipSuccess() && appState.isAipSuccessExact()) {
    if (appState.isUserInterested(actionables)) {
      const isRoutePresent = routeNames.includes(routeName);
      if (routeName && isRoutePresent && isEligibleRoute(appState, routeName)) {
        return {
          targetRoute: routeName,
          query,
        };
      } else {
        return {
          targetRoute: get(targetLayout, 'routes.aipHome', 'aipHome'),
          query,
        };
      }
    }
    return {
      targetRoute: get(
        targetLayout,
        'routes.approvedApplication',
        'approvedApplication'
      ),
    };
  }
  if (
    appState.isAipSuccess() &&
    appState.isPostAip() &&
    !appState.isCreditLineManagement() &&
    appState.isUserInterested(actionables)
  ) {
    const isRoutePresent = routeNames.includes(routeName);
    if (routeName && isRoutePresent && isEligibleRoute(appState, routeName)) {
      return {
        targetRoute: routeName,
        query,
      };
    }
    const currentRouteName = router.currentRoute.name || '';
    const isCurrentRoutePresent = routeNames.includes(currentRouteName);
    if (
      currentRouteName &&
      isCurrentRoutePresent &&
      isEligibleRoute(appState, currentRouteName)
    ) {
      return {
        targetRoute: currentRouteName,
        query,
      };
    }
    return {
      targetRoute: get(targetLayout, 'routes.aipHome', 'aipHome'),
      query,
    };
  }
  /*   if (!isCustomerAuthenticatedSync(authState())) {
    if (routeName) {
      return {
        targetRoute: routeName
      };
    }
    if (!appState.isAipSuccess()) {
      if (appState.isApplicationSubmitted()) {
        return {
          targetRoute: get(targetLayout, 'routes.submitApplication', 'submitApplication')
        };
      }
      if (routeName) {
        const isRoutePresent = routeNames.includes(routeName);
        if (isRoutePresent && isEligibleRoute(appState, routeName)) {
          return {
            targetRoute: routeName
          };
        }
      }
      const currentRouteName = router.currentRoute.name || '';
      const isCurrentRoutePresent = routeNames.includes(currentRouteName);
      if (currentRouteName && isCurrentRoutePresent && isEligibleRoute(appState, currentRouteName)) {
        return {
          targetRoute: currentRouteName
        };
      }
      return {
        targetRoute: get(targetLayout, 'routes.qualHome', 'qual')
      };
    }
  } */
  if (!appState.isAipSuccess()) {
    if (appState.isApplicationSubmitted()) {
      return {
        targetRoute: get(
          targetLayout,
          'routes.submitApplication',
          'submitApplication'
        ),
      };
    }
    if (routeName) {
      const isRoutePresent = routeNames.includes(routeName);
      if (isRoutePresent && isEligibleRoute(appState, routeName)) {
        return {
          targetRoute: routeName,
        };
      }
    }
    const currentRouteName = router.currentRoute.name || '';
    const isCurrentRoutePresent = routeNames.includes(currentRouteName);
    if (
      currentRouteName &&
      isCurrentRoutePresent &&
      isEligibleRoute(appState, currentRouteName)
    ) {
      return {
        targetRoute: currentRouteName,
      };
    }
    return {
      targetRoute: get(targetLayout, 'routes.qualHome', 'qual'),
    };
  } else {
    if (routeName) {
      const isRoutePresent = routeNames.includes(routeName);
      if (isRoutePresent && isEligibleRoute(appState, routeName)) {
        return {
          targetRoute: routeName,
        };
      }
    }
    const currentRouteName = router.currentRoute.name || '';
    const isCurrentRoutePresent = routeNames.includes(currentRouteName);
    if (
      currentRouteName &&
      isCurrentRoutePresent &&
      isEligibleRoute(appState, currentRouteName)
    ) {
      return {
        targetRoute: currentRouteName,
      };
    }
    if (appState.isUserInterested(actionables)) {
      return {
        targetRoute: get(targetLayout, 'routes.aipHome', 'aipHome'),
      };
    } else {
      return {
        targetRoute: get(
          targetLayout,
          'routes.approvedApplication',
          'approvedApplication'
        ),
      };
    }
  }
}

export function navigateTo(
  appState: ApplicationState,
  loanResponse: LoanResponse
) {
  const navigationRoute = getNavigationRoute(appState, loanResponse);
  const { targetRoute = '', query = {}, params = {} } = navigationRoute;
  if (targetRoute) {
    reroute(targetRoute, query, params);
  } else {
    const microappsEnabled = isMicroappsEnabled();
    const targetLayout = microappsEnabled
      ? layoutConfig.GPAY_LAYOUT
      : layoutConfig.DEFAULT_LAYOUT;
    reroute(get(targetLayout, 'routes.login', 'customerLogin'), query, params);
  }
}

export const reroute = (targetRoute: string, query = {}, params = {}) => {
  const currentRouteName: string = router.currentRoute.name || '';
  if (currentRouteName && currentRouteName !== targetRoute) {
    router.push({
      name: targetRoute,
      query: !isEmpty(query) ? query : undefined,
      params: !isEmpty(params) ? params : undefined,
    });
  }
};

export const rerouteInNewTab = (
  targetRoute: string,
  query = {},
  params = {}
) => {
  const currentRouteName: string = router.currentRoute.name || '';
  if (currentRouteName && currentRouteName !== targetRoute) {
    const route = router.resolve({
      name: targetRoute,
      query: !isEmpty(query) ? query : undefined,
      params: !isEmpty(params) ? params : undefined,
    });
    openInNewTab(route.href);
  }
};

/**
 * Checks whether it is eligible route
 * @param {ApplicationState} appState
 * @param {string} targetRoute
 * @param {string?} key
 * @returns {boolean}
 */
export function isEligibleRoute(
  appState: ApplicationState,
  targetRoute: string,
  key?: string
): boolean {
  let routes = [];
  if (key && !isEmpty(key)) {
    routes = eligibleRoutes[key];
  } else {
    const currentAppMode = appState.getCurrentAppMode();
    routes = eligibleRoutes[currentAppMode || 'DEFAULT'];
  }
  return routes.includes(targetRoute);
}
