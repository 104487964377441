export default class FulfillmentModel {
  public static getInstance(): FulfillmentModel {
    if (!FulfillmentModel.instance) {
      FulfillmentModel.instance = new FulfillmentModel();
    }
    return FulfillmentModel.instance;
  }

  private static instance: FulfillmentModel | null;

  private _allowedLocations!: string[];

  public get allowedLocations(): string[] {
    return this._allowedLocations;
  }

  public set allowedLocations(allowedLocations: string[]) {
    this._allowedLocations = allowedLocations;
  }

  public clearInstance() {
    FulfillmentModel.instance = null;
  }
  // TODO: Implement utils
}
