import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { Nullable } from '@/types';

export default class BankInfo {
  public static getInstance(): BankInfo {
    if (!BankInfo.instance || !isCustomerAuthenticatedSync(authState())) {
      BankInfo.instance = new BankInfo();
    }
    return BankInfo.instance;
  }
  private static instance: BankInfo | null;

  private _bankName?: Nullable<string>;
  private _accountNumber?: Nullable<string>;
  private _ifsc?: Nullable<string>;
  private _status?: Nullable<string>;
  private _reason?: Nullable<string>;
  private _pennyDropVerificationLeft?: Nullable<number>;
  private _beneficiaryAttemptsLeft?: Nullable<number>;
  private _verified?: boolean;

  private constructor() {
    this._bankName = null;
    this._accountNumber = null;
    this._ifsc = null;
    this._status = null;
    this._reason = null;
    this.pennyDropVerificationLeft = null;
    this._beneficiaryAttemptsLeft = null;
    this._verified = false;
  }

  public resetBankInfo(): BankInfo {
    return new BankInfo();
  }

  public get bankName(): Nullable<string> {
    return this._bankName || null;
  }

  public set bankName(bankName: Nullable<string>) {
    this._bankName = bankName;
  }

  public get accountNumber(): Nullable<string> {
    return this._accountNumber || null;
  }

  public set accountNumber(accountNumber: Nullable<string>) {
    this._accountNumber = accountNumber;
  }

  public get ifsc(): Nullable<string> {
    return this._ifsc || null;
  }

  public set ifsc(ifsc: Nullable<string>) {
    this._ifsc = ifsc;
  }

  public get status(): Nullable<string> {
    return this._status || null;
  }

  public set status(status: Nullable<string>) {
    this._status = status;
  }

  public get reason(): Nullable<string> {
    return this._reason || null;
  }

  public set reason(reason: Nullable<string>) {
    this._reason = reason;
  }

  public get pennyDropVerificationLeft(): Nullable<number> {
    return this._pennyDropVerificationLeft || null;
  }

  public set pennyDropVerificationLeft(
    pennyDropVerificationLeft: Nullable<number>
  ) {
    this._pennyDropVerificationLeft = pennyDropVerificationLeft;
  }

  public get beneficiaryAttemptsLeft(): Nullable<number> {
    return this._beneficiaryAttemptsLeft || null;
  }

  public set beneficiaryAttemptsLeft(
    beneficiaryAttemptsLeft: Nullable<number>
  ) {
    this._beneficiaryAttemptsLeft = beneficiaryAttemptsLeft;
  }

  public get verified(): boolean {
    return this._verified || false;
  }

  public set verified(verified: boolean) {
    this._verified = verified;
  }

  public setBankInfo(bankInfo: BankInfo) {
    if (bankInfo) {
      this._bankName = bankInfo.bankName;
      this._accountNumber = bankInfo.accountNumber;
      this._ifsc = bankInfo.ifsc;
      this._status = bankInfo.status;
      this._reason = bankInfo.reason;
      this.pennyDropVerificationLeft = bankInfo.pennyDropVerificationLeft;
      this._beneficiaryAttemptsLeft = bankInfo.beneficiaryAttemptsLeft;
      this._verified = bankInfo.verified;
    }
  }

  public clearInstance() {
    BankInfo.instance = null;
  }
}
