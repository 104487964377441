import { makeFetchClient } from 'so-fetch-js';
import { store } from '@/store/index';
import { get, hasOwnProperty, isEmpty } from '@/lib/helpers';
import { getLocalKey } from '@/store/local_store';
import {
  ACCESS_DETAILS,
  LINK_ID,
  CURRENT_ACTION_ID,
  MAGIC_LINK_ACCESS_DETAILS,
} from '@/store/store_keys';
import { setCurrentActionId } from '@/store/actions/magic_link';
import { uuid } from '@/lib/utils';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { magicLinkState } from '@/store/getters/magic_link';
import router from '@/router';
import { getGeolocation } from '@/store/getters/app_config';

const addAuthHeader = (config: any) => {
  const isAuthenticated = isCustomerAuthenticatedSync(authState());
  if (isAuthenticated) {
    const { access_token: accessToken } = authState();
    config.headers = {
      Accept: 'application/json',
      'Cache-Control': 'no-cache, no-store',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    return config;
  }
  const accessDetails: any = getLocalKey(ACCESS_DETAILS);
  if (
    accessDetails &&
    hasOwnProperty(accessDetails, 'access_token') &&
    accessDetails.access_token
  ) {
    config.headers = {
      Accept: 'application/json',
      'Cache-Control': 'no-cache, no-store',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessDetails.accessToken}`,
    };
    return config;
  }
  return config;
};

const setJsonHeaders = (config: any) => {
  config.headers = {
    ...config.headers,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
    'mt-request-id': uuid(),
  };
  return config;
};

const setCacheHeaders = (config: any) => {
  config.headers = {
    ...config.headers,
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
  };
  return config;
};

const setGeolocationHeader = (config: any) => {
  const geolocation = getGeolocation();
  const { latitude = NaN, longitude = NaN, accuracy = 0 } = geolocation;

  config.headers = {
    ...config.headers,
    ...(!isEmpty(geolocation) &&
      !isNaN(latitude) &&
      !isNaN(longitude) && {
        'geo-location': `${latitude}, ${longitude}, ${accuracy}`,
      }),
  };
  return config;
};

const setMagicLinkHeaders = (config: any) => {
  let { access_token: accessToken } = magicLinkState();
  const accessDetails: any = getLocalKey(MAGIC_LINK_ACCESS_DETAILS);
  if (
    accessDetails &&
    hasOwnProperty(accessDetails, 'access_token') &&
    accessDetails.access_token
  ) {
    accessToken = accessDetails.access_token;
  }
  const state: any = store.getState();
  let linkId = get(state, 'magicLink.linkId', '');
  if (!linkId) {
    linkId = getLocalKey(LINK_ID);
  }
  let currentActionId = get(state, 'magicLink.currentActionId', '');
  if (!currentActionId) {
    currentActionId = getLocalKey(CURRENT_ACTION_ID);
  }

  config.headers = {
    Authorization: `Bearer ${accessToken}`,
    MagicLinkId: linkId,
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
    'mt-request-id': uuid(),
  };
  if (currentActionId) {
    config.headers.MagicLinkActionId = currentActionId;
  }
  return config;
};

const resetActionId = (response: any) => {
  setCurrentActionId({ payload: { currentActionId: '' } });
  return response;
};

export const checkStatusAndTakeAction = (response: any) => {
  switch (response.status) {
    case 401:
      router.push({
        name: 'genericError',
        params: {
          errorCode: '401',
          errorMessage: 'Oops! You are not logged in',
        },
      });
      break;
    case 502:
      router.push({
        name: 'genericError',
        params: {
          errorCode: '502',
          errorMessage:
            'Something went wrong! It is not you, it is us. We are looking into it',
        },
      });
      break;
    case 500:
      router.push({
        name: 'genericError',
        params: {
          errorCode: '500',
          errorMessage:
            'Seems like our web developer has dozed off. Something has gone wrong in the server',
        },
      });
      break;
    case 400:
      router.push({
        name: 'genericError',
        params: {
          errorCode: '400',
          errorMessage: 'Bad request',
        },
      });
      break;
    default:
      return response;
  }
};

// const handleInvalidToken = async (response: SoFetchResponse<any>) => {
//     const { status = 0, ok = false }: any = response;
//     /* if (!ok && [401, 502].includes(status)) {
//         await store.dispatch(clearAccessDetails());
//         deleteLocalKey(ACCESS_DETAILS);
//         router.push({ name: 'customerLogin' });
//     } */
//     return response;
// };

export const jsonApiClient = makeFetchClient({
  rootUrl: () => process.env.VUE_APP_API_BASE_URL,
  requestInterceptors: [
    addAuthHeader,
    setJsonHeaders,
    setCacheHeaders,
    setGeolocationHeader,
  ],
  responseInterceptors: [],
});

export const unsecureApiClient = makeFetchClient({
  rootUrl: () => process.env.VUE_APP_API_BASE_URL,
  requestInterceptors: [setJsonHeaders, setCacheHeaders],
});

export const magicLinkApiClient = makeFetchClient({
  rootUrl: () => `${process.env.VUE_APP_API_BASE_URL}/v1/magic_link`,
  requestInterceptors: [setMagicLinkHeaders],
  responseInterceptors: [resetActionId],
});

export const magicLinkActionApiClient = makeFetchClient({
  rootUrl: () => process.env.VUE_APP_API_BASE_URL,
  requestInterceptors: [setMagicLinkHeaders],
  responseInterceptors: [resetActionId],
});
