import { get, isEmpty } from '@/lib/helpers';
import { store } from '@/store/index';
import { getLocalKey } from '../local_store';
import { CUSTOMER_DETAILS } from '../store_keys';

export const customerDetails = () => {
  const state: any = store.getState();
  if (!isEmpty(state.customer)) {
    return state.customer;
  }
  const customerDetails = getLocalKey(CUSTOMER_DETAILS);
  if (!isEmpty(customerDetails)) {
    return customerDetails;
  }
  return null;
};

export const getPhoneNumber = () => {
  const state: any = store.getState();
  return get(state.customer, 'customerDetails.phone', 'XXXXXXXXXX');
};

export const getDisplayName = () => {
  const state: any = store.getState();
  return get(state.customer, 'customerDetails.displayName', 'User');
};

export const getProfilePictureUrl = () => {
  const state: any = store.getState();
  return get(state.customer, 'customerDetails.imageUrl', '');
};

export const getSignedProfilePictureUrl = () => {
  const state: any = store.getState();
  return get(state.customer, 'customerDetails.signedImageUrl', '');
};

export const aadhaarDetails = () => {
  return get(customerDetails, 'aadhaarDetails', {});
};

export const panDetails = () => {
  return get(customerDetails(), 'panDetails', {});
};

export const loanResponse = () => {
  return get(customerDetails(), 'loanResponseDetails', {});
};

export const isSelfieAvailable = () => {
  const state: any = store.getState();
  const uploadedDocuments = get(state.customer, 'uploadedDocuments', []);
  if (uploadedDocuments.length > 0) {
    const isSelfieUploaded = uploadedDocuments.find(
      (uploadedDocument: any) =>
        uploadedDocument.documentType === 'selfie' &&
        ['UPLOADED', 'ACCEPTED'].includes(uploadedDocument.status)
    );
    return isSelfieUploaded;
  }
  return false;
};
