import { get, hasOwnProperty } from './helpers';
import updeep from 'updeep';

interface Action {
  type: string;
  path: string | null;
  payload: any;
}

function updateState(state: object, payload: any) {
  if (typeof payload === 'function') {
    return payload(state);
  }

  return updeep(payload, state);
}

function updateStateAtPath(
  state: object,
  payload: object,
  path: string | null
) {
  if (typeof payload === 'function') {
    return updeep.updateIn(
      path,
      updeep.constant(payload(get(state, path))),
      state
    );
  }

  return updeep.updateIn(path, payload, state);
}

function defaultReducer(state: object, { payload, path = null }: Action) {
  if (path !== null) {
    return updateStateAtPath(state, payload, path);
  }

  return updateState(state, payload);
}

export default function createReducer(
  namespace: string,
  initialState: object,
  handlers: any = {}
) {
  const namespaceRegexp = new RegExp(`^${namespace}/`);
  return (state = initialState, action: Action) => {
    if (hasOwnProperty(handlers, action.type)) {
      return handlers[action.type](state, action);
    } else if (namespaceRegexp.test(action.type)) {
      return defaultReducer(state, action);
    }
    return state;
  };
}
