export const get = (obj: any, path: string | null, defaultValue: any = null) =>
  String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce(
      (a: any, c) =>
        a && Object.hasOwnProperty.call(a, c) ? a[c] : defaultValue,
      obj
    );

export const isNil = (obj: any): boolean => obj == null || obj === undefined;

export const isNull = (obj: any): boolean => obj == null;

export const isUndefined = (obj: any): boolean => obj === undefined;

export const isNaN = (obj: any): boolean => Number.isNaN(obj);

export const isEmpty = (obj: any): boolean =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const head = (objArray: any[]): any => {
  const [firstElem] = objArray;
  return firstElem || '';
};

export const isFunction = (object: any) => {
  return !!(object && object.constructor && object.call && object.apply);
};
export const sortBy = (object: any[], key?: any) => {
  if (key) {
    return object.concat().sort(sortByHelper(key));
  } else {
    return object.sort();
  }
};

const sortByHelper = (key: any) => {
  return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const intersection = (array: any[], ...restArrays: any[]) => {
  const arrayOfArrays = [array, ...restArrays];
  return arrayOfArrays.reduce((a, b) => a.filter((c: any) => b.includes(c)));
};

export const deleteInPath = (object: any, path: string) => {
  let currentObject = object;
  const parts: string[] = path.split('.');
  const targetProp = parts.pop();
  for (const part of parts) {
    currentObject = currentObject[part];
    if (!currentObject) {
      return;
    }
  }
  if (targetProp) {
    Reflect.deleteProperty(currentObject, targetProp);
  }
};

export const groupBy = (
  array: any,
  propertyKey: string,
  filterEmptyKeys: boolean
) => {
  return array.reduce((acc: any, item: any) => {
    if ((filterEmptyKeys && item[propertyKey]) || !filterEmptyKeys) {
      (acc[item[propertyKey]] = acc[item[propertyKey]] || []).push(item);
    }
    return acc;
  }, {});
};

export const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const hasOwnProperty = (object: any, path: string) => {
  return Object.prototype.hasOwnProperty.call(object, path);
};

export function debounce<T extends (...args: any[]) => void>(
  fn: T,
  delay: number
): T {
  let timeoutID: number | undefined | null = null;

  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID || 0);
    timeoutID = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  } as T;
}
