import createReducer from '@/lib/redux_updeep';
import { Auth } from '@/interfaces/auth';
import { getLocalKey } from '@/store/local_store';
import { ACCESS_DETAILS } from '@/store/store_keys';
import { isEmpty } from '@/lib/helpers';

interface Action {
  type: string;
  path?: string | null;
  payload?: any;
}

function getInitialState(): Auth {
  let initialState = {};
  const accessDetails = getLocalKey(ACCESS_DETAILS);
  if (!isEmpty(accessDetails)) {
    initialState = accessDetails;
  }
  return initialState;
}

export default createReducer('AUTH', getInitialState(), {
  'AUTH/INITIAL_STATE': (state: Auth, action: Action) => {
    return someAction(state, action.payload);
  },
});

function someAction(state: Auth, payload: any) {
  return { state, payload };
}
