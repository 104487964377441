import { Nullable } from '@/types';

export default class BankDetail {
  private _name?: Nullable<string>;
  // tslint:disable: variable-name
  private _account_no?: Nullable<string>;
  private _userStatementPresent?: boolean;
  private _is_primary?: boolean;
  private _start_date?: Nullable<string>;
  private _end_date?: Nullable<string>;
  // tslint:enable: variable-name

  constructor(bankDetails?: BankDetail) {
    this._name = (bankDetails && bankDetails.name) || null;
    this._account_no = (bankDetails && bankDetails.accountNo) || null;
    this._userStatementPresent =
      (bankDetails && bankDetails.userStatementPresent) || false;
    this._is_primary = (bankDetails && bankDetails.isPrimary) || false;
    this._start_date = (bankDetails && bankDetails.startDate) || null;
    this._end_date = (bankDetails && bankDetails.endDate) || null;
  }

  public get name(): Nullable<string> {
    return this._name || null;
  }

  public set name(name: Nullable<string>) {
    this._name = name;
  }

  public get accountNo(): Nullable<string> {
    return this._account_no || null;
  }

  public set accountNo(accountNo: Nullable<string>) {
    this._account_no = accountNo;
  }

  public get userStatementPresent(): boolean {
    return this._userStatementPresent || false;
  }

  public set userStatementPresent(userStatementPresent: boolean) {
    this._userStatementPresent = userStatementPresent;
  }

  public get isPrimary(): boolean {
    return this._is_primary || false;
  }

  public set isPrimary(isPrimary: boolean) {
    this._is_primary = isPrimary;
  }

  public get startDate(): Nullable<string> {
    return this._start_date || null;
  }

  public set startDate(startDate: Nullable<string>) {
    this._start_date = startDate;
  }

  public get endDate(): Nullable<string> {
    return this._end_date || null;
  }

  public set endDate(endDate: Nullable<string>) {
    this._end_date = endDate;
  }
}
