import Vue from 'vue';

const gaEventBus = new Vue({
  created() {
    this.$on('processEvent', this.handleEvent);
  },
  beforeDestroy() {
    this.$off('processEvent');
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleEvent(eventName: string, eventAttributes: any) {
      // eslint-disable-next-line no-undef
      (window as any).dataLayer.push({ event: eventName }); // to be changed with respect to google analytics
    },
  },
});

export default gaEventBus;
