import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { Nullable } from '@/types';

export default class FinanceHistory {
  public static readonly HIGHER_APPROVED_LIMIT: number = 212345.67;

  public static getInstance(): FinanceHistory {
    if (!FinanceHistory.instance || isCustomerAuthenticatedSync(authState())) {
      FinanceHistory.instance = new FinanceHistory();
    }
    return FinanceHistory.instance;
  }
  private static instance: FinanceHistory | null;

  private _applicationRejected?: Nullable<boolean>;
  private _loanTaken?: Nullable<boolean>;
  private _checkBounced?: Nullable<boolean>;
  private _defaulted?: Nullable<boolean>;
  private _isFinanceHistoryAvailable?: Nullable<boolean>;
  private _loanRequirementReason?: Nullable<string>;
  private _otherReason?: Nullable<string>;

  private constructor() {
    this._applicationRejected = null;
    this._loanTaken = null;
    this._checkBounced = null;
    this._defaulted = null;
    this._isFinanceHistoryAvailable = null;
    this._loanRequirementReason = null;
    this._otherReason = null;
  }

  public clearInstance() {
    FinanceHistory.instance = null;
  }

  public setFinanceHistory(financeHistory: FinanceHistory) {
    if (financeHistory) {
      this._applicationRejected = financeHistory.applicationRejected || null;
      this._loanTaken = financeHistory.loanTaken || null;
      this._checkBounced = financeHistory.checkBounced || null;
      this._defaulted = financeHistory.defaulted || null;
      this._isFinanceHistoryAvailable =
        financeHistory.isFinanceHistoryAvailable || null;
      this._loanRequirementReason =
        financeHistory.loanRequirementReason || null;
      this._otherReason = financeHistory.otherReason || null;
    }
  }

  public get applicationRejected(): Nullable<boolean> {
    return this._applicationRejected || null;
  }

  public set applicationRejected(applicationRejected: Nullable<boolean>) {
    this._applicationRejected = applicationRejected;
  }

  public get loanTaken(): Nullable<boolean> {
    return this._loanTaken || null;
  }

  public set loanTaken(loanTaken: Nullable<boolean>) {
    this._loanTaken = loanTaken;
  }

  public get checkBounced(): Nullable<boolean> {
    return this._checkBounced || null;
  }

  public set checkBounced(checkBounced: Nullable<boolean>) {
    this._checkBounced = checkBounced;
  }

  public get defaulted(): Nullable<boolean> {
    return this._defaulted || null;
  }

  public set defaulted(defaulted: Nullable<boolean>) {
    this._defaulted = defaulted;
  }

  public get isFinanceHistoryAvailable(): Nullable<boolean> {
    return this._isFinanceHistoryAvailable || null;
  }

  public set isFinanceHistoryAvailable(
    isFinanceHistoryAvailable: Nullable<boolean>
  ) {
    this._isFinanceHistoryAvailable = isFinanceHistoryAvailable;
  }

  public get loanRequirementReason(): string {
    return this._loanRequirementReason || '';
  }

  public set loanRequirementReason(loanRequirementReason: string) {
    this._loanRequirementReason = loanRequirementReason;
  }

  public get otherReason(): string {
    return this._otherReason || '';
  }

  public set otherReason(otherReason: string) {
    this._otherReason = otherReason;
  }
}
