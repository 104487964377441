import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { Nullable } from '@/types';

export default class PanCard {
  public static getInstance() {
    if (!PanCard.instance || !isCustomerAuthenticatedSync(authState())) {
      PanCard.instance = new PanCard();
    }
    return PanCard.instance;
  }

  private static instance: PanCard | null;

  private _name?: Nullable<string>;
  private _panNumber?: Nullable<string>;
  private _sonOf?: Nullable<string>;

  private _dateOfBirth?: Nullable<string>;
  private _customerId?: Nullable<string>;
  private _imageUrl?: Nullable<string>;

  private _nsdlFirstName?: Nullable<string>;
  private _nsdlMiddleName?: Nullable<string>;
  private _nsdlLastName?: Nullable<string>;
  private _nsdlPanStatus?: Nullable<string>;
  private _nsdlUpdatedDate?: Nullable<string>;
  private _status?: Nullable<string>;

  private constructor() {
    this._name = null;
    this._panNumber = null;
    this._sonOf = null;
    this.dateOfBirth = null;
    this._customerId = null;
    this._imageUrl = null;
    this._nsdlFirstName = null;
    this._nsdlMiddleName = null;
    this._nsdlLastName = null;
    this._nsdlPanStatus = null;
    this._nsdlUpdatedDate = null;
    this._status = null;
  }

  public setPanDetails(panDetails: PanCard) {
    if (panDetails) {
      this._name = panDetails.name || null;
      this._panNumber = panDetails.panNumber || null;
      this._sonOf = panDetails.sonOf || null;
      this.dateOfBirth = panDetails.dateOfBirth || null;
      this._customerId = panDetails.customerId || null;
      this._imageUrl = panDetails.imageUrl || null;
      this._nsdlFirstName = panDetails.nsdlFirstName || null;
      this._nsdlMiddleName = panDetails.nsdlMiddleName || null;
      this._nsdlLastName = panDetails.nsdlLastName || null;
      this._nsdlPanStatus = panDetails.nsdlPanStatus || null;
      this._nsdlUpdatedDate = panDetails.nsdlUpdatedDate || null;
      this._status = panDetails.status || null;
    }
  }

  public get name(): Nullable<string> {
    return this._name || null;
  }

  public set name(name: Nullable<string>) {
    this._name = name;
  }

  public get panNumber(): Nullable<string> {
    return this._panNumber || null;
  }

  public set panNumber(panNumber: Nullable<string>) {
    this._panNumber = panNumber;
  }

  public get sonOf(): Nullable<string> {
    return this._sonOf || null;
  }

  public set sonOf(sonOf: Nullable<string>) {
    this._sonOf = sonOf;
  }

  public get dateOfBirth(): Nullable<string> {
    return this._dateOfBirth || null;
  }

  public set dateOfBirth(dateOfBirth: Nullable<string>) {
    this._dateOfBirth = dateOfBirth;
  }

  public get customerId(): Nullable<string> {
    return this._customerId || null;
  }

  public set customerId(customerId: Nullable<string>) {
    this._customerId = customerId;
  }

  public get imageUrl(): Nullable<string> {
    return this._imageUrl || null;
  }

  public set imageUrl(imageUrl: Nullable<string>) {
    this._imageUrl = imageUrl;
  }

  public get nsdlFirstName(): Nullable<string> {
    return this._nsdlFirstName || null;
  }

  public set nsdlFirstName(nsdlFirstName: Nullable<string>) {
    this._nsdlFirstName = nsdlFirstName;
  }

  public get nsdlMiddleName(): Nullable<string> {
    return this._nsdlMiddleName || null;
  }

  public set nsdlMiddleName(nsdlMiddleName: Nullable<string>) {
    this._nsdlMiddleName = nsdlMiddleName;
  }

  public get nsdlLastName(): Nullable<string> {
    return this._nsdlLastName || null;
  }

  public set nsdlLastName(nsdlLastName: Nullable<string>) {
    this._nsdlLastName = nsdlLastName;
  }

  public get nsdlPanStatus(): Nullable<string> {
    return this._nsdlPanStatus || null;
  }

  public set nsdlPanStatus(nsdlPanStatus: Nullable<string>) {
    this._nsdlPanStatus = nsdlPanStatus;
  }

  public get nsdlUpdatedDate(): Nullable<string> {
    return this._nsdlUpdatedDate || null;
  }

  public set nsdlUpdatedDate(nsdlUpdatedDate: Nullable<string>) {
    this._nsdlUpdatedDate = nsdlUpdatedDate;
  }

  public get status(): Nullable<string> {
    return this._status || null;
  }

  public set status(status: Nullable<string>) {
    this._status = status;
  }

  public clearInstance() {
    PanCard.instance = null;
  }
}
