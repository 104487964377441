
import { Component, Vue } from 'vue-property-decorator';
import Notification from './Notification.vue';
import bus from '@/lib/event_bus';

@Component({
  components: {
    Notification,
  },
})
export default class Notifications extends Vue {
  private notificationList: object[] = [];
  private incrementalIndex: number = 0;

  private created() {
    bus.$on('notify', this.sendNotification);
  }

  private closeNotification(event: Event, index: number) {
    this.notificationList.splice(index, 1);
  }

  private sendNotification(notification: any) {
    notification = {
      id: this.incrementalIndex++,
      ...notification,
    };
    this.notificationList.push(notification);
  }

  private beforeDestroy() {
    bus.$off('notify');
  }
}
