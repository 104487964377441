export const layoutConfig: Record<string, any> = {
  GPAY_LAYOUT: {
    routes: {
      qualHome: 'gpay-qual',
      quickProfile: 'gpay-quickProfile',
      residenceDetails: 'gpay-residenceDetails',
      financialDetails: 'gpay-financialDetails',
      submitApplication: 'gpay-submitApplication',
      rejectedApplication: 'gpay-rejectedApplication',
      approvedApplication: 'gpay-approvedApplication',
      perfiosValidation: 'gpay-perfiosValidation',
      noResponse: 'gpay-noResponse',
      creditEligibility: 'gpay-creditEligibility',
      login: 'gpay-customerLogin',
      loginComponent: 'gpay-customerLoginComponent',
      bankerportalRejected: 'gpay-rejectedApplication',
      aipHome: 'gpay-aip',
      benefits: 'gpay-benefits',
      beneficiaryDetails: 'gpay-beneficiaryDetails',
      uploadDocuments: 'gpay-uploadDocuments',
      bankFormalities: 'gpay-bankFormalities',
      officeAddress: 'gpay-officeAddress',
      termsAndConditions: 'gpay-termsAndConditions',
      signDocumentsDmi: 'gpay-signDocumentsDmi',
      signDocumentsRbl: 'gpay-signDocumentsRbl',
      signDocumentsMuskmelon: 'gpay-signDocumentsMuskmelon',
      signDocumentsIncred: 'gpay-signDocumentsIncred',
      signDocumentsFullerton: 'gpay-signDocumentsFullerton',
      signDocumentsTapstart: 'gpay-signDocumentsTapstart',
      signDocumentsLendbox: 'gpay-signDocumentsLendbox',
      signDocumentsCholamandalam: 'gpay-signDocumentsCholamandalam',
      signDocumentsCreditSaison: 'gpay-signDocumentsCreditSaison',
      signDocumentsHdbfs: 'gpay-signDocumentsHdbfs',
      interestRate: 'gpay-interestRate',
      keyFactStatement: 'gpay-keyFactStatement',
      appStatusTracker: 'gpay-appStatusTracker',
      emandate: 'gpay-emandate',
      okyc: 'gpay-okyc',
      dkyc: 'gpay-dkyc',
      okycOld: 'gpay-okyc-old',
      hypervergeOkyc: 'gpay-hypervergeOkyc',
      completeKyc: 'gpay-completeKyc',
      uploadSalarySlips: 'gpay-uploadSalarySlips',
      verifyIncome: 'gpay-verifyIncome',
      scheduleAppointment: 'gpay-scheduleAppointment',
      uploadSelfie: 'gpay-uploadSelfie',
    },
    quickProfile: {},
  },
  DEFAULT_LAYOUT: {
    routes: {
      qualHome: 'qual',
      quickProfile: 'quickProfile',
      quickProfileV2: 'quickProfileV2',
      residenceAddress: 'residenceAddress',
      residenceDetails: 'residenceDetails',
      financialDetails: 'financialDetails',
      submitApplication: 'submitApplication',
      rejectedApplication: 'rejectedApplication',
      approvedApplication: 'approvedApplication',
      perfiosValidation: 'perfiosValidation',
      noResponse: 'noResponse',
      creditEligibility: 'creditEligibility',
      login: 'customerLogin',
      loginComponent: 'customerLoginComponent',
      bankerportalRejected: 'rejectedApplication',
      aipHome: 'aipHome',
      benefits: 'benefits',
      beneficiaryDetails: 'beneficiaryDetails',
      uploadDocuments: 'uploadDocuments',
      submitBankStatement: 'submitBankStatement',
      bankFormalities: 'bankFormalities',
      officeAddress: 'officeAddress',
      termsAndConditions: 'termsAndConditions',
      signDocumentsDmi: 'signDocumentsDmi',
      signDocumentsRbl: 'signDocumentsRbl',
      signDocumentsMuskmelon: 'signDocumentsMuskmelon',
      signDocumentsIncred: 'signDocumentsIncred',
      signDocumentsFullerton: 'signDocumentsFullerton',
      signDocumentsTapstart: 'signDocumentsTapstart',
      signDocumentsLendbox: 'signDocumentsLendbox',
      signDocumentsCholamandalam: 'signDocumentsCholamandalam',
      signDocumentsCreditSaison: 'signDocumentsCreditSaison',
      signDocumentsHdbfs: 'signDocumentsHdbfs',
      interestRate: 'interestRate',
      keyFactStatement: 'keyFactStatement',
      appStatusTracker: 'appStatusTracker',
      emandate: 'emandate',
      okyc: 'okyc',
      dkyc: 'dkyc',
      okycOld: 'okyc-old',
      hypervergeOkyc: 'hypervergeOkyc',
      completeKyc: 'completeKyc',
      uploadSelfie: 'uploadSelfie',
      employmentStabilityCheck: 'employmentStabilityCheck',
      uploadSalarySlips: 'uploadSalarySlips',
      verifyIncome: 'verifyIncome',
      scheduleAppointment: 'scheduleAppointment',
      rblVkyc: 'rblVkyc',
      signDocumentsAspire: 'signDocumentsAspire',
      signDocumentsKomac: 'signDocumentsKomac',
    },
  },
  MAGIC_LINK_LAYOUT: {
    routes: {
      home: 'home-ml',
      termsAndConditions: 'termsAndConditions-ml',
      signDocumentsDmi: 'signDocumentsDmi-ml',
      signDocumentsRbl: 'signDocumentsRbl-ml',
      signDocumentsMuskmelon: 'signDocumentsMuskmelon-ml',
      signDocumentsIncred: 'signDocumentsIncred-ml',
      signDocumentsFullerton: 'signDocumentsFullerton-ml',
      signDocumentsTapstart: 'signDocumentsTapstart-ml',
      signDocumentsLendbox: 'signDocumentsLendbox-ml',
      signDocumentsCholamandalam: 'signDocumentsCholamandalam-ml',
      signDocumentsCreditSaison: 'signDocumentsCreditSaison-ml',
      signDocumentsHdbfs: 'signDocumentsHdbfs-ml',
      emandate: 'setupEmandate-ml',
      captureOtherDetails: 'captureOtherDetails-ml',
      okyc: 'okyc-ml',
      dkyc: 'dkyc-ml',
      okycOld: 'okyc-old-ml',
      hypervergeOkyc: 'hypervergeOkyc-ml',
      uploadDocuments: 'uploadDocuments-ml',
      beneficiaryDetails: 'beneficiaryDetails-ml',
      moneytapTnc: 'moneytapTnc-ml',
      login: 'customerLogin',
      loginComponent: 'customerLoginComponent',
      limitIncrease: 'limitIncrease-ml',
      completeKyc: 'completeKyc-ml',
      submitBankStatement: 'submitBankStatement-ml',
      uploadSalarySlips: 'uploadSalarySlips-ml',
      capUpload: 'capUpload-ml',
      submitBankProof: 'submitBankProof-ml',
    },
  },
  CREDIT_MANAGEMENT_LAYOUT: {
    routes: {
      aipHome: 'cm-home',
      tenureSelection: 'cm-tenure-selection',
      confirmTransaction: 'cm-confirm-transaction',
      transactionResponse: 'cm-transaction-response',
      emiRepayment: 'cm-emi-repayment',
      signDocumentMuskmelon: 'cm-sign-documents-muskmelon',
      loanRestructureHome: 'loan-restructure-home',
      signDocumentHdbfs: 'cm-sign-documents-hdbfs',
      signDocumentCholamandalam: 'cm-sign-documents-cholamandalam',
      signDocumentCholamandalamLaf: 'cm-sign-documents-cholamandalam-laf',
      limitIncrease: 'limitIncrease',
      keyFactStatement: 'cm-key-fact-statement',
      setupEmandate: 'cm-setup-emandate',
      beneficiaryDetails: 'cm-beneficiary-details',
      scheduleAppointment: 'cm-schedule-appointment',
      submitBankProof: 'cm-submit-bank-proof',
      signDocumentIncred: 'cm-sign-documents-incred',
      signDocumentIncredLaf: 'cm-sign-documents-incred-laf',
    },
  },
  LOAN_RESTRUCTURE_LAYOUT: {
    routes: {
      aipHome: 'loan-restructure-home',
      summary: 'loan-restructure-summary',
      otpConfirmation: 'loan-restructure-otp-confirmation',
      restructureSuccessful: 'loan-restructure-successful',
      paymentSuccess: 'loan-restructure-payment-success',
      paymentFailure: 'loan-restructure-payment-failure',
      paymentPending: 'loan-restructure-payment-pending',
    },
  },
};

export const eligibleRoutes: Record<string, string[]> = {
  QUALIFICATION: [
    'gpay-qual',
    'gpay-quickProfile',
    'gpay-residenceDetails',
    'gpay-financialDetails',
    'gpay-submitApplication',
    'gpay-rejectedApplication',
    'gpay-approvedApplication',
    'gpay-perfiosValidation',
    'gpay-noResponse',
    'gpay-creditEligibility',
    'qual',
    'quickProfile',
    'quickProfileV2',
    'residenceAddress',
    'residenceDetails',
    'financialDetails',
    'submitApplication',
    'rejectedApplication',
    'approvedApplication',
    'perfiosValidation',
    'noResponse',
    'creditEligibility',
    'gpay-qualBenefits',
    'qualBenefits',
  ],
  POST_AIP: [
    'bankerPortalRejected',
    'gpay-aip',
    'gpay-benefits',
    'gpay-beneficiaryDetails',
    'gpay-uploadDocuments',
    'gpay-bankFormalities',
    'gpay-officeAddress',
    'gpay-termsAndConditions',
    'gpay-signDocumentsDmi',
    'gpay-signDocumentsRbl',
    'gpay-signDocumentsMuskmelon',
    'gpay-signDocumentsIncred',
    'gpay-signDocumentsFullerton',
    'gpay-signDocumentsTapstart',
    'gpay-signDocumentsLendbox',
    'gpay-signDocumentsCholamandalam',
    'gpay-employmentStabilityCheck',
    'employmentStabilityCheck',
    'gpay-signDocumentsCreditSaison',
    'gpay-signDocumentsHdbfs',
    'gpay-interestRate',
    'gpay-keyFactStatement',
    'gpay-appStatusTracker',
    'gpay-emandate',
    'gpay-okyc',
    'gpay-okyc-old',
    'gpay-hypervergeOkyc',
    'gpay-completeKyc',
    'gpay-uploadSalarySlips',
    'bankerPortalRejected',
    'aipHome',
    'benefits',
    'beneficiaryDetails',
    'uploadDocuments',
    'bankFormalities',
    'officeAddress',
    'termsAndConditions',
    'signDocumentsDmi',
    'signDocumentsRbl',
    'signDocumentsMuskmelon',
    'signDocumentsIncred',
    'signDocumentsFullerton',
    'signDocumentsTapstart',
    'signDocumentsLendbox',
    'signDocumentsCholamandalam',
    'signDocumentsCreditSaison',
    'signDocumentsHdbfs',
    'submitBankStatement',
    'interestRate',
    'keyFactStatement',
    'appStatusTracker',
    'emandate',
    'okyc',
    'dkyc',
    'approvedApplication',
    'gpay-approvedApplication',
    'okyc-old',
    'hypervergeOkyc',
    'completeKyc',
    'uploadSalarySlips',
    'gpay-scheduleAppointment',
    'scheduleAppointment',
    'gpay-uploadSelfie',
    'rbl-v-kyc',
  ],
  CREDIT_MANAGEMENT: [
    'cm-home',
    'cm-tenure-selection',
    'cm-confirm-transaction',
    'cm-transaction-response',
    'cm-emi-repayment',
    'cm-sign-documents-muskmelon',
    'loan-restructure-home',
    'loan-restructure-payment-success',
    'loan-restructure-payment-failure',
    'loan-restructure-payment-pending',
    'cm-sign-documents-hdbfs',
    'cm-sign-documents-cholamandalam',
    'cm-sign-documents-incred',
    'cm-key-fact-statement',
    'cm-setup-emandate',
    'cm-beneficiary-details',
    'cm-schedule-appointment',
    'cm-submit-bank-statement',
    'cm-sign-documents-cholamandalam-laf',
    'cm-sign-documents-incred-laf',
  ],
  AUTHENTICATION: ['gpay-customerLogin', 'customerLogin'],
  LOAN_RESTRUCTURE: [
    'loan-restructure-home',
    'loan-restructure-payment-success',
    'loan-restructure-payment-failure',
    'loan-restructure-payment-pending',
  ],
  DEFAULT: [],
};
