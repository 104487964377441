const strings = {
  ftc_addl_deductions_pre_emi_interest_title_dmi: 'Pre-EMI Interest Amount',
  ftc_addl_deductions_pre_emi_interest_desc_dmi_new({
    date,
  }: {
    date: string;
  }) {
    return `This upfront charge corresponds to the interest for the time period between today and the first EMI due date. Hence, now your first payment will now be due on ${date}`;
  },
  ftc_addl_deductions_line_setup_title_dmi: 'Line Setup Fee + Usage Fee*',
  ftc_addl_deductions_line_setup_desc_dmi:
    '*A first-time fee for your first transaction, then a small usage fee for each transaction after to keep line readily available (incl GST @ 18%).',
  ftc_addl_deductions_processing_fee_title: 'Processing Fee',
  title_insurance_protection: 'Insurance Protection',
  detail_insurance_protection:
    'This is a one-time premium deduction for this loan.',
  fund_transfer_processing_fee_message_fixed({
    fee,
    gst,
  }: {
    fee: number;
    gst: string;
  }) {
    return `Processing fee of ( ${fee} ) + GST ( ${gst} ), is applicable`;
  },
  fund_transfer_processing_fee_message_flexible({
    fee,
    gst,
  }: {
    fee: number;
    gst: string;
  }) {
    return `Processing fee of ( ${fee} ) + GST ( ${gst} ), is applicable`;
  },
  fund_transfer_processing_fee_message_higher({
    max,
    flat,
    gst,
  }: {
    max: number;
    flat: number;
    gst: number;
  }) {
    return `Processing fee of ( ${max} or ${flat} ) + GST ( ${gst} ), whichever  is higher is applicable`;
  },
  fund_transfer_processing_fee_message_lower({
    min,
    flat,
    gst,
  }: {
    min: number;
    flat: number;
    gst: number;
  }) {
    return `Processing fee of ( ${min} or ${flat} ) + GST ( ${gst} ), whichever  is lower is applicable`;
  },
  ftc_addl_deductions_pre_emi_interest_desc_fullerton({
    date,
  }: {
    date: string;
  }) {
    return `This corresponds to the interest for the time period between today and the first EMI due date. <b>This will be added to your 1st EMI.</b> Hence, now your first payment will now be due on ${date}`;
  },
};

export default strings;
