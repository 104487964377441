import eventBus from '@/lib/event_bus';
import { get, isEmpty } from '@/lib/helpers';
import { getNavigationRoute, updateLayoutConfig } from '@/lib/layout_config';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { isMicroappsEnabled } from '@/lib/utils';
import { retrieveBigCookie } from '@/store/actions/customer';
import { authState } from '@/store/getters/auth';
import bus from '@/lib/event_bus';
import { layoutConfig } from '@/lib/layout_consts';
import { getLocalKey } from '@/store/local_store';

const beforeEnter = async (to: any, from: any, next: any) => {
  if (isMicroappsEnabled()) {
    updateLayoutConfig('GPAY_LAYOUT');
  } else {
    updateLayoutConfig('DEFAULT_LAYOUT');
  }
  try {
    eventBus.$emit('loader', {
      show: true,
      message: 'Loading...',
    });
    if (isCustomerAuthenticatedSync(authState())) {
      await retrieveBigCookie(false, 'qualRoutes:beforeEnter');
      bus.$emit('syncEvent', 'WEB.ROUTE_CHANGE', {
        to: to.name,
        from: from.name,
        dateTime: new Date().toISOString(),
        nonInteraction: true,
      });
    } else {
      if (isMicroappsEnabled()) {
        next({ path: '/gq' });
      } else {
        next();
      }
    }
    let navigationRoute;
    if (to && to.name) {
      navigationRoute = getNavigationRoute(undefined, undefined, to.name);
    } else {
      navigationRoute = getNavigationRoute();
    }
    const { targetRoute = '', query = {}, params = {} } = navigationRoute;
    if (targetRoute && targetRoute === 'qual') {
      if (isMicroappsEnabled()) {
        next({ path: '/gq' });
      } else {
        next();
      }
    } else if (targetRoute !== to.name && !to.redirectedFrom) {
      next({
        name: targetRoute,
        query: !isEmpty(query) ? query : undefined,
        params: !isEmpty(params) ? params : undefined,
      });
    } else {
      if (isMicroappsEnabled()) {
        next({ path: '/gq' });
      } else {
        next();
      }
    }
  } catch (err) {
    console.log('Error while getting customer details', err);
    if (isMicroappsEnabled()) {
      next({ path: '/gq' });
    } else {
      next();
    }
  } finally {
    eventBus.$emit('loader');
  }
};

export const qualRoutes = {
  path: '/qual',
  component: () =>
    import(/* webpackChunkName: "qual" */ '@/layouts/BasicLayout.vue'),
  meta: { requiresAuth: true },
  beforeEnter,
  children: [
    {
      path: '',
      name: 'qual',
      beforeEnter: (to: any, from: any, next: any) => {
        next({ name: 'quickProfileV2' });
      },
    },
    {
      path: 'quick-profile',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.quickProfile', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-quick-prof" */ '@/views/qual/Qualification.vue'
        ),
    },
    {
      path: 'quick-profile-v2',
      name: get(
        layoutConfig,
        'DEFAULT_LAYOUT.routes.quickProfileV2',
        'quickProfileV2'
      ),
      component: () =>
        import(
          /* webpackChunkName: "dl-quick-prof" */ '@/views/qual/Qualification.vue'
        ),
    },
    {
      path: 'residence-address',
      name: get(
        layoutConfig,
        'DEFAULT_LAYOUT.routes.residenceAddress',
        'residenceAddress'
      ),
      component: () => import('@/views/qual/ResidenceAddress.vue'),
    },
    {
      path: 'residence-details',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.residenceDetails', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-resi-deti" */ '@/views/qual/ResidenceDetails.vue'
        ),
    },
    {
      path: 'financial-details',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.financialDetails', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-fin-deti" */ '@/views/qual/FinancialDetails.vue'
        ),
    },
    {
      path: 'submit-application',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.submitApplication', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-submit-app" */ '@/views/qual/SubmitApplication2.vue'
        ),
    },
    {
      path: 'rejected',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.rejectedApplication', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "dl-reject-app" */ '@/views/qual/RejectScreen.vue'
        ),
    },
    {
      path: 'approved',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.approvedApplication', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "dl-approve-app" */ '@/views/qual/ApprovedApplication.vue'
        ),
    },
    {
      path: 'perfios',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.perfiosValidation', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "dl-perfios" */ '@/views/qual/PerfiosValidation.vue'
        ),
    },
    {
      path: 'credit-eligibility',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.creditEligibility', ''),
      meta: { requiresAuth: true },
      beforeEnter: async (to: any, from: any, next: any) => {
        try {
          await retrieveBigCookie(
            true,
            'qualRoutes:creditEligibility:beforeEnter'
          );
          next();
        } catch {
          next({ name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.login', '') });
          console.log('Unable to fetch customer data');
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "dl-credit-eligible" */ '@/views/qual/CreditEligibility.vue'
        ),
    },
    {
      path: 'no-response',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.noResponse', ''),
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "dl-no-resp" */ '@/views/qual/NoResponse.vue'
        ),
    },
  ],
};
