import Vue from 'vue';
declare const fbq: any;
const fbEventBus = new Vue({
  created() {
    this.$on('processEvent', this.handleEvent);
  },
  beforeDestroy() {
    this.$off('processEvent');
  },
  methods: {
    handleEvent(eventName: string, eventAttributes: any) {
      // eslint-disable-next-line no-undef, @typescript-eslint/no-unused-vars
      const { nonInteraction, ...atrributes } = eventAttributes;
      try {
        fbq('trackCustom', eventName, atrributes);
      } catch (err) {
        console.log('Error while sending events to facebook: ', err);
      }
    },
  },
});

export default fbEventBus;
