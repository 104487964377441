import * as types from '../action_types/auth';
import { doLogout, getAccessToken, updateAccessToken } from '@/api/login_utils';
import {
  setLocalKey,
  deleteLocalKey,
  setLocalKeyWithExpiry,
} from '@/store/local_store';
import { ACCESS_DETAILS, MT_PROFILE, SESSION_ID } from '@/store/store_keys';
import { get, hasOwnProperty } from '@/lib/helpers';
import { store } from '@/store';
import bus from '@/lib/event_bus';
import { clearCustomerDetails } from './customer';
import MtProfile from '@/business/models/mt_profile';
import { isMicroappsEnabled } from '@/lib/utils';
import { HOUR_IN_MILLISECONDS } from '@/lib/consts';
import MtProfileBuilder from '@/business/mt_profile_builder';
import { captureCustomerConsent } from '@/api';
import { reroute } from '@/lib/layout_config';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '../getters/auth';
import { updateTenureData, updateTransactionData } from './app_config';

interface LoginInputModel {
  userName: number | string;
  password: number | string;
  authType: string;
  authVersion?: number;
}

export interface CustomerConsent {
  tcAccepted: boolean;
  tcAcceptedAt: string;
  whatsAppOptIn: 'YES' | 'NO' | 'UNKNOWN';
  privacyPolicyAccepted: boolean;
  privacyPolicyAcceptedAt: string;
}

interface Auth {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  scope?: string;
  token_type?: string;
  authenticated?: boolean;
  short_lived?: boolean;
  expiry_time?: number;
  session_id?: string;
}

export function storeAccessDetails(data: { payload: Auth }) {
  let { payload } = data;
  if (isMicroappsEnabled() && !hasOwnProperty(payload, 'short_lived')) {
    payload = {
      ...payload,
      short_lived: true,
      expiry_time: new Date().getTime() + HOUR_IN_MILLISECONDS,
    };
  } else if (!hasOwnProperty(payload, 'short_lived')) {
    payload = {
      ...payload,
      short_lived: false,
      expiry_time: Number.MAX_SAFE_INTEGER,
    };
  }
  store.dispatch({
    type: types.STORE_ACCESS_DETAILS,
    payload,
  });
}

export function updateSession(data: { payload: Auth }) {
  const { payload } = data;
  store.dispatch({
    type: types.UPDATE_SESSION,
    payload,
  });
  setLocalKey(SESSION_ID, payload);
}

export async function doLogin(payload: LoginInputModel) {
  return new Promise((resolve, reject) => {
    bus.$emit('loader', {
      show: true,
      message: 'Authenticating...',
    });
    getAccessToken(
      payload.userName,
      payload.password,
      payload.authType,
      payload.authVersion
    )
      .then(async (response: any) => {
        if (response.ok) {
          let auth: Auth = {
            ...response.data,
            authenticated: true,
          };
          if (isMicroappsEnabled()) {
            auth = {
              ...auth,
              short_lived: true,
              expiry_time: new Date().getTime() + HOUR_IN_MILLISECONDS,
            };
            setLocalKeyWithExpiry(ACCESS_DETAILS, auth, HOUR_IN_MILLISECONDS);
          } else {
            auth = {
              ...auth,
              short_lived: false,
              expiry_time: Number.MAX_SAFE_INTEGER,
            };
            setLocalKey(ACCESS_DETAILS, auth);
          }
          storeAccessDetails({ payload: auth });
          resolve(auth);
        } else {
          let authData: Auth = {
            access_token: '',
            authenticated: false,
          };
          if (isMicroappsEnabled()) {
            authData = {
              ...authData,
              short_lived: true,
              expiry_time: new Date().getTime() + HOUR_IN_MILLISECONDS,
            };
            setLocalKeyWithExpiry(
              ACCESS_DETAILS,
              authData,
              HOUR_IN_MILLISECONDS
            );
          } else {
            authData = {
              ...authData,
              short_lived: false,
              expiry_time: new Date().getTime() + HOUR_IN_MILLISECONDS,
            };
            setLocalKey(ACCESS_DETAILS, authData);
          }
          storeAccessDetails({ payload: authData });
          resolve(authData);
          const status = get(response, 'status');
          let errorMessage = 'You are not registered with us. Please sign up';
          if (get(response, 'data.error_description')) {
            errorMessage = get(response, 'data.error_description');
          } else if ([400, 404, 500, 502].includes(status)) {
            errorMessage = 'Unable to log you in. Please try after a while.';
          }
          bus.$emit('notify', {
            type: 'error',
            title: 'Error',
            message: errorMessage,
            showClose: false,
          });
        }
      })
      .catch((error: any) => {
        reject({ authenicated: false, error });
        bus.$emit('notify', {
          type: 'error',
          title: 'Error',
          message: 'Something went wrong. Please contact Freo',
          autoClose: 3000,
          showClose: false,
        });
      })
      .finally(() => {
        bus.$emit('loader');
      });
  });
}

export function refreshAccessToken(refreshToken: string) {
  return new Promise((resolve, reject) => {
    bus.$emit('loader', {
      show: true,
      message: 'Authenticating...',
    });
    updateAccessToken(refreshToken)
      .then(async (response: any) => {
        if (response.ok) {
          const auth = {
            ...response.data,
            authenticated: true,
          };
          if (isMicroappsEnabled()) {
            setLocalKeyWithExpiry(ACCESS_DETAILS, auth, HOUR_IN_MILLISECONDS);
          } else {
            setLocalKey(ACCESS_DETAILS, auth);
          }
          storeAccessDetails({ payload: auth });
          resolve(true);
        } else {
          const authData: Auth = {
            access_token: '',
            authenticated: false,
          };
          if (isMicroappsEnabled()) {
            setLocalKeyWithExpiry(
              ACCESS_DETAILS,
              authData,
              HOUR_IN_MILLISECONDS
            );
          } else {
            setLocalKey(ACCESS_DETAILS, authData);
          }
          storeAccessDetails({ payload: authData });
          resolve(false);
          const status = get(response, 'status');
          let errorMessage = 'You are not registered with us. Please sign up';
          if (get(response, 'data.error_description')) {
            errorMessage = get(response, 'data.error_description');
          } else if ([400, 404, 500, 502].includes(status)) {
            errorMessage = 'Unable to login you in. Please try after a while.';
          }
          bus.$emit('notify', {
            type: 'error',
            title: 'Error',
            message: errorMessage,
            showClose: false,
          });
        }
      })
      .catch((error: any) => {
        reject(error);
        bus.$emit('notify', {
          type: 'error',
          title: 'Error',
          message: 'Something went wrong. Please contact Freo',
          autoClose: 3000,
          showClose: false,
        });
      });
  }).finally(() => {
    bus.$emit('loader');
  });
}

export function clearAccessDetails() {
  return {
    type: types.CLEAR_ACCESS_DETAILS,
    payload: {
      authenticated: false,
      access_token: '',
      refresh_token: '',
      expires_in: 0,
      scope: '',
      token_type: '',
    },
  };
}

export function clearUserDetails() {
  store.dispatch(clearAccessDetails());
  try {
    deleteLocalKey(ACCESS_DETAILS);
    deleteLocalKey(SESSION_ID);
    deleteLocalKey(MT_PROFILE);
    MtProfile.getInstance().clearInstance();
    MtProfileBuilder.getInstance().generateNewProfile();
    clearCustomerDetails();
    updateTenureData({ payload: {} });
    updateTransactionData({ payload: {} });
  } catch (err) {
    console.log('Error clearing info', err);
  }
}

export async function doCtxLogout(shouldCallApi = true) {
  if (isCustomerAuthenticatedSync(authState())) {
    try {
      if (shouldCallApi) {
        await doLogout();
      }
      clearUserDetails();
    } catch (err) {
      console.log(err);
    }
  }
  clearUserDetails();
  setTimeout(() => {
    let loginRoute = 'customerLogin';
    if (isMicroappsEnabled()) {
      loginRoute = 'gpay-customerLogin';
    }
    reroute(loginRoute);
  });
}

export function captureConsent(payload: CustomerConsent) {
  return new Promise((resolve, reject) => {
    captureCustomerConsent(payload)
      .then(async (response: any) => {
        if (response.ok) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  }).finally(() => {
    bus.$emit('loader');
  });
}
