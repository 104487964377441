import { PincodeStateObj, CityStateObj } from '@/interfaces/app_config';

export const states: PincodeStateObj[] = [
  {
    name: 'Andaman & Nicobar Islands',
    min: 74,
    max: 74,
  },
  {
    name: 'Andhra Pradesh',
    min: 50,
    max: 56,
  },
  {
    name: 'Arunachal Pradesh',
    min: 78,
    max: 79,
  },
  {
    name: 'Assam',
    min: 78,
    max: 79,
  },
  {
    name: 'Bihar',
    min: 80,
    max: 85,
  },
  {
    name: 'Chandigarh',
    min: 14,
    max: 16,
  },
  {
    name: 'Chhattisgarh',
    min: 46,
    max: 49,
  },
  {
    name: 'Dadra & Nagar Haveli',
    min: 39,
    max: 39,
  },
  {
    name: 'Daman & Diu',
    min: 36,
    max: 39,
  },
  {
    name: 'Delhi',
    min: 11,
    max: 11,
  },
  {
    name: 'Goa',
    min: 40,
    max: 40,
  },
  {
    name: 'Gujarat',
    min: 36,
    max: 39,
  },
  {
    name: 'Haryana',
    min: 12,
    max: 13,
  },
  {
    name: 'Himachal Pradesh',
    min: 17,
    max: 17,
  },
  {
    name: 'Jammu  & Kashmir',
    min: 18,
    max: 19,
  },
  {
    name: 'Jharkhand',
    min: 81,
    max: 83,
  },
  {
    name: 'Karnataka',
    min: 53,
    max: 59,
  },
  {
    name: 'Kerala',
    min: 67,
    max: 69,
  },
  {
    name: 'Lakshadweep',
    min: 67,
    max: 68,
  },
  {
    name: 'Madhya Pradesh',
    min: 45,
    max: 48,
  },
  {
    name: 'Maharashtra',
    min: 40,
    max: 44,
  },
  {
    name: 'Manipur',
    min: 79,
    max: 79,
  },
  {
    name: 'Meghalaya',
    min: 79,
    max: 79,
  },
  {
    name: 'Mizoram',
    min: 79,
    max: 79,
  },
  {
    name: 'Nagaland',
    min: 78,
    max: 79,
  },
  {
    name: 'Orissa',
    min: 75,
    max: 77,
  },
  {
    name: 'Pondicherry',
    min: 60,
    max: 60,
  },
  {
    name: 'Punjab',
    min: 14,
    max: 16,
  },
  {
    name: 'Rajasthan',
    min: 30,
    max: 34,
  },
  {
    name: 'Sikkim',
    min: 73,
    max: 73,
  },
  {
    name: 'Tamil Nadu',
    min: 53,
    max: 66,
  },
  {
    name: 'Telangana',
    min: 50,
    max: 56,
  },
  {
    name: 'Tripura',
    min: 72,
    max: 79,
  },
  {
    name: 'Uttaranchal',
    min: 24,
    max: 26,
  },
  {
    name: 'Uttarakhand',
    min: 24,
    max: 26,
  },
  {
    name: 'Uttar Pradesh',
    min: 20,
    max: 28,
  },
  {
    name: 'West Bengal',
    min: 70,
    max: 74,
  },
];

export const cities: CityStateObj[] = [
  {
    city: 'Agra',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Ahmedabad',
    state: 'Gujarat',
  },
  {
    city: 'Ahmednagar',
    state: 'Maharashtra',
  },
  {
    city: 'Ajmer',
    state: 'Rajasthan',
  },
  {
    city: 'Allahabad',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Alwar',
    state: 'Rajasthan',
  },
  {
    city: 'Ambala',
    state: 'Haryana',
  },
  {
    city: 'Anand',
    state: 'Gujarat',
  },
  {
    city: 'Ankleshwar',
    state: 'Gujarat',
  },
  {
    city: 'Asansol',
    state: 'West Bengal',
  },
  {
    city: 'Aurangabad',
    state: 'Maharashtra',
  },
  {
    city: 'Bangalore',
    state: 'Karnataka',
  },
  {
    city: 'Bankura',
    state: 'West Bengal',
  },
  {
    city: 'Belgaum',
    state: 'Karnataka',
  },
  {
    city: 'Bharuch',
    state: 'Gujarat',
  },
  {
    city: 'Bhiwadi',
    state: 'Rajasthan',
  },
  {
    city: 'Bhopal',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Bhubaneshwar',
    state: 'Orissa',
  },
  {
    city: 'Bhubaneswar',
    state: 'Orissa',
  },
  {
    city: 'Chandigarh',
    state: 'Chandigarh',
  },
  {
    city: 'Chennai',
    state: 'Tamil Nadu',
  },
  {
    city: 'Chittoor',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Chittorgarh',
    state: 'Rajasthan',
  },
  {
    city: 'Cochin',
    state: 'Kerala',
  },
  {
    city: 'Coimbatore',
    state: 'Tamil Nadu',
  },
  {
    city: 'Dakshina Kannada',
    state: 'Karnataka',
  },
  {
    city: 'Dehradun',
    state: 'Uttaranchal',
  },
  {
    city: 'Delhi',
    state: 'Delhi',
  },
  {
    city: 'Dewas',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Dharwad',
    state: 'Karnataka',
  },
  {
    city: 'Dombivilli',
    state: 'Maharashtra',
  },
  {
    city: 'Durg',
    state: 'Chattisgarh',
  },
  {
    city: 'Durgapur',
    state: 'West Bengal',
  },
  {
    city: 'East Godavari',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Ernakulam',
    state: 'Kerala',
  },
  {
    city: 'Erode',
    state: 'Tamil Nadu',
  },
  {
    city: 'Faridabad',
    state: 'Haryana',
  },
  {
    city: 'Fort Cochin',
    state: 'Kerala',
  },
  {
    city: 'Gandhinagar',
    state: 'Gujarat',
  },
  {
    city: 'Gautam Buddha Nagar',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Ghaziabad',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Greater Noida',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Guna',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Guntur',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Gurgaon',
    state: 'Haryana',
  },
  {
    city: 'Guwahati',
    state: 'Assam',
  },
  {
    city: 'Haldwani',
    state: 'Uttarakhand',
  },
  {
    city: 'Haridwar',
    state: 'Uttarkhand',
  },
  {
    city: 'Hooghly',
    state: 'West Bengal',
  },
  {
    city: 'Howrah',
    state: 'West Bengal',
  },
  {
    city: 'Hubli',
    state: 'Karnataka',
  },
  {
    city: 'Hyderabad',
    state: 'Telangana',
  },
  {
    city: 'Imphal',
    state: 'Manipur',
  },
  {
    city: 'Indore',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Jabalpur',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Jaipur',
    state: 'Rajasthan',
  },
  {
    city: 'Jalandhar',
    state: 'Punjab',
  },
  {
    city: 'Jamshedpur',
    state: 'Jharkhand',
  },
  {
    city: 'Jodhpur',
    state: 'Rajasthan',
  },
  {
    city: 'K.V.Rangareddy',
    state: 'Telangana',
  },
  {
    city: 'Kaithal',
    state: 'Haryana',
  },
  {
    city: 'Kakinada',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Kalyan',
    state: 'Maharashtra',
  },
  {
    city: 'Kanchipuram',
    state: 'Tamil Nadu',
  },
  {
    city: 'Kanpur',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Karnal',
    state: 'Haryana',
  },
  {
    city: 'Katni',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Khammam',
    state: 'Telangana',
  },
  {
    city: 'Khorda',
    state: 'Orissa',
  },
  {
    city: 'Kochi',
    state: 'Kerala',
  },
  {
    city: 'Kolar',
    state: 'Karnataka',
  },
  {
    city: 'Kolhapur',
    state: 'Maharashtra',
  },
  {
    city: 'Kolkata',
    state: 'West Bengal',
  },
  {
    city: 'Lucknow',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Ludhiana',
    state: 'Punjab',
  },
  {
    city: 'Madurai',
    state: 'Tamil Nadu',
  },
  {
    city: 'Manesar',
    state: 'Haryana',
  },
  {
    city: 'Mangalore',
    state: 'Karnataka',
  },
  {
    city: 'Mapusa',
    state: 'Goa',
  },
  {
    city: 'Mehsana',
    state: 'Gujarat',
  },
  {
    city: 'Mohali',
    state: 'Punjab',
  },
  {
    city: 'Moradabad',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Mumbai',
    state: 'Maharashtra',
  },
  {
    city: 'Mysore',
    state: 'Karnataka',
  },
  {
    city: 'Nagpur',
    state: 'Maharashtra',
  },
  {
    city: 'Nashik',
    state: 'Maharashtra',
  },
  {
    city: 'Navi Mumbai',
    state: 'Maharashtra',
  },
  {
    city: 'Nellore',
    state: 'Andhra Pradesh',
  },
  {
    city: 'New Delhi',
    state: 'Delhi',
  },
  {
    city: 'Noida',
    state: 'Uttar Pradesh',
  },
  {
    city: 'North Goa',
    state: 'Goa',
  },
  {
    city: 'Panaji',
    state: 'Goa',
  },
  {
    city: 'Panchkula',
    state: 'Haryana',
  },
  {
    city: 'Panipat',
    state: 'Haryana',
  },
  {
    city: 'Panvel',
    state: 'Maharashtra',
  },
  {
    city: 'Patna',
    state: 'Bihar',
  },
  {
    city: 'Pondicherry',
    state: 'Pondicherry',
  },
  {
    city: 'Puducherry',
    state: 'Pondicherry',
  },
  {
    city: 'Pune',
    state: 'Maharashtra',
  },
  {
    city: 'Raigad',
    state: 'Maharashtra',
  },
  {
    city: 'Raipur',
    state: 'Chhattisgarh',
  },
  {
    city: 'Rajahmundry',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Rajamahendravaram',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Rajamundry',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Rajkot',
    state: 'Gujarat',
  },
  {
    city: 'Ranga Reddy',
    state: 'Telangana',
  },
  {
    city: 'Rangareddy',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Rudrapur',
    state: 'Uttarakhand',
  },
  {
    city: 'Sagar',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Sahibzada Ajit Singh Nagar',
    state: 'Punjab',
  },
  {
    city: 'Salem',
    state: 'Tamil Nadu',
  },
  {
    city: 'Sangli',
    state: 'Maharashtra',
  },
  {
    city: 'Satara',
    state: 'Maharashtra',
  },
  {
    city: 'Secunderabad',
    state: 'Telangana',
  },
  {
    city: 'Sikar',
    state: 'Rajasthan',
  },
  {
    city: 'Solapur',
    state: 'Maharashtra',
  },
  {
    city: 'Sonepat',
    state: 'Haryana',
  },
  {
    city: 'Sri Potti Sriramulu Nellore',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Surat',
    state: 'Gujarat',
  },
  {
    city: 'Thane',
    state: 'Maharashtra',
  },
  {
    city: 'Thanjavur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Thiruvallur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Thiruvananthapuram',
    state: 'Kerala',
  },
  {
    city: 'Tiruchirappalli',
    state: 'Tamil Nadu',
  },
  {
    city: 'Tirupati',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Tiruppur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Tirupur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Tiruvallur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Trivandrum',
    state: 'Kerala',
  },
  {
    city: 'Udaipur',
    state: 'Rajasthan',
  },
  {
    city: 'Vadodara',
    state: 'Gujarat',
  },
  {
    city: 'Vapi',
    state: 'Gujarat',
  },
  {
    city: 'Varanasi',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Vellore',
    state: 'Tamil Nadu',
  },
  {
    city: 'Vijayawada',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Visakhapatnam',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Vishakapatnam',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Vizag',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Warangal',
    state: 'Telangana',
  },
  {
    city: '24 Parganas',
    state: 'West Bengal',
  },
];
