import QandA from './q_and_a';

export default class CreditInquiry {
  private _partnerCustomerId!: string;
  private _customerId!: string;
  private _ref1!: string;
  private _bureau!: string;
  private _qAndA!: QandA[];
  private _bureauRequestType!: string;
  private _bureauProvider!: string;
  private _bureauType!: string;
  private _bureauReportStatus!: string;

  public get partnerCustomerId(): string {
    return this._partnerCustomerId;
  }

  public set partnerCustomerId(partnerCustomerId: string) {
    this._partnerCustomerId = partnerCustomerId;
  }

  public get customerId(): string {
    return this._customerId;
  }

  public set customerId(customerId: string) {
    this._customerId = customerId;
  }

  public get ref1(): string {
    return this._ref1;
  }

  public set ref1(ref1: string) {
    this._ref1 = ref1;
  }

  public get bureau(): string {
    return this._bureau;
  }

  public set bureau(bureau: string) {
    this.bureau = bureau;
  }

  public get qAndA(): QandA[] {
    return this._qAndA;
  }

  public set qAndA(qAndA: QandA[]) {
    this._qAndA = qAndA;
  }

  public get bureauRequestType(): string {
    return this._bureauRequestType;
  }

  public set bureauRequestType(bureauRequestType: string) {
    this._bureauRequestType = bureauRequestType;
  }

  public get bureauProvider(): string {
    return this._bureauProvider;
  }

  public set bureauProvider(bureauProvider: string) {
    this._bureauProvider = bureauProvider;
  }

  public get bureauType(): string {
    return this._bureauType;
  }

  public set bureauType(bureauType: string) {
    this._bureauType = bureauType;
  }

  public get bureauReportStatus(): string {
    return this._bureauReportStatus;
  }

  public set bureauReportStatus(bureauReportStatus: string) {
    this._bureauReportStatus = bureauReportStatus;
  }
}
