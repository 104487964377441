import { combineReducers } from 'redux';

import customersReducer from './customers';
import customerReducer from './customer';
import authReducer from './auth';
import magicLinkReducer from './magic_link';
import appConfigReducer from './app_config';

const rootReducer = combineReducers({
  customers: customersReducer,
  auth: authReducer,
  customer: customerReducer,
  magicLink: magicLinkReducer,
  appConfig: appConfigReducer,
});

export default rootReducer;
