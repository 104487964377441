import { head } from '@/lib/helpers';
import { tenure } from '@/app_config/qual_config';
import { Tenure } from '@/interfaces/app_config';
export default class Duration {
  private _months: number = 0;
  private _years: number = 0;
  private _totalMonths?: number;

  constructor(months?: number, years?: number) {
    this._months = months || 0;
    this._years = years || 0;
    this._totalMonths = this._years * 12 + this._months;
  }

  public get months(): number {
    return this._months || 0;
  }

  public set months(months: number) {
    this._months = months;
  }

  public get years(): number {
    return this._years || 0;
  }

  public set years(years: number) {
    this._years = years;
  }

  public get totalMonths(): number {
    return this._totalMonths || 0;
  }

  public set totalMonths(totalMonths: number) {
    this._totalMonths = totalMonths;
  }

  public isSet() {
    return this._years > 0 || this._months > 0;
  }

  public getDuration(tenureKey: string) {
    const currentTenure: Tenure = head(
      tenure.filter((tenureItem: any) => tenureItem.value === tenureKey)
    );
    return new Duration(
      currentTenure.tenure.months,
      currentTenure.tenure.years
    );
  }

  public getDurationStr() {
    let durationStr = '';
    if (this._years > 0) {
      durationStr =
        this._years && this._months
          ? `${this._years}years${this._months}month`
          : '';
    } else {
      durationStr = this._months > 0 ? `${this._months}month` : '';
    }
    return durationStr;
  }

  public setDuration(duration: Duration) {
    if (duration) {
      if (duration.months > -1) {
        this._months = duration.months;
      }
      if (duration.years > -1) {
        this._years = duration.years;
      }
      this._totalMonths = this._years * 12 + this._months;
    }
    return this;
  }
}
