import Customer from './customer';
import PanCard from './pan_card';
import AadhaarCard from './aadhaar_card';
import BusinessCard from './business_card';
import Home from './home';
import BankDetail from './bank_detail';
import LoanRequest from './loan_request';
import LoanResponse from './loan_response';
import FinanceHistory from './finance_history';
import BankInfo from './bank_info';
import NetBankingDetails from './net_banking_details';
import CreditProfile from './credit_profile';
import ApplicationModel from './application_model';
import IncomeInfo from './income_info';
import BigCookie from './big_cookie';
import Address from './address';
import { Nullable } from '@/types';
import { get, isEmpty } from '@/lib/helpers';
import { layoutConfig } from '@/store/getters/app_config';
import { getLocalKey } from '@/store/local_store';

export default class MtProfile {
  public static readonly MODEL_CUSTOMER_ID = 'customerExternalId';
  public static readonly MODEL_CUSTOMER = 'customerModel';
  public static readonly MODEL_PAN_CARD = 'panCardModel';
  public static readonly MODEL_AADHAAR_CARD = 'aadhaarModel';
  public static readonly MODEL_BUSINESS_CARD = 'businessCardModel';
  public static readonly MODEL_HOME = 'homeModel';
  public static readonly MODEL_SALARY_ACCOUNT = 'salaryAccount';
  public static readonly MODEL_LOAN_REQUEST = 'loanRequestModel';
  public static readonly MODEL_LOAN_RESPONSE = 'loanResponseModel';
  public static readonly MODEL_FINANCE_HISTORY = 'financeHistoryModel';
  public static readonly MODEL_BENEFICIARY_INFO = 'beneficiaryInfoModel';
  public static readonly MODEL_CREDIT_PROFILE = 'creditProfileModel';
  public static readonly MODEL_CUSTOMER_PROFILE = 'customerProfileModel';
  public static readonly MODEL_INCOME_INFO = 'incomeInfo';

  public static readonly CUSTOMER_EMAIL_ID = 'emailId';
  public static readonly CUSTOMER_PHONE = 'phone';
  public static readonly CUSTOMER_FIRST_NAME = 'firstName';
  public static readonly CUSTOMER_MIDDLE_NAME = 'middleName';
  public static readonly CUSTOMER_LAST_NAME = 'lastName';
  public static readonly CUSTOMER_IMAGE_URL = 'imageUrl';
  public static readonly CUSTOMER_ID = 'customerId';
  public static readonly CUSTOMER_PHONE_VERIFIED = 'phoneVerified';
  public static readonly CUSTOMER_AADHAAR_LINK = 'aadhaarLink';
  public static readonly CUSTOMER_MARITAL_STATUS = 'maritalstatus';
  public static readonly CUSTOMER_LOAN_STATUS = 'loanStatus';
  public static readonly CUSTOMER_ACCOUNT_LOCKED = 'isAcctLocked';
  public static readonly CUSTOMER_CURRENT_CITY = 'currentCity';
  public static readonly CUSTOMER_ID_TOKEN = 'idToken';
  public static readonly CUSTOMER_AUTH_TYPE = 'authType';
  public static readonly CUSTOMER_CLIENT_ID = 'clientId';
  public static readonly CUSTOMER_CLIENT_SECRET = 'clientSecret';
  public static readonly CUSTOMER_TC_ACCEPTED = 'tcAccepted';
  public static readonly CUSTOMER_TC_ACCEPTED_AT = 'tcAcceptedAt';
  public static readonly CUSTOMER_PRIVACY_POLICY_ACCEPTED =
    'privacyPolicyAccepted';
  public static readonly CUSTOMER_PRIVACY_POLICY_ACCEPTED_AT =
    'privacyPolicyAcceptedAt';
  public static readonly CUSTOMER_LAST_KNOWN_LOCATION = 'lastKnownLocation';

  public static readonly AADHAAR_CARD_FIRST_NAME = 'firstName';
  public static readonly AADHAAR_CARD_MIDDLE_NAME = 'middleName';
  public static readonly AADHAAR_CARD_LAST_NAME = 'lastName';
  public static readonly AADHAAR_CARD_GENDER = 'gender';
  public static readonly AADHAAR_CARD_FATHER_OR_SPOUSE_NAME =
    'fatherOrSpouseName';
  public static readonly AADHAAR_CARD_DATE_OF_BIRTH = 'dateOfBirth';
  public static readonly AADHAAR_CARD_YEAR_OF_BIRTH = 'yearOfBirth';
  public static readonly AADHAAR_CARD_AADHAAR_EMAIL = 'email';
  public static readonly AADHAAR_CARD_OTP_VERIFIED = 'otpVerified';
  public static readonly AADHAAR_CARD_ADDRESS = 'address';
  public static readonly AADHAAR_CARD_AADHAAR_NUMBER = 'aadhaarNumber';
  public static readonly AADHAAR_CARD_DEMO_VERIFIED = 'demoVerified';
  public static readonly AADHAAR_CARD_RAW_XML = 'rawXml';
  public static readonly AADHAAR_CARD_AADHAAR_STATUS = 'status';
  public static readonly AADHAAR_CARD_DISPLAYABLE_AADHAAR =
    'displayableAadhaar';

  // Pan Card
  public static readonly PAN_CARD_PAN_NAME = 'name';
  public static readonly PAN_CARD_PAN_NUMBER = 'panNumber';
  public static readonly PAN_CARD_SON_OF = 'sonOf';
  public static readonly PAN_CARD_DOB = 'dateOfBirth';
  public static readonly PAN_CARD_IMAGE_URL = 'imageUrl';

  // Annual Gross Income
  public static readonly ANNUAL_INCOME = 'annualIncome';

  // Monthly Gross Income
  public static readonly MONTHLY_INCOME = 'monthlyIncome';

  // Business Card
  public static readonly BUSINESS_CARD_NAME = 'name';
  public static readonly BUSINESS_CARD_EMPLOYEE_ID = 'employeeID';
  public static readonly BUSINESS_CARD_EMAIL_OCR = 'emailByOCR';
  public static readonly BUSINESS_CARD_EMAIL = 'officeEmail';
  public static readonly BUSINESS_CARD_COMPANY_NAME = 'companyName';
  public static readonly BUSINESS_CARD_COMPANY_TYPE = 'companyType';
  public static readonly BUSINESS_CARD_EMPLOYMENT_TYPE = 'employmentType';
  public static readonly BUSINESS_CARD_ADDRESS = 'address';
  public static readonly BUSINESS_CARD_JOB_TITLE = 'jobTitle';
  public static readonly BUSINESS_CARD_JOB_TYPE = 'jobType';
  public static readonly BUSINESS_CARD_JOINING_DATE = 'joiningDate';
  public static readonly BUSINESS_CARD_CURRENT_EXPERIENCE_DURATION =
    'currentExperienceDuration';
  public static readonly BUSINESS_CARD_CURRENT_EXPERIENCE_DURATION_MONTHS =
    'currentExperienceDurationInMonths';
  public static readonly BUSINESS_CARD_TOTAL_EXPERIENCE_DURATION_MONTHS =
    'totalExperienceDurationInMonths';
  public static readonly BUSINESS_CARD_TOTAL_EXPERIENCE_DURATION =
    'totalExperienceDuration';
  public static readonly BUSINESS_CARD_OFFICE_PHONE = 'officePhone';
  public static readonly BUSINESS_CARD_LINE_BUSINESS = 'lineOfBusiness';
  public static readonly BUSINESS_CARD_WORK_HOME_ADDRESS =
    'isWorkHomeAddressSame';
  public static readonly BUSINESS_CARD_MIN_TURNOVER = 'minMonthlyTurnover';
  public static readonly BUSINESS_CARD_MAX_TURNOVER = 'maxMonthlyTurnover';

  // Home
  public static readonly HOME_CURRENT_CITY_DURATION = 'currentCityDuration';
  public static readonly HOME_CURRENT_CITY_DURATION_MONTHS =
    'currentCityDurationInMonths';
  public static readonly HOME_CURRENT_ADDRESS_DURATION =
    'currentAddressDuration';
  public static readonly HOME_CURRENT_ADDRESS_DURATION_MONTHS =
    'currentAddressDurationInMonths';
  public static readonly HOME_ADDRESS = 'address';
  public static readonly HOME_RESIDENCE_TYPE = 'residenceType';

  // Finance History
  public static readonly FINANCE_HISTORY_APPLICATION_REJECTED =
    'applicationRejected';
  public static readonly FINANCE_HISTORY_LOAN_TAKEN = 'loanTaken';
  public static readonly FINANCE_HISTORY_CHEQUE_BOUNCED = 'checkBounced';
  public static readonly FINANCE_HISTORY_DEFAULTED = 'defaulted';
  public static readonly FINANCE_HISTORY_LOAN_REQUIREMENT_REASON =
    'loanRequirementReason';
  public static readonly FINANCE_HISTORY_IS_FINANCE_HISTORY_AVAILABLE =
    'isFinanceHistoryAvailable';

  // Loan Request
  public static readonly LOAN_REQUEST_REASON = 'loanRequirementReason';

  // Passcode
  public static readonly PASSCODE = 'passcode';

  // Bank Info
  public static readonly BANK_INFO_ACCOUNT_NUMBER = 'accountNumber';
  public static readonly BANK_INFO_IFSC = 'ifsc';

  // Credit Profile
  public static readonly CREDIT_PROFILE_FIRST_CREDIT = 'firstCredit';
  public static readonly CREDIT_PROFILE_OPEN_CREDIT = 'openCredit';
  public static readonly CREDIT_PROFILE_HAS_CREDIT_CARD = 'hasCreditCard';
  public static readonly CREDIT_PROFILE_HAS_LOAN = 'hasLoan';

  // Customer Profile
  public static readonly CUSTOMER_PROFILE_EDUCATIONAL_QUALIFICATION =
    'educationalQualification';
  public static readonly CUSTOMER_PROFILE_NATIONALITY = 'nationality';
  public static readonly CUSTOMER_PROFILE_LOAN_REASON = 'loanReason';

  // Application Model
  public static readonly APPLICATION_MODEL_HAS_AADHAAR_LINKED_MOBILE =
    'hasAadhaarLnkdMobile';
  public static readonly APPLICATION_MODEL_IS_CURRENT_ADDRESS_AADHAAR_LINKED_ADDRESS =
    'isCurrAddrAadhaarLnkdAddr';
  public static readonly APPLICATION_MODEL_AADHAAR_USAGE_CONSENT_DATE =
    'aadhaarUsageConsentOn';
  public static readonly APPLICATION_MODEL_PARTNER_TNC_AGREED_ON =
    'partnerTncAgreedOn';
  public static readonly APPLICATION_MODEL_PREFERRED_LINE_TYPE =
    'preferredLineType';

  // Customer Data
  public static readonly CUSTOMER_DATA_APPVIRALITY_KEY = 'appViralityKey';

  // Income Info
  public static readonly INCOME_INFO_DECLARED = 'declared';
  public static readonly INCOME_INFO_MODE = 'mode';
  public static readonly INCOME_INFO_BANK_IFSC_PREFIXES = 'bankIfscPrefixes';

  public static readonly WHATSAPP_OPT_IN = 'whatsAppOptIn';

  public static getInstance(): MtProfile {
    if (!MtProfile.instance) {
      return new MtProfile();
    }
    return MtProfile.instance;
  }

  private static instance: MtProfile | null;

  private _customerExternalId?: Nullable<string>;
  private _customerModel: Customer;
  private _panCardModel: PanCard;
  private _aadhaarModel: AadhaarCard;
  private _businessCardModel: BusinessCard;
  private _homeModel: Home;
  private _netbankingModel?: NetBankingDetails;
  private _salaryAccount: BankDetail;
  private _loanRequestModel: LoanRequest;
  private _loanResponseModel: LoanResponse;
  private _financeHistoryModel: FinanceHistory;
  private _beneficiaryInfoModel: BankInfo;
  private _creditProfileModel: CreditProfile;
  private _applicationModel: ApplicationModel;
  private _incomeInfo: IncomeInfo;
  private constructor() {
    this._customerExternalId = null;
    this._customerModel = Customer.getInstance();
    this._panCardModel = PanCard.getInstance();
    this._aadhaarModel = AadhaarCard.getInstance();
    this._businessCardModel = BusinessCard.getInstance();
    this._homeModel = Home.getInstance();
    this._salaryAccount = new BankDetail();
    this._loanRequestModel = LoanRequest.getInstance();
    this._loanResponseModel = LoanResponse.getInstance();
    this._financeHistoryModel = FinanceHistory.getInstance();
    this._beneficiaryInfoModel = BankInfo.getInstance();
    this._creditProfileModel = CreditProfile.getInstance();
    this._applicationModel = ApplicationModel.getInstance();
    this._incomeInfo = IncomeInfo.getInstance();
  }

  public get customerExternalId(): Nullable<string> {
    return this._customerExternalId || BigCookie.getInstance().customerId;
  }

  public set customerExternalId(customerExternalId: Nullable<string>) {
    this._customerExternalId = customerExternalId;
  }

  public get customerModel(): Customer {
    return this._customerModel || BigCookie.getInstance().customerDetails;
  }

  public set customerModel(customerModel: Customer) {
    this._customerModel = customerModel;
  }

  public get panCardModel(): PanCard {
    return this._panCardModel || PanCard.getInstance();
  }

  public set panCardModel(panCardModel: PanCard) {
    this._panCardModel = panCardModel;
  }

  public get aadhaarModel(): AadhaarCard {
    return this._aadhaarModel || BigCookie.getInstance().aadhaarDetails;
  }

  public set aadhaarModel(aadhaarModel: AadhaarCard) {
    this._aadhaarModel = aadhaarModel;
  }

  public get businessCardModel(): BusinessCard {
    return this._businessCardModel || BigCookie.getInstance().businessDetails;
  }

  public set businessCardModel(businessCardModel: BusinessCard) {
    this._businessCardModel = businessCardModel;
  }

  public get homeModel(): Home {
    return this._homeModel || BigCookie.getInstance().homeDetails;
  }

  public set homeModel(homeModel: Home) {
    this._homeModel = homeModel;
  }

  public get netbankingModel(): NetBankingDetails {
    return this._netbankingModel || new NetBankingDetails();
  }

  public set netbankingModel(netbankingModel: NetBankingDetails) {
    this._netbankingModel = netbankingModel;
  }

  public get salaryAccount(): BankDetail {
    return this._salaryAccount || new BankDetail();
  }

  public set salaryAccount(salaryAccount: BankDetail) {
    this._salaryAccount = salaryAccount;
  }

  public get loanRequestModel(): LoanRequest {
    return this._loanRequestModel || BigCookie.getInstance().loanRequestDetails;
  }

  public set loanRequestModel(loanRequestModel: LoanRequest) {
    this._loanRequestModel = loanRequestModel;
  }

  public get loanResponseModel(): LoanResponse {
    return this._loanResponseModel || LoanResponse.getInstance();
  }

  public set loanResponseModel(loanResponseModel: LoanResponse) {
    this._loanResponseModel = loanResponseModel;
  }

  public get financeHistoryModel(): FinanceHistory {
    return (
      this._financeHistoryModel || BigCookie.getInstance().financeHistoryModel
    );
  }

  public set financeHistoryModel(financeHistoryModel: FinanceHistory) {
    this._financeHistoryModel = financeHistoryModel;
  }

  public get beneficiaryInfoModel(): BankInfo {
    return (
      this._beneficiaryInfoModel || BigCookie.getInstance().beneficiaryInfoModel
    );
  }

  public set beneficiaryInfoModel(beneficiaryInfoModel: BankInfo) {
    this._beneficiaryInfoModel = beneficiaryInfoModel;
  }

  public get creditProfileModel(): CreditProfile {
    return (
      this._creditProfileModel || BigCookie.getInstance().creditProfileModel
    );
  }

  public set creditProfileModel(creditProfileModel: CreditProfile) {
    this._creditProfileModel = creditProfileModel;
  }

  public get applicationModel(): ApplicationModel {
    return this._applicationModel || BigCookie.getInstance().applicationModel;
  }

  public set applicationModel(applicationModel: ApplicationModel) {
    this._applicationModel = applicationModel;
  }

  public get incomeInfo(): IncomeInfo {
    return this._incomeInfo || BigCookie.getInstance().incomeInfo;
  }

  public set incomeInfo(incomeInfo: IncomeInfo) {
    this._incomeInfo = incomeInfo;
  }

  public clearInstance() {
    MtProfile.instance = new MtProfile();
    Customer.getInstance().clearInstance();
    PanCard.getInstance().clearInstance();
    AadhaarCard.getInstance().clearInstance();
    BusinessCard.getInstance().clearInstance();
    Home.getInstance().clearInstance();
    CreditProfile.getInstance().clearInstance();
    ApplicationModel.getInstance().clearInstance();
    IncomeInfo.getInstance().clearInstance();
    BigCookie.getInstance().clearInstance();
  }

  public updateMtProfile(mtProfile: MtProfile) {
    this._customerExternalId = mtProfile.customerExternalId || '';
    this._customerModel.setCustomerDetails(
      mtProfile.customerModel || Customer.getInstance()
    );
    this._panCardModel.setPanDetails(
      mtProfile.panCardModel || PanCard.getInstance()
    );
    this._aadhaarModel.setAadhaarDetails(
      mtProfile.aadhaarModel || AadhaarCard.getInstance()
    );
    this._businessCardModel.setBusinessCardDetails(
      mtProfile.businessCardModel || BusinessCard.getInstance()
    );
    this._homeModel.setHomeDetails(mtProfile.homeModel || Home.getInstance());
    this._creditProfileModel.setCreditProfile(
      mtProfile.creditProfileModel || CreditProfile.getInstance()
    );
    this._applicationModel.setApplicationModel(
      mtProfile.applicationModel || ApplicationModel.getInstance()
    );
    this._incomeInfo.setIncomeInfo(
      mtProfile.incomeInfo || IncomeInfo.getInstance()
    );
  }

  public setMtProfile(bigCookie?: BigCookie) {
    if (bigCookie) {
      this._customerExternalId = bigCookie.customerId || null;
      this._customerModel.setCustomerDetails(
        bigCookie.customerDetails || Customer.getInstance()
      );
      this._panCardModel.setPanDetails(
        bigCookie.panDetails || PanCard.getInstance()
      );
      this._aadhaarModel.setAadhaarDetails(
        bigCookie.aadhaarDetails || AadhaarCard.getInstance()
      );
      this._businessCardModel.setBusinessCardDetails(
        bigCookie.businessDetails || BusinessCard.getInstance()
      );
      this._homeModel.setHomeDetails(
        bigCookie.homeDetails || Home.getInstance()
      );
      this._creditProfileModel.setCreditProfile(
        bigCookie.creditProfileModel || CreditProfile.getInstance()
      );
      this._applicationModel.setApplicationModel(
        bigCookie.applicationModel || ApplicationModel.getInstance()
      );
      this._incomeInfo.setIncomeInfo(
        bigCookie.incomeInfo || IncomeInfo.getInstance()
      );
      this._salaryAccount =
        bigCookie.bankDetails && bigCookie.bankDetails.length > 0
          ? bigCookie.bankDetails[0]
          : new BankDetail();
    } else {
      this._customerExternalId = null;
      this._customerModel.setCustomerDetails({} as Customer);
      this._panCardModel.setPanDetails({} as PanCard);
      this._aadhaarModel.setAadhaarDetails({} as AadhaarCard);
      this._businessCardModel.setBusinessCardDetails({} as BusinessCard);
      this._homeModel.setHomeDetails({} as Home);
      this._salaryAccount = new BankDetail();
      this._creditProfileModel.setCreditProfile({} as CreditProfile);
      this._applicationModel.setApplicationModel({} as ApplicationModel);
      this._incomeInfo.setIncomeInfo({} as IncomeInfo);
    }
  }

  public getCurrentStep(): string {
    return get(layoutConfig(), 'routes.quickProfileV2', 'quickProfileV2');
  }

  public isQuickProfileCompleted(): boolean {
    const {
      firstName = '',
      // lastName = '',
      // phone = ''
    } = this._customerModel || {};

    const { dateOfBirth = '', gender } = this._aadhaarModel || {};

    const requiredFields = [firstName, dateOfBirth, gender];
    return requiredFields.every((item: any) => !isEmpty(item));
  }

  public isResidenceDetailsCompleted(): boolean {
    const { address = new Address(), residenceType = '' } =
      this._homeModel || {};

    return (
      this.addressFilled(address) &&
      [residenceType].every((item: string) => !isEmpty(item))
    );
  }

  public isWorkDetailsCompleted(): boolean {
    const {
      employmentType = '',
      jobType = '',
      officeEmail = '',
    } = this._businessCardModel || {};
    const { panNumber = '' } = this._panCardModel || {};
    const { declared = '' } = this._incomeInfo || {};
    return [employmentType, jobType, officeEmail, panNumber, declared].every(
      (item: any) => !isEmpty(item)
    );
  }

  private addressFilled(address: Address) {
    const {
      addressLine1 = '',
      city = '',
      state = '',
      pincode = '',
    } = address || {};
    return [addressLine1, city, state, pincode].every(
      (item: any) => !isEmpty(item)
    );
  }
}
