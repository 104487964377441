import { Nullable } from '@/types';

export default class CustomerProfile {
  public static getInstance(): CustomerProfile {
    if (!CustomerProfile.instance) {
      CustomerProfile.instance = new CustomerProfile();
    }
    return CustomerProfile.instance;
  }

  private static instance: CustomerProfile | null;

  private _educationalQualification?: Nullable<string>;
  private _nationality?: Nullable<string>;
  private _loanReason?: Nullable<string>;

  private constructor() {
    this._educationalQualification = null;
    this._nationality = null;
    this._loanReason = null;
  }

  public resetCustomerProfile() {
    return new CustomerProfile();
  }

  public get educationalQualification(): Nullable<string> {
    return this._educationalQualification || null;
  }

  public set educationalQualification(
    educationalQualification: Nullable<string>
  ) {
    this._educationalQualification = educationalQualification;
  }

  public get nationality(): Nullable<string> {
    return this._nationality || null;
  }

  public set nationality(nationality: Nullable<string>) {
    this._nationality = nationality;
  }

  public get loanReason(): Nullable<string> {
    return this._loanReason || null;
  }

  public set loanReason(loanReason: Nullable<string>) {
    this._loanReason = loanReason;
  }

  public setCustomerProfile(customerProfile: CustomerProfile) {
    if (customerProfile) {
      this._educationalQualification =
        customerProfile.educationalQualification || null;
      this._nationality = customerProfile.nationality || null;
      this._loanReason = customerProfile.loanReason || null;
    }
  }

  public clearInstance() {
    CustomerProfile.instance = null;
  }
}
