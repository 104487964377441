import {
  PincodeStateObj,
  CityStateObj,
  SelectionValue,
  Tenure,
  CityRegionMap,
} from '@/interfaces/app_config';
import { groupBy } from './helpers';

export const states: PincodeStateObj[] = [
  {
    name: 'Andaman & Nicobar Islands',
    min: 74,
    max: 74,
  },
  {
    name: 'Andhra Pradesh',
    min: 50,
    max: 56,
  },
  {
    name: 'Arunachal Pradesh',
    min: 78,
    max: 79,
  },
  {
    name: 'Assam',
    min: 78,
    max: 79,
  },
  {
    name: 'Bihar',
    min: 80,
    max: 85,
  },
  {
    name: 'Chandigarh',
    min: 14,
    max: 16,
  },
  {
    name: 'Chhattisgarh',
    min: 46,
    max: 49,
  },
  {
    name: 'Dadra & Nagar Haveli',
    min: 39,
    max: 39,
  },
  {
    name: 'Daman & Diu',
    min: 36,
    max: 39,
  },
  {
    name: 'Delhi',
    min: 11,
    max: 11,
  },
  {
    name: 'Goa',
    min: 40,
    max: 40,
  },
  {
    name: 'Gujarat',
    min: 36,
    max: 39,
  },
  {
    name: 'Haryana',
    min: 12,
    max: 13,
  },
  {
    name: 'Himachal Pradesh',
    min: 17,
    max: 17,
  },
  {
    name: 'Jammu  & Kashmir',
    min: 18,
    max: 19,
  },
  {
    name: 'Jharkhand',
    min: 81,
    max: 83,
  },
  {
    name: 'Karnataka',
    min: 53,
    max: 59,
  },
  {
    name: 'Kerala',
    min: 67,
    max: 69,
  },
  {
    name: 'Lakshadweep',
    min: 67,
    max: 68,
  },
  {
    name: 'Madhya Pradesh',
    min: 45,
    max: 48,
  },
  {
    name: 'Maharashtra',
    min: 40,
    max: 44,
  },
  {
    name: 'Manipur',
    min: 79,
    max: 79,
  },
  {
    name: 'Meghalaya',
    min: 79,
    max: 79,
  },
  {
    name: 'Mizoram',
    min: 79,
    max: 79,
  },
  {
    name: 'Nagaland',
    min: 78,
    max: 79,
  },
  {
    name: 'Orissa',
    min: 75,
    max: 77,
  },
  {
    name: 'Pondicherry',
    min: 60,
    max: 60,
  },
  {
    name: 'Punjab',
    min: 14,
    max: 16,
  },
  {
    name: 'Rajasthan',
    min: 30,
    max: 34,
  },
  {
    name: 'Sikkim',
    min: 73,
    max: 73,
  },
  {
    name: 'Tamil Nadu',
    min: 60,
    max: 66,
  },
  {
    name: 'Telangana',
    min: 50,
    max: 56,
  },
  {
    name: 'Tripura',
    min: 72,
    max: 79,
  },
  {
    name: 'Uttaranchal',
    min: 24,
    max: 26,
  },
  {
    name: 'Uttarakhand',
    min: 24,
    max: 26,
  },
  {
    name: 'Uttar Pradesh',
    min: 20,
    max: 28,
  },
  {
    name: 'West Bengal',
    min: 70,
    max: 74,
  },
];

export const cities: CityStateObj[] = [
  {
    city: 'Agra',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Ahmedabad',
    state: 'Gujarat',
  },
  {
    city: 'Ahmednagar',
    state: 'Maharashtra',
  },
  {
    city: 'Ajmer',
    state: 'Rajasthan',
  },
  {
    city: 'Allahabad',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Alwar',
    state: 'Rajasthan',
  },
  {
    city: 'Ambala',
    state: 'Haryana',
  },
  {
    city: 'Anand',
    state: 'Gujarat',
  },
  {
    city: 'Ankleshwar',
    state: 'Gujarat',
  },
  {
    city: 'Asansol',
    state: 'West Bengal',
  },
  {
    city: 'Aurangabad',
    state: 'Maharashtra',
  },
  {
    city: 'Bangalore',
    state: 'Karnataka',
  },
  {
    city: 'Bankura',
    state: 'West Bengal',
  },
  {
    city: 'Belgaum',
    state: 'Karnataka',
  },
  {
    city: 'Bharuch',
    state: 'Gujarat',
  },
  {
    city: 'Bhiwadi',
    state: 'Rajasthan',
  },
  {
    city: 'Bhopal',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Bhubaneshwar',
    state: 'Orissa',
  },
  {
    city: 'Bhubaneswar',
    state: 'Orissa',
  },
  {
    city: 'Chandigarh',
    state: 'Chandigarh',
  },
  {
    city: 'Chennai',
    state: 'Tamil Nadu',
  },
  {
    city: 'Chittoor',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Chittorgarh',
    state: 'Rajasthan',
  },
  {
    city: 'Cochin',
    state: 'Kerala',
  },
  {
    city: 'Coimbatore',
    state: 'Tamil Nadu',
  },
  {
    city: 'Dakshina Kannada',
    state: 'Karnataka',
  },
  {
    city: 'Dehradun',
    state: 'Uttaranchal',
  },
  {
    city: 'Delhi',
    state: 'Delhi',
  },
  {
    city: 'Dewas',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Dharwad',
    state: 'Karnataka',
  },
  {
    city: 'Dombivilli',
    state: 'Maharashtra',
  },
  {
    city: 'Durg',
    state: 'Chattisgarh',
  },
  {
    city: 'Durgapur',
    state: 'West Bengal',
  },
  {
    city: 'East Godavari',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Ernakulam',
    state: 'Kerala',
  },
  {
    city: 'Erode',
    state: 'Tamil Nadu',
  },
  {
    city: 'Faridabad',
    state: 'Haryana',
  },
  {
    city: 'Fort Cochin',
    state: 'Kerala',
  },
  {
    city: 'Gandhinagar',
    state: 'Gujarat',
  },
  {
    city: 'Gautam Buddha Nagar',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Ghaziabad',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Greater Noida',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Guna',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Guntur',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Gurgaon',
    state: 'Haryana',
  },
  {
    city: 'Guwahati',
    state: 'Assam',
  },
  {
    city: 'Haldwani',
    state: 'Uttarakhand',
  },
  {
    city: 'Haridwar',
    state: 'Uttarkhand',
  },
  {
    city: 'Hooghly',
    state: 'West Bengal',
  },
  {
    city: 'Howrah',
    state: 'West Bengal',
  },
  {
    city: 'Hubli',
    state: 'Karnataka',
  },
  {
    city: 'Hyderabad',
    state: 'Telangana',
  },
  {
    city: 'Imphal',
    state: 'Manipur',
  },
  {
    city: 'Indore',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Jabalpur',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Jaipur',
    state: 'Rajasthan',
  },
  {
    city: 'Jalandhar',
    state: 'Punjab',
  },
  {
    city: 'Jamshedpur',
    state: 'Jharkhand',
  },
  {
    city: 'Jodhpur',
    state: 'Rajasthan',
  },
  {
    city: 'K.V.Rangareddy',
    state: 'Telangana',
  },
  {
    city: 'Kaithal',
    state: 'Haryana',
  },
  {
    city: 'Kakinada',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Kalyan',
    state: 'Maharashtra',
  },
  {
    city: 'Kanchipuram',
    state: 'Tamil Nadu',
  },
  {
    city: 'Kanpur',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Karnal',
    state: 'Haryana',
  },
  {
    city: 'Katni',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Khammam',
    state: 'Telangana',
  },
  {
    city: 'Khorda',
    state: 'Orissa',
  },
  {
    city: 'Kochi',
    state: 'Kerala',
  },
  {
    city: 'Kolar',
    state: 'Karnataka',
  },
  {
    city: 'Kolhapur',
    state: 'Maharashtra',
  },
  {
    city: 'Kolkata',
    state: 'West Bengal',
  },
  {
    city: 'Lucknow',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Ludhiana',
    state: 'Punjab',
  },
  {
    city: 'Madurai',
    state: 'Tamil Nadu',
  },
  {
    city: 'Manesar',
    state: 'Haryana',
  },
  {
    city: 'Mangalore',
    state: 'Karnataka',
  },
  {
    city: 'Mapusa',
    state: 'Goa',
  },
  {
    city: 'Mehsana',
    state: 'Gujarat',
  },
  {
    city: 'Mohali',
    state: 'Punjab',
  },
  {
    city: 'Moradabad',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Mumbai',
    state: 'Maharashtra',
  },
  {
    city: 'Mysore',
    state: 'Karnataka',
  },
  {
    city: 'Nagpur',
    state: 'Maharashtra',
  },
  {
    city: 'Nashik',
    state: 'Maharashtra',
  },
  {
    city: 'Navi Mumbai',
    state: 'Maharashtra',
  },
  {
    city: 'Nellore',
    state: 'Andhra Pradesh',
  },
  {
    city: 'New Delhi',
    state: 'Delhi',
  },
  {
    city: 'Noida',
    state: 'Uttar Pradesh',
  },
  {
    city: 'North Goa',
    state: 'Goa',
  },
  {
    city: 'Panaji',
    state: 'Goa',
  },
  {
    city: 'Panchkula',
    state: 'Haryana',
  },
  {
    city: 'Panipat',
    state: 'Haryana',
  },
  {
    city: 'Panvel',
    state: 'Maharashtra',
  },
  {
    city: 'Patna',
    state: 'Bihar',
  },
  {
    city: 'Pondicherry',
    state: 'Pondicherry',
  },
  {
    city: 'Puducherry',
    state: 'Pondicherry',
  },
  {
    city: 'Pune',
    state: 'Maharashtra',
  },
  {
    city: 'Raigad',
    state: 'Maharashtra',
  },
  {
    city: 'Raipur',
    state: 'Chhattisgarh',
  },
  {
    city: 'Rajahmundry',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Rajamahendravaram',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Rajamundry',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Rajkot',
    state: 'Gujarat',
  },
  {
    city: 'Ranga Reddy',
    state: 'Telangana',
  },
  {
    city: 'Rangareddy',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Rudrapur',
    state: 'Uttarakhand',
  },
  {
    city: 'Sagar',
    state: 'Madhya Pradesh',
  },
  {
    city: 'Sahibzada Ajit Singh Nagar',
    state: 'Punjab',
  },
  {
    city: 'Salem',
    state: 'Tamil Nadu',
  },
  {
    city: 'Sangli',
    state: 'Maharashtra',
  },
  {
    city: 'Satara',
    state: 'Maharashtra',
  },
  {
    city: 'Secunderabad',
    state: 'Telangana',
  },
  {
    city: 'Sikar',
    state: 'Rajasthan',
  },
  {
    city: 'Solapur',
    state: 'Maharashtra',
  },
  {
    city: 'Sonepat',
    state: 'Haryana',
  },
  {
    city: 'Sri Potti Sriramulu Nellore',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Surat',
    state: 'Gujarat',
  },
  {
    city: 'Thane',
    state: 'Maharashtra',
  },
  {
    city: 'Thanjavur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Thiruvallur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Thiruvananthapuram',
    state: 'Kerala',
  },
  {
    city: 'Tiruchirappalli',
    state: 'Tamil Nadu',
  },
  {
    city: 'Tirupati',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Tiruppur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Tirupur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Tiruvallur',
    state: 'Tamil Nadu',
  },
  {
    city: 'Trivandrum',
    state: 'Kerala',
  },
  {
    city: 'Udaipur',
    state: 'Rajasthan',
  },
  {
    city: 'Vadodara',
    state: 'Gujarat',
  },
  {
    city: 'Vapi',
    state: 'Gujarat',
  },
  {
    city: 'Varanasi',
    state: 'Uttar Pradesh',
  },
  {
    city: 'Vellore',
    state: 'Tamil Nadu',
  },
  {
    city: 'Vijayawada',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Visakhapatnam',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Vishakapatnam',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Vizag',
    state: 'Andhra Pradesh',
  },
  {
    city: 'Warangal',
    state: 'Telangana',
  },
  {
    city: '24 Parganas',
    state: 'West Bengal',
  },
];

export const companyTypes: SelectionValue[] = [
  {
    text: 'Public Limited Company',
    value: 'PUBLIC_LIMITED',
  },
  {
    text: 'Multinational',
    value: 'MULTINATIONAL',
  },
  {
    text: 'Central/State Government',
    value: 'CENTRAL_STATE_GOVT',
  },
  {
    text: 'Private Limited',
    value: 'PRIVATE_LIMITED',
  },
  {
    text: 'Proprietorship',
    value: 'PROPRIETORSHIP',
  },
  {
    text: 'Individual',
    value: 'INDIVIDUAL',
  },
  {
    text: 'Hospital',
    value: 'HOSPITAL',
  },
  {
    text: 'College',
    value: 'COLLEGE',
  },
  {
    text: 'LLP',
    value: 'LLP',
  },
  {
    text: 'Partnership Firm',
    value: 'PARTNERSHIP_FIRM',
  },
  {
    text: 'Hindu Undivided Family',
    value: 'HUF',
  },
  {
    text: 'Other including Society/Trust/AOP',
    value: 'OTHER',
  },
];

export const employmentTypes: SelectionValue[] = [
  {
    text: 'Full-time',
    value: 'FULL_TIME',
  },
  {
    text: 'Part-Time',
    value: 'PART_TIME',
  },
  {
    text: 'Contract',
    value: 'CONTRACT',
  },
];

export const jobTypes: SelectionValue[] = [
  {
    text: 'Salaried',
    value: 'SALARIED',
  },
  {
    text: 'Self Employed Business',
    value: 'SELF_EMPLOYED_BUSINESS',
  },
  {
    text: 'Self-Employed Professional',
    value: 'SELF_EMPLOYED_PROFESSIONAL',
  },
  {
    text: 'Student',
    value: 'STUDENT',
  },
  {
    text: 'Homemaker',
    value: 'HOMEMAKER',
  },
  {
    text: 'Retired',
    value: 'RETIRED',
  },
  {
    text: 'Other',
    value: 'OTHER',
  },
];

export const residenceTypes: SelectionValue[] = [
  {
    text: 'Owned by Self / Family / Spouse',
    value: 'OWNED',
  },
  {
    text: 'Rented',
    value: 'RENTED',
  },
];

export const tenure: Tenure[] = [
  {
    text: 'Less than 1 year',
    value: '11month',
    tenure: {
      years: 0,
      months: 11,
    },
    tenureInMonths: 11,
  },
  {
    text: '1 - 3 years',
    value: '2years11month',
    tenure: {
      years: 2,
      months: 11,
    },
    tenureInMonths: 35,
  },
  {
    text: 'More than 3 years',
    value: '3years1month',
    tenure: {
      years: 3,
      months: 1,
    },
    tenureInMonths: 37,
  },
];

export const invalidNameParts: string[] = [
  'mr',
  'mrs',
  'miss',
  'ji',
  'shri',
  'shree',
  'jee',
  'col',
  'sri',
  'dr',
  'prof',
  'sir',
];

export const cityRegionMapping: CityRegionMap = {
  Ahmedabad: 'Ahmedabad',
  Aurangabad: 'Aurangabad',
  Ambala: 'Ambala',
  Anand: 'Vadodara',
  Bangalore: 'Bangalore',
  Bharuch: 'Vadodara',
  Bhopal: 'Bhopal',
  Chandigarh: 'Chandigarh',
  Chennai: 'Chennai',
  Cochin: 'Cochin',
  Coimbatore: 'Coimbatore',
  Dehradun: 'Dehradun',
  Delhi: 'NCR',
  Erode: 'Erode',
  Faridabad: 'NCR',
  Gandhinagar: 'Ahmedabad',
  Ghaziabad: 'NCR',
  'Greater Noida': 'NCR',
  Gurgaon: 'NCR',
  Haridwar: 'Haridwar',
  Hooghly: 'Kolkata',
  Howrah: 'Kolkata',
  Hyderabad: 'Hyderabad',
  Indore: 'Indore',
  Jaipur: 'Jaipur',
  Jodhpur: 'Jodhpur',
  'K.V.Rangareddy': 'Hyderabad',
  Kochi: 'Kochi',
  Kolhapur: 'Kolhapur',
  Kolkata: 'Kolkata',
  Mysuru: 'Mysuru',
  Ludhiana: 'Ludhiana',
  Lucknow: 'Lucknow',
  Mangalore: 'Mangalore',
  Mhow: 'Indore',
  Mohali: 'Chandigarh',
  Mumbai: 'Mumbai',
  Nagpur: 'Nagpur',
  Nashik: 'Nashik',
  'Navi Mumbai': 'Mumbai',
  'New Delhi': 'NCR',
  Noida: 'NCR',
  Panchkula: 'Chandigarh',
  Pune: 'Pune',
  Rajkot: 'Rajkot',
  Raipur: 'Raipur',
  Rangareddy: 'Hyderabad',
  Salem: 'Salem',
  Secunderabad: 'Hyderabad',
  Surat: 'Surat',
  Thane: 'Mumbai',
  Tirupathi: 'Tirupathi',
  Tiruppur: 'Tiruppur',
  Tiruchirappalli: 'Tiruchirappalli',
  Udaipur: 'Udaipur',
  Vadodara: 'Vadodara',
  Vijayawada: 'Vijayawada',
  Vishakapatnam: 'Vishakapatnam',
  Ajmer: 'Ajmer',
  Alwar: 'Alwar',
  Barmer: 'Barmer',
  Bikaner: 'Bikaner',
  Bilaspur: 'Bilaspur',
  Chittorgarh: 'Chittorgarh',
  Chittoor: 'Chittoor',
  Dewas: 'Indore',
  Durg: 'Durg',
  'East Godavari': 'East Godavari',
  Kakinada: 'East Godavari',
  Rajamundry: 'East Godavari',
  Guna: 'Guna',
  Hanumangarh: 'Hanumangarh',
  Hisar: 'Hisar',
  Jabalpur: 'Jabalpur',
  Jhunjhunu: 'Jhunjhunu',
  Kaithal: 'Kaithal',
  Katni: 'Katni',
  Khammam: 'Khammam',
  Korba: 'Korba',
  Kurnool: 'Kurnool',
  Kurukshetra: 'Kurukshetra',
  Medak: 'Medak',
  Nagaur: 'Nagaur',
  Nainital: 'Nainital',
  Haldwani: 'Nainital',
  Nizamabad: 'Nizamabad',
  Pali: 'Pali',
  Patna: 'Patna',
  Ratlam: 'Ratlam',
  Rohtak: 'Rohtak',
  Sikar: 'Sikar',
  'Sri Potti Sriramulu Nellore': 'Nellore',
  Nellore: 'Nellore',
  'Udham Singh Nagar': 'Rudrapur',
  Rudrapur: 'Rudrapur',
  Vizianagaram: 'Vizianagaram',
  Asansol: 'Asansol',
  Durgapur: 'Durgapur',
  Jamshedpur: 'Jamshedpur',
  Thiruvallur: 'Thiruvallur',
  Vellore: 'Vellore',
};

export const alternateCityNameMapping: Record<string, string> = {
  Bengaluru: 'Bangalore',
  Banglore: 'Bangalore',
  Ahmadabad: 'Ahmedabad',
  Ahemedabad: 'Ahmedabad',
  Baroda: 'Vadodara',
  Cochin: 'Kochi',
  Ernakulam: 'Kochi',
  Mysore: 'Mysuru',
  Tirupati: 'Tirupathi',
  Trichy: 'Tiruchirappalli',
  Tiruchi: 'Tiruchirappalli',
  Vizag: 'Vishakapatnam',
  Visakhapatnam: 'Vishakapatnam',
  Vishakhapatnam: 'Vishakapatnam',
  Vijaywada: 'Vijayawada',
  Gurugram: 'Gurgaon',
  Hoogly: 'Hooghly',
  Nasik: 'Nashik',
  Hugli: 'Hooghly',
  Hoogli: 'Hooghly',
  Calcutta: 'Kolkata',
  Kolkatta: 'Kolkata',
};

export const maritalStatusMapping: SelectionValue[] = [
  {
    text: 'Single',
    value: 'SINGLE',
  },
  {
    text: 'Married',
    value: 'MARRIED',
  },
];

export const loanReasonsMap: string[] = [
  'Marriage',
  'House Renovation',
  'House Deposit',
  'Pay Existing Loan',
  'Education',
  'Medical Emergency',
  'Business',
  'Others',
];

export const customerProfileReference: { [key: string]: any } = {
  name: {
    name: 'Customer Name',
    width: 'full',
  },
  referenceId: {
    name: 'Reference Id',
    width: 'full',
  },
  createdDate: {
    name: 'Created Date',
    width: 'half',
  },
  limitAmount: {
    name: 'Limit Amount',
    width: 'half',
  },
  limitAmountInWords: {
    name: 'Limit Amount In Words',
    width: 'full',
  },
  interestRate: {
    name: 'Interest Rate (%)',
    width: 'half',
  },
  interestType: {
    name: 'Interest Type',
    width: 'half',
  },
  panNumber: {
    name: 'PAN Number',
    width: 'half',
  },
  purpose: {
    name: 'Purpose',
    width: 'half',
  },
  maritalStatus: {
    name: 'Marital Status',
    width: 'half',
  },
  phone: {
    name: 'Mobile',
    width: 'half',
  },
  email: {
    name: 'Email',
    width: 'half',
  },
  lineSetupFee: {
    name: 'Line Setup Fee + Usage Fee (Usage Fee will be levied at each transaction)',
    width: 'half',
  },

  loanAmount: {
    name: 'Loan Amount',
    width: 'half',
  },
  loanAmountInWords: {
    name: 'Loan Amount In Words',
    width: 'full',
  },
};

export const bankIfscPrefixes: Array<{ [key: string]: any }> = [
  { code: 'SBIN', name: 'State Bank of India, India' },
  { code: 'UTIB', name: 'Axis Bank, India' },
  { code: 'HDFC', name: 'HDFC Bank, India' },
  { code: 'YESB', name: 'Yes Bank, India' },
  { code: 'HSBC', name: 'HSBC, India' },
  // { code: 'CITI', name: 'Citibank, India' },
  { code: 'ABHY', name: 'Abhyudaya Co-operative Bank' },
  { code: 'AMDN', name: "Ahmednagar Merchant's Co-op. Bank Ltd." },
  { code: 'AIRP', name: 'Airtel Payments Bankﾠ Ltd., India' },
  { code: 'AKJB', name: 'Akola Janata Commercial Co Operative Bank Ltd' },
  { code: 'ALLA', name: 'Allahabad Bank, India' },
  { code: 'AJHC', name: 'AMBARNATH JAI HIND COOP BANK LTD' },
  { code: 'APBL', name: 'ANDHRA PRADESH STATE CO-OP BANK' },
  { code: 'ASBL', name: 'Apna Sahakari Bank Ltd' },
  { code: 'AUBL', name: 'AU Small Finance Bank, India' },
  { code: 'DEUT', name: 'Deutsche Bank' },
  { code: 'BDBL', name: 'Bandhan Bank limited, India' },
  { code: 'BARB', name: 'Bank of Baroda, India' },
  { code: 'BKID', name: 'Bank of India, India' },
  { code: 'MAHB', name: 'Bank of Maharashtra, India' },
  { code: 'BARC', name: 'Barclays Bank, India' },
  { code: 'BACB', name: 'Bassein Catholic Co-operative Bank Ltd' },
  { code: 'BMBL', name: 'Bharatiya Mahila Bank, India' },
  { code: 'BNPA', name: 'BNP Paribas, India' },
  { code: 'CNRB', name: 'Canara Bank, India' },
  { code: 'CLBL', name: 'Capital Small Finance Bank, India' },
  { code: 'CSBK', name: 'Catholic Syrian Bank, India' },
  { code: 'CBIN', name: 'Central Bank of India, India' },
  { code: 'CIUB', name: 'City Union Bank, India' },
  { code: 'COSB', name: 'Cosmos Co-op. Bank Ltd., India' },
  { code: 'DBSS', name: 'DBS Bank, India' },
  { code: 'BKDN', name: 'Dena Bank, India' },
  { code: 'DCBL', name: 'Development Credit Bank, India' },
  { code: 'DLXB', name: 'Dhanalakshmi Bank, India' },
  { code: 'DNSB', name: 'Dombivli Nagarik Sahakari Bank' },
  { code: 'ESFB', name: 'Equitas Small Finance Bankﾠ Ltd., India' },
  { code: 'ESMF', name: 'ESAF Small Finance Bank, India' },
  { code: 'FDRL', name: 'Federal Bank, India' },
  { code: 'FINO', name: 'Fino Payments Bank, India' },
  { code: 'PJSB', name: 'Gopinath Patil Parsik Janata Sahakari Bank Ltd' },
  { code: 'ICIC', name: 'ICICI Bank, India' },
  { code: 'IBKL', name: 'IDBI Bank, India' },
  { code: 'IDFB', name: 'IDFC Bank, India' },
  { code: 'IPOS', name: 'India Post Payments bank Limited, India' },
  { code: 'IDIB', name: 'Indian Bank, India' },
  { code: 'IOBA', name: 'Indian Overseas Bank, India' },
  { code: 'INDB', name: 'IndusInd Bank, India' },
  { code: 'ITBL', name: 'Irinjalakuda Town Co-operative Bank Ltd.' },
  { code: 'JAKA', name: 'Jammu And Kashmir Bank, India' },
  { code: 'JSBL', name: 'Janakalyan Sahakari Bank Ltd.' },
  { code: 'JANA', name: 'Janaseva Sahakari Bank Ltd' },
  { code: 'JASB', name: 'Janaseva Sahakari Bank(Borivali) Ltd.' },
  { code: 'JSBP', name: 'Janata Sahakari Bank Ltd' },
  { code: 'KAIJ', name: 'Kallappanna Awade Ichalkaranji Janata Sahkari Bank' },
  { code: 'KJSB', name: 'Kalyan Janata Sahakari Bank' },
  { code: 'KUCB', name: 'Karad Urban Co-Operative Bank Ltd' },
  { code: 'KARB', name: 'Karnataka Bank, India' },
  { code: 'KVBL', name: 'Karur Vysya Bank, India' },
  { code: 'KKBK', name: 'Kotak Mahindra Bank' },
  { code: 'NICB', name: 'New India Co-op Bank Ltd' },
  { code: 'LAVB', name: 'Lakshmi Vilas Bank, India' },
  { code: 'MCBL', name: 'Mahanagar Co-operative Bank' },
  { code: 'MSNU', name: 'Mehsana Urban Co-Operative Bank' },
  { code: 'NKGS', name: 'NKGSB Bank, India' },
  { code: 'NNSB', name: 'Nutan Nagarik Sahakari Bank Ltd.' },
  { code: 'ORBC', name: 'Oriental Bank of Commerce, India' },
  { code: 'PYTM', name: 'Paytm Payments Bank, India' },
  { code: 'PMEC', name: 'Prime Co-operative Bank Ltd.' },
  { code: 'PMCB', name: 'Punjab & Maharashtra Co-Op Bank, India' },
  { code: 'PSIB', name: 'Punjab and Sind Bank, India' },
  { code: 'PUNB', name: 'Punjab National Bank, India' },
  { code: 'UTBI', name: 'United Bank of India' },
  { code: 'RRBP', name: 'RAJARAMBAPU SAHAKARI BANK LTD' },
  { code: 'RSBL', name: 'Rajgurunagar Sahakari Bank Ltd.' },
  { code: 'RNSB', name: 'Rajkot Nagarik Sahakari Bank Ltd.IMPS' },
  { code: 'RATN', name: 'RBL (Ratnakar) Bank, India' },
  { code: 'SRCB', name: 'Saraswat Bank, India' },
  { code: 'CRUB', name: 'Shri Chhatrapathi Rajarsshi Shahu Bank' },
  { code: 'SIDC', name: 'SINDHUDURG DCCB' },
  { code: 'SIBL', name: 'South Indian Bank, India' },
  { code: 'SCBL', name: 'Standard Chartered Bank, India' },
  { code: 'SURY', name: 'Suryoday Small Finance Bank, India' },
  { code: 'SVCB', name: 'SVC Co-op Bank Limited' },
  { code: 'SYNB', name: 'Syndicate Bank, India' },
  { code: 'TMBL', name: 'Tamilnadu Mercantile Bank Ltd., India' },
  { code: 'TSAB', name: 'TELANGANA STATE CO-OP APEX BANK' },
  { code: 'TBSB', name: 'Thane Bharat Sahakari Bank Ltd' },
  { code: 'APMC', name: 'The A.P. Mahesh Co-operative Urban Bank Ltd' },
  { code: 'AMCB', name: 'The Ahmedabad Mercantile Co-op Bank Ltd' },
  {
    code: 'ADCC',
    name: 'The Akola District Central Co-OperativeﾠBank ltdLive',
  },
  { code: 'BCBM', name: 'The Bharat Co-operative Bank (Mumbai) Ltd' },
  { code: 'GBCB', name: 'The Greater Bombay Co-operative Bank' },
  { code: 'GSCB', name: 'The Gujarat State Co-op Bank Ltd' },
  { code: 'HCBL', name: 'The Hasti Co-op. Bank Ltd.' },
  { code: 'HPSC', name: 'The Himachal Pradesh State Cooperative bank Ltd' },
  { code: 'JPCB', name: 'The Jalgaon Peoplesﾠ Co-op Bank Ltd.' },
  { code: 'KCCB', name: 'The Kalupur Commercial Co-Op Bank Ltd' },
  { code: 'MUBL', name: 'The Municipal Co-operative Bank Ltd' },
  { code: 'NTBL', name: 'The Nainital Bank Ltd' },
  { code: 'ORCB', name: 'THE ODISHA STATE CO-OP BANK LTD' },
  { code: 'PUCB', name: 'The Pandharpur Urban Co-op Bank Ltd.' },
  { code: 'SDCB', name: 'The Surat District Co-op Bank Ltd.' },
  { code: 'SPCB', name: 'The Surat Peoples Co-op Bank Ltdﾠ' },
  { code: 'SUTB', name: 'The SUTEX Co-operative Bank Ltd' },
  { code: 'TNSC', name: 'THE TAMILNADU STATE APEX COOP BANK' },
  { code: 'VARA', name: 'The Varachha Co-op Bank Ltd' },
  { code: 'VVSB', name: 'The Vasai Vikas Co-op Bank Ltd' },
  { code: 'VSBL', name: 'The Vishweshwar Sahakari Bank Ltd.' },
  { code: 'THRS', name: 'Thrissurﾠ District Co-operative Bank' },
  { code: 'TJSB', name: 'TJSB Sahakari Bank, India' },
  { code: 'UCBA', name: 'UCO Bank, India' },
  { code: 'UBIN', name: 'Union Bank of India, India' },
  { code: 'UTKS', name: 'Utkarsh Small Finance Bank, India' },
  { code: 'VIJB', name: 'Vijaya Bank, India' },
  { code: 'Others', name: 'Others' },
];

export const daysToExpiry: number = 30;

export const INPUT_DATE_REGEX: RegExp = /([0-9]{2})([0-9]{2})([0-9]{4})/;

export const OUTPUT_DATE_REGEX: RegExp = /([0-9]{4})-([0-9]{2})-([0-9]{2})/;

export const IND_PHONE_REGEX: RegExp = /^(\+[0-9]?[0-9])(\s|\S)(\d[0-9]{9})$/;

export const MASK_TOKENS: any = {
  '#': { pattern: /\d/ },
  S: { pattern: /[a-zA-Z]/ },
  A: { pattern: /[0-9a-zA-Z]/ },
  U: { pattern: /[a-zA-Z]/, transform: (v: string) => v.toLocaleUpperCase() },
  L: { pattern: /[a-zA-Z]/, transform: (v: string) => v.toLocaleLowerCase() },
};

export const HOUR_IN_MILLISECONDS = 3600000;

export const CANCELLED_REQUEST_TEXT = 'the user cancelled the request';

export const LOGIN_ROUTES = ['customerLogin', 'gpay-customerLogin'];

export const APPROVED_APP_ROUTES = ['approvedApplication'];

export const ONE_SEC_IN_MILLISECONDS = 1000;

export const FIVE_MINS_IN_MILLISECONDS = 300 * ONE_SEC_IN_MILLISECONDS;

export const ONE_DAY_IN_MS = 86400000;

export const bankToLogoMapping: any = [
  {
    name: 'BANK OF BARODA',
    imageURL: require('@/assets/images/bank_of_baroda.png'),
  },
  { name: 'STATE BANK OF INDIA', imageURL: require('@/assets/images/sbi.png') },
  {
    name: 'EQUITAS SMALL FINANCE BANK LIMITED',
    imageURL: require('@/assets/images/equitas.png'),
  },
  { name: 'AXIS BANK', imageURL: require('@/assets/images/axis.png') },
  { name: 'CANARA BANK', imageURL: require('@/assets/images/canara_bank.png') },
  {
    name: 'FEDERAL BANK',
    imageURL: require('@/assets/images/federal_bank_logo.png'),
  },
  { name: 'HDFC BANK', imageURL: require('@/assets/images/hdfc.png') },
  { name: 'ICICI BANK', imageURL: require('@/assets/images/icici.png') },
  {
    name: 'KOTAK MAHINDRA BANK',
    imageURL: require('@/assets/images/kotak.png'),
  },
  { name: 'INDIAN BANK', imageURL: require('@/assets/images/indian_bank.png') },
];

export const CAP_UPLOAD_DETAILS = [
  {
    key: 'electricity_bill',
    docGroup: 'commonly_uploaded',
    docSubGroup: 'electricity_bill',
    priority: 1,
    maxFilesAllowed: 3,
    title: 'Upload your electricity bill',
    name: 'Electricity Bill',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
      'Must not be older than 35 days',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'mobile_postpaid_bill',
    docGroup: 'commonly_uploaded',
    docSubGroup: 'mobile_postpaid_bill',
    priority: 2,
    maxFilesAllowed: 3,
    title: 'Upload your mobile, landline or broadband bill',
    name: 'Mobile, landline or broadband bill',
    condition: 'Mobile prepaid bills not accepted',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
      'Must not be older than 35 days',
      'Cannot be a prepaid bill',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'water_bill',
    docGroup: 'commonly_uploaded',
    docGroupName: 'Commonly Uploaded',
    docSubGroup: 'water_bill',
    priority: 3,
    maxFilesAllowed: 3,
    title: 'Upload your water bill',
    name: 'Water bill',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
      'Must not be older than 35 days',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'gas_bill',
    docGroup: 'commonly_uploaded',
    docGroupName: 'Commonly Uploaded',
    docSubGroup: 'gas_bill',
    priority: 4,
    maxFilesAllowed: 3,
    title: 'Upload your cooking gas bill',
    name: 'Cooking gas bill',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
      'Must not be older than 35 days',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'bank_statement',
    docGroup: 'commonly_uploaded',
    docGroupName: 'Commonly Uploaded',
    docSubGroup: 'bank_statement',
    priority: 5,
    maxFilesAllowed: 3,
    title: 'Upload your bank statement',
    name: 'Bank statement or passbook',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
      'Must not be older than 35 days',
    ],
    passwordRequired: true,
    files: [],
  },
  {
    key: 'rental_agreement',
    docGroup: 'other_documents',
    docGroupName: 'Other Documents',
    docSubGroup: 'rental_agreement',
    priority: 1,
    maxFilesAllowed: 3,
    title: 'Upload your rental agreement',
    name: 'Rental agreement',
    condition: "Along with utility bill in landlord's name",
    description: [
      'Must mention your name',
      'Must have the address mentioned above',
      'Must not be older than 12 months',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'utility_bill',
    docGroup: '',
    docGroupName: '',
    docSubGroup: 'rental_agreement',
    maxFilesAllowed: 3,
    title: 'Upload your utility bill',
    description: [
      "Must have your landlord's name",
      'Must have the address mentioned above',
      'Must not be older than 35 days',
      'Cannot be a prepaid bill',
    ],
    files: [],
  },
  {
    key: 'vehicle_registration_certificate',
    docGroup: 'other_documents',
    docGroupName: 'Other Documents',
    docSubGroup: 'vehicle_registration_certificate',
    priority: 2,
    maxFilesAllowed: 2,
    title: 'Upload your vehicles registration certificate (RC)',
    name: 'Vehicle registration certificate (RC)',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'employer_accommodation_allocation_letter',
    docGroup: 'other_documents',
    docGroupName: 'Other Documents',
    docSubGroup: 'employer_accommodation_allocation_letter',
    priority: 3,
    maxFilesAllowed: 3,
    title: 'Upload proof of address from your employer or HR',
    name: 'Proof of address from employer or HR',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
      "Must be on the employer's letter head",
      'Must be from your present employer',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'voter_id',
    docGroup: 'other_documents',
    docGroupName: 'Other Documents',
    docSubGroup: 'voter_id',
    priority: 4,
    maxFilesAllowed: 2,
    title: 'Upload your voter ID',
    name: 'Voter ID',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'driving_license',
    docGroup: 'other_documents',
    docGroupName: 'Other Documents',
    docSubGroup: 'driving_license',
    priority: 5,
    maxFilesAllowed: 2,
    title: 'Upload your driving license',
    name: 'Driving License',
    condition: 'Delhi driving license may not be accepted',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
      'Cannot be a driving license from Delhi',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'passport',
    docGroup: 'other_documents',
    docGroupName: 'Other Documents',
    docSubGroup: 'passport',
    priority: 6,
    maxFilesAllowed: 2,
    title: 'Upload your passport',
    name: 'Passport',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
    ],
    passwordRequired: false,
    files: [],
  },
  {
    key: 'generic_address_proof',
    docGroup: 'generic_document',
    docGroupName: 'Generic Document(s)',
    docSubGroup: 'generic_address_proof',
    priority: 1,
    maxFilesAllowed: 3,
    title: 'Upload address proof',
    name: 'Some other document',
    description: [
      'Must have your name',
      'Must have the address mentioned above',
    ],
    passwordRequired: false,
    files: [],
  },
];

export function getUploadOptionsByDocGroup(capUploadDetails: any) {
  return groupBy(capUploadDetails, 'docGroup', true);
}

export function categories(CAP_DETAILS: any) {
  const categorizedData: any = {
    commonly_uploaded: [],
    other_documents: [],
    generic_document: [],
  };
  categorizedData.commonly_uploaded = CAP_DETAILS.filter(
    (item: any) => item.category === 'commonly_uploaded'
  ).sort((itemA: any, itemB: any) => itemA.priority - itemB.priority);
  categorizedData.other_documents = CAP_DETAILS.filter(
    (item: any) => item.category === 'other_documents'
  ).sort((itemA: any, itemB: any) => itemA.priority - itemB.priority);
  categorizedData.generic_document = CAP_DETAILS.filter(
    (item: any) => item.category === 'generic_document'
  ).sort((itemA: any, itemB: any) => itemA.priority - itemB.priority);
  return categorizedData;
}

export const mandateSetupStatusData: any = {
  BLOCKED: {
    title: 'Your bank does not support digital auto-repayments',
    description:
      'Please <b>change your beneficiary details</b> and choose another bank that supports it. Most popular banks do.',
    buttonText: 'Change beneficiary details',
    bgcolor: 'linear-gradient(90deg, #EA6270, #EA8870)',
    color: '#EA8870',
    route: 'cm-beneficiary-details',
    routeName: 'routes.beneficiaryDetails',
    icon: require('../assets/icons/error_wait.svg'),
  },
  BD_PENDING: {
    title: 'Almost ready to start using your money',
    description:
      'Before you withdraw any amount from your credit limit, <b>add your bank account</b> where you’d like to receive the money.',
    buttonText: 'Set up beneficiary details',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: 'cm-beneficiary-details',
    routeName: 'routes.beneficiaryDetails',
    icon: require('../assets/images/semicircle-bar.gif'),
  },
  CX_ACTION_PENDING: {
    title: 'Last step before you can start using your money',
    description:
      '<b>Set up auto-repayments</b> and never miss an EMI. Avoid the hassle of manually repaying each month. Always maintain a perfect credit score.',
    buttonText: 'Set up auto-repayments',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: 'cm-setup-emandate',
    routeName: 'routes.setupEmandate',
    icon: require('../assets/images/semicircle-bar.gif'),
  },
  EMANDATE_CX_ATN_PENDING: {
    title: 'Last step before you can start using your money',
    description:
      '<b>Set up auto-repayments</b> and never miss an EMI. Avoid the hassle of manually repaying each month. Always maintain a perfect credit score.',
    buttonText: 'Set up auto-repayments',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: 'cm-setup-emandate',
    routeName: 'routes.setupEmandate',
    icon: require('../assets/images/semicircle-bar.gif'),
  },
  DOCUMENT_REVIEW: {
    title: 'Reviewing your documents',
    description:
      'Hold on just a while longer. We’ll inform you about the next steps shortly.',
    buttonText: null,
    bgcolor: 'linear-gradient(90deg, #1CA2BC, #1C7EBC)',
    color: '#1C7FBC',
    route: null,
    routeName: null,
    icon: require('../assets/images/icon-wait.png'),
  },
  EMANDATE_DOCUMENT_REVIEW: {
    title: 'Verifying status of your auto-repayments',
    description:
      'Hold on for a few minutes. We’ll inform you about the next steps shortly.',
    buttonText: null,
    bgcolor: 'linear-gradient(90deg, #1CA2BC, #1C7EBC)',
    color: '#1C7FBC',
    route: null,
    routeName: null,
    icon: require('../assets/images/icon-wait.png'),
  },
  NACH_DOCUMENT_REVIEW: {
    title: 'Verifying status of your auto-repayments',
    description:
      'Hold on for a few minutes. We’ll inform you about the next steps shortly.',
    buttonText: null,
    bgcolor: 'linear-gradient(90deg, #1CA2BC, #1C7EBC)',
    color: '#1C7FBC',
    route: null,
    routeName: null,
    icon: require('../assets/images/icon-wait.png'),
  },
  FINOPS_REVIEW: {
    title: 'Failed to set up auto-repayments',
    description:
      'We’re working to fix this. We might need to set up auto-repayments using a physical form. If needed, we’ll pick it up from your address in a few days. Still need help? Reach out to us at <a href="mailto:hello@moneytap.com" style="color:white;">hello@moneytap.com</a>',
    buttonText: null,
    bgcolor: 'linear-gradient(90deg, #EA6270, #EA8870)',
    color: '#EA8870',
    route: null,
    routeName: null,
    icon: require('../assets/icons/error_wait.svg'),
  },
  PENNY_DROP_REVIEW: {
    title: 'Verifying your beneficiary details…',
    description:
      'Meanwhile, <b>set up auto-repayments</b> and never miss an EMI. Avoid the hassle of manually repaying each month. Always maintain a perfect credit score.',
    buttonText: 'Set up auto-repayments',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: 'cm-setup-emandate',
    routeName: 'routes.setupEmandate',
    icon: require('../assets/images/semicircle-bar.gif'),
  },
  PENNY_DROP_REVIEW_EMANDATE_COMPLETED: {
    title: 'Reviewing your documents',
    description:
      'Hold on just a while longer. We’ll inform you about the next steps shortly.',
    buttonText: null,
    bgcolor: 'linear-gradient(90deg, #1CA2BC, #1C7EBC)',
    color: '#1C7FBC',
    route: null,
    routeName: null,
    icon: require('../assets/images/icon-wait.png'),
  },
  PENNY_DROP_REVIEW_EMANDATE_ELIGIBLE: {
    title: 'Verifying your beneficiary details…',
    description:
      'Meanwhile, <b>set up auto-repayments</b> and never miss an EMI. Avoid the hassle of manually repaying each month. Always maintain a perfect credit score.',
    buttonText: 'Set up auto-repayments',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: 'cm-setup-emandate',
    routeName: 'routes.setupEmandate',
    icon: require('../assets/images/semicircle-bar.gif'),
  },
  NACH_PICKUP_PENDING: {
    title: 'Our agent will call you to book an appointment',
    description:
      'They’ll visit you to <b>set up auto-repayments</b> using a physical form. After setting it up, you can start using your money immediately.',
    buttonText: 'View appointment status',
    bgcolor: 'linear-gradient(90deg, #1CA2BC, #1C7EBC)',
    color: '#1C7FBC',
    route: 'cm-schedule-appointment',
    routeName: 'routes.scheduleAppointment',
    icon: require('../assets/images/icon-wait.png'),
  },
  PICKUP_PENDING_EMANDATE_COMPLETED: {
    title: 'Failed to verify your beneficiary details',
    description:
      'That’s all right. You can ,<b>upload proof of bank account to verify.</b>',
    buttonText: 'Upload bank account proof',
    bgcolor: 'linear-gradient(90deg, #EA6270, #EA8870)',
    color: '#EA8870',
    route: 'cm-submit-bank-proof',
    routeName: 'routes.submitBankProof',
    icon: require('../assets/icons/error_wait.svg'),
  },
  PICKUP_PENDING_EMANDATE_ELIGIBLE: {
    title: 'Last step before you can start using your money',
    description:
      '<b>Set up auto-repayments</b> and never miss an EMI. Avoid the hassle of manually repaying each month. Always maintain a perfect credit score.',
    buttonText: 'Set up auto-repayments',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: 'cm-setup-emandate',
    routeName: 'routes.setupEmandate',
    icon: require('../assets/images/semicircle-bar.gif'),
  },
  REJECTED: {
    title: 'Account Disabled',
    description:
      'Your IMPS has been disabled. Please contact <a href="mailto:hello@moneytap.com" style="color:white;">hello@moneytap.com</a> for more information',
    buttonText: null,
    bgcolor: 'linear-gradient(90deg, #EA6270, #EA8870)',
    color: '#EA6970',
    route: null,
    routeName: null,
    icon: require('../assets/icons/error_wait.svg'),
  },
  VERIFIED: {
    title: 'You’re all set!',
    description:
      'You can now select and transfer any amount from your credit line.',
    buttonText: 'Got it',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: null,
    routeName: null,
    icon: require('../assets/images/complete.gif'),
  },
  MANDATE_PENDING_EMANDATE_COMPLETED: {
    title: 'Failed to verify your beneficiary details',
    description:
      'That’s all right. You can ,<b>upload proof of bank account to verify.</b>',
    buttonText: 'Upload bank account proof',
    bgcolor: 'linear-gradient(90deg, #EA6270, #EA8870)',
    color: '#EA8870',
    route: 'cm-submit-bank-proof',
    routeName: 'routes.submitBankProof',
    icon: require('../assets/icons/error_wait.svg'),
  },
  MANDATE_PENDING_EMANDATE_ELIGIBLE: {
    title: 'Last step before you can start using your money',
    description:
      '<b>Set up auto-repayments</b> and never miss an EMI. Avoid the hassle of manually repaying each month. Always maintain a perfect credit score.',
    buttonText: 'Set up auto-repayments',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: 'cm-setup-emandate',
    routeName: 'routes.setupEmandate',
    icon: require('../assets/images/semicircle-bar.gif'),
  },
  // NA: {
  //   title: 'You’re all set!',
  //   description:
  //     'You can now select and transfer any amount from your credit line.',
  //   buttonText: 'Got it',
  //   bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
  //   color: '#248472',
  //   route: null,
  //   routeName: null,
  //   icon: require('../assets/images/complete.gif'),
  // },
  MANDATE_NOT_REQUIRED: {
    title: 'Almost ready to start using your money',
    description:
      'Before you withdraw any amount from your credit limit, <b>add your bank account</b> where you’d like to receive the money.',
    buttonText: 'Set up beneficiary details',
    bgcolor: 'linear-gradient(90deg, #008477, #08A07B)',
    color: '#248472',
    route: 'cm-beneficiary-details',
    routeName: 'routes.beneficiaryDetails',
    icon: require('../assets/images/semicircle-bar.gif'),
  },
};

export const bankProofData: any = {
  BANK_STATEMENTS: {
    instructions: [
      'You may download bank statements from your bank’s <b>mobile-banking app or net-banking website.</b>',
      'Please <b>upload a PDF</b> or a <b>screenshot</b> of it. Alternatively, you can <b>take a photo</b> or <b>upload a scan</b> of it.',
      'You can upload bank statements for <b>any duration.</b>',
      'Ensure that your <b>bank details are clearly visible</b> on the document.',
      'Ensure that you upload or take a <b>photo of the original</b> document.',
      'Document should be <b>clear, readable</b>, and <b>coloured.</b>',
      'Do <b>not</b> upload <b>black & white or photo-copied images.</b>',
    ],
    title: 'Bank Statements',
    key: 'bank_statements',
    passwordRequired: true,
    maxFilesAllowed: 1,
    files: [],
  },
  CANCELLED_CHEQUE: {
    instructions: [
      'Ensure that the cheque has the word <b>“CANCELLED”</b> written across it.',
      '<b>All 4 corners</b> of the document should be <b>visible.</b>',
      'Do <b>not</b> take a <b>photo of your computer screen</b> to upload the document.',
      'Ensure that your <b>bank details are clearly visible</b> on the document.',
      'Ensure that you upload or take a <b>photo of the original</b> document.',
      'Document should be <b>clear, readable</b>, and <b>coloured.</b>',
      'Do <b>not</b> upload <b>black & white or photo-copied images.</b>',
    ],
    title: 'Cancelled Cheque',
    key: 'cancelled_cheques',
    passwordRequired: false,
    maxFilesAllowed: 1,
    files: [],
  },
  BANK_PASSBOOK: {
    instructions: [
      'Take photo of the passbook page that mentions your <b>name, bank account number</b>, and <b>bank IFSC.</b>',
      '<b>All 4 corners</b> of the document should be <b>visible.</b>',
      'Do <b>not</b> take a <b>photo of your computer screen</b> to upload the document.',
      'Ensure that your <b>bank details are clearly visible</b> on the document.',
      'Ensure that you upload or take a <b>photo of the original</b> document.',
      'Document should be <b>clear, readable</b>, and <b>coloured.</b>',
      'Do <b>not</b> upload <b>black & white or photo-copied images.</b>',
    ],
    title: 'Bank Passbook',
    key: 'bank_passbook',
    passwordRequired: false,
    maxFilesAllowed: 1,
    files: [],
  },
};

export const freoConnectPartners: Record<string, any> = {
  INDIALENDS: {
    name: 'IndiaLends',
    icon: require('@/assets/icons/indialends.svg'),
    brandIcon: require('@/assets/images/indialends-mt.svg'),
  },
  CREDITHAAT: {
    name: 'CreditHaat',
    icon: require('@/assets/icons/credithaat.svg'),
    brandIcon: require('@/assets/images/credithaat-mt.svg'),
  },
  UPWARDS: {
    name: 'Upward',
    icon: require('@/assets/icons/upwards.svg'),
    brandIcon: require('@/assets/images/upwards-mt.svg'),
  },
};

export const priorityMap: Record<string, number> = {
  URGENT: 100,
  ACUTE: 99,
  CRITICAL: 95,
  IMPORTANT: 80,
  HIGH: 60,
  MEDIUM: 40,
  LOW: 20,
};

export const offerTnc: string[] = [
  'Offer amount shown is the maximum you can save by choosing the highest loan amount and the longest tenure available',
  'Offer is applicable on transaction within 15 days after the offer is applied',
  'The offer can be deactivated in case of non-eligibility or at the sole discretion of MoneyTap or the Lending Partner',
];

export const cashBackOfferTnc: string[] = [
  'Unlock credit limit and complete all pending steps to get money instantly in your account',
  'Cashback will be sent to the bank details provided by you',
];

export const APPROVED_LIMIT_THRESHOLD_FOR_CASHBACK = 5000;

export const beneficiaryVarifiedStatuses = [
  'VERIFIED',
  'MANDATE_NOT_REQUIRED',
  'UNKNOWN',
];
