import { ACCESS_DETAILS } from '@/store/store_keys';
import { getLocalKey } from '@/store/local_store';
import { Auth } from '@/interfaces/auth';
import { isEmpty } from '@/lib/helpers';
import { storeAccessDetails } from '@/store/actions/auth';
import { isMicroappsEnabled } from './utils';
import { HOUR_IN_MILLISECONDS } from './consts';

export function isCustomerAuthenticated(auth: Auth): Promise<boolean> {
  return new Promise((resolve) => {
    const { short_lived, expiry_time, authenticated, access_token } = auth;
    const nowEpoch = new Date().getTime();
    if (short_lived && expiry_time && nowEpoch > expiry_time) {
      let payload: Auth = {
        access_token: '',
        authenticated: false,
      };
      if (isMicroappsEnabled()) {
        payload = {
          ...payload,
          short_lived: true,
          expiry_time: new Date().getTime() + HOUR_IN_MILLISECONDS,
        };
      } else {
        payload = {
          ...payload,
          short_lived: false,
          expiry_time: new Date().getTime() + HOUR_IN_MILLISECONDS,
        };
      }
      storeAccessDetails({
        payload,
      });
      resolve(false);
    }
    if (authenticated && access_token) {
      resolve(true);
    }
    const accessDetails = getLocalKey(ACCESS_DETAILS);
    if (
      !isEmpty(accessDetails) &&
      accessDetails.authenticated &&
      accessDetails.access_token
    ) {
      storeAccessDetails({ payload: accessDetails });
      resolve(true);
    }
    resolve(false);
  });
}

export function isCustomerAuthenticatedSync(auth: Auth): boolean {
  const { short_lived, expiry_time, authenticated, access_token } = auth;
  const nowEpoch = new Date().getTime();
  if (short_lived && expiry_time && nowEpoch > expiry_time) {
    let payload: Auth = {
      access_token: '',
      authenticated: false,
    };
    if (isMicroappsEnabled()) {
      payload = {
        ...payload,
        short_lived: true,
        expiry_time: new Date().getTime() + HOUR_IN_MILLISECONDS,
      };
    } else {
      payload = {
        ...payload,
        short_lived: false,
        expiry_time: new Date().getTime() + HOUR_IN_MILLISECONDS,
      };
    }
    storeAccessDetails({
      payload,
    });
    return false;
  }
  if (authenticated && access_token) {
    return true;
  }
  const accessDetails = getLocalKey(ACCESS_DETAILS);
  if (
    !isEmpty(accessDetails) &&
    accessDetails.authenticated &&
    accessDetails.access_token
  ) {
    storeAccessDetails({ payload: accessDetails });
    return true;
  }
  return false;
}
