import { Nullable } from '@/types';

export default class NetBankingDetails {
  private _status?: Nullable<string>;
  private _type?: Nullable<string>;
  // tslint:disable-next-line: variable-name
  private _netbanking_txnId?: Nullable<string>;
  private _txnId?: Nullable<string>;
  private _customer?: Nullable<string>;

  public get status(): Nullable<string> {
    return this._status || null;
  }

  public set status(status: Nullable<string>) {
    this._status = status;
  }

  public get type(): Nullable<string> {
    return this._type || null;
  }

  public set type(type: Nullable<string>) {
    this._type = type;
  }

  public get netbanking_txnId(): Nullable<string> {
    return this._netbanking_txnId || null;
  }

  public set netbanking_txnId(netbankingTxnId: Nullable<string>) {
    this._netbanking_txnId = netbankingTxnId;
  }

  public get txnId(): Nullable<string> {
    return this._txnId || null;
  }

  public set txnId(txnId: Nullable<string>) {
    this._txnId = txnId;
  }

  public get customer(): Nullable<string> {
    return this._customer || null;
  }

  public set customer(customer: Nullable<string>) {
    this._customer = customer;
  }
}
