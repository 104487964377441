import './promise_polyfill';
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import {
  ContextLines,
  Debug,
  ExtraErrorData,
  HttpClient,
} from '@sentry/integrations';
import App from './App.vue';
import router from './router';
import loadPolyfills from './polyfills';
import './registerServiceWorker';
import { getLocalKey } from './store/local_store';
import { SESSION_ID } from './store/store_keys';
import { get } from './lib/helpers';
import { store } from '@/store';
import { uuid } from './lib/utils';
const state = store.getState();

Vue.config.productionTip = false;

Vue.config.errorHandler = (err: any) => {
  Sentry.withScope((scope: any) => {
    const customerId = get(state as object, 'customer.customerId');
    const sessionId = getLocalKey(SESSION_ID) || uuid();
    const phone = get(state as object, 'customer.customerDetails.phone', '');
    customerId && scope.setTag('customerId', customerId);
    customerId && phone && scope.setUser({ id: customerId, userName: phone });
    sessionId && scope.setTag('sessionId', `id:${sessionId}`);
    phone && scope.setTag('phone', phone);
    Sentry.captureMessage(err);
  });
};

window.onerror = (message: any) => {
  Sentry.withScope((scope: any) => {
    const customerId = get(state as object, 'customer.customerId');
    const sessionId = getLocalKey(SESSION_ID) || uuid();
    const phone = get(state as object, 'customer.customerDetails.phone', '');
    customerId && scope.setTag('customerId', customerId);
    sessionId && scope.setTag('sessionId', `id:${sessionId}`);
    phone && scope.setTag('phone', phone);
    customerId && phone && scope.setUser({ id: customerId, userName: phone });
    Sentry.captureMessage(message);
  });
};

window.onunhandledrejection = (event: any) => {
  Sentry.withScope((scope: any) => {
    const customerId = get(state as object, 'customer.customerId');
    const sessionId = getLocalKey(SESSION_ID) || uuid();
    const phone = get(state as object, 'customer.customerDetails.phone', '');
    customerId && scope.setTag('customerId', customerId);
    sessionId && scope.setTag('sessionId', `id:${sessionId}`);
    phone && scope.setTag('phone', phone);
    customerId && phone && scope.setUser({ id: customerId, userName: phone });
    Sentry.captureMessage(event);
  });
};

Sentry.init({
  Vue,
  dsn: 'https://5e5ab94fa8248ef6edb94d028ef53577@sentry.freomoney.com/13',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.ModuleMetadata(),
    new HttpClient(),
    new ExtraErrorData(),
    new Debug(),
    new ContextLines(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.8,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://web.moneytap.com/#/'],

  // Capture Replay for 20% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  trackComponents: true,
  environment: process.env.NODE_ENV,
  debug: process.env.NODE_ENV !== 'production',
  release: `freo-credit-webapp@${process.env.VUE_APP_VERSION}` || '1.0.0',
  attachStacktrace: true,
  enableTracing: true,
  ignoreErrors: [
    /fbevents.js/i,
    /Loading CSS chunk/i,
    /Failed to fetch/i,
    /wasm streaming compile failed/i,
    /Failed to read the \/'contentDocument\/' property from \/'HTMLIFrameElement\/'/i,
    /Failed to execute \/'transaction\/' on \/'IDBDatabase\/'/i,
    /Meta Pixel/i,
    /actionInDocInited/i,
    /sentry\/integrations/i,
    /appJsBridge already exist/i,
    /getReadModeExtract/i,
    /getReadModeRender/i,
    /getReadModeConfig/i,
  ],
  denyUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /graph\.facebook\.com/i,
  ],
});

function initialize() {
  new Vue({
    router,
    render(h) {
      return h(App);
    },
  }).$mount('#app');
  import('@/analytics/base').then((analytics) => analytics.init());
}

loadPolyfills(initialize);
