import * as types from '../action_types/magic_link';
import {
  fetchCustomerData,
  updateAccessToken,
  getAccessToken as getMagicLinkAccessToken,
  getAdditionalDetails,
} from '@/api/magic_link';
import { store } from '@/store';
import { setLocalKey } from '../local_store';
import {
  LINK_ID,
  CURRENT_ACTION_ID,
  MAGIC_LINK_ACCESS_DETAILS,
  MAGIC_LINK_DATA,
  FINANCE_REPORT_META_DATA,
} from '../store_keys';
import bus from '@/lib/event_bus';
import { get } from '@/lib/helpers';

export function setCurrentActionId(data: {
  payload: { [key: string]: string };
}) {
  setLocalKey(CURRENT_ACTION_ID, data.payload.currentActionId || '');
  store.dispatch({
    type: types.SET_CURRENT_ACTION_ID,
    payload: data.payload,
  });
}

export function setLinkId(data: { payload: { [key: string]: string } }) {
  setLocalKey(LINK_ID, data.payload.linkId || '');
  store.dispatch({
    type: types.SET_LINK_ID,
    payload: data.payload,
  });
}

export function storeLinkDetails(data: { payload: any }) {
  setLocalKey(MAGIC_LINK_DATA, data.payload || '');
  store.dispatch({
    type: types.STORE_LINK_DETAILS,
    payload: {
      ...data.payload,
      customerBasicProfile: get(data, 'payload.customerBasicProfile', {}),
      tasks: get(data, 'payload.tasks', []),
    },
  });
}

export function storeOkycMetaData(data: { payload: any }) {
  store.dispatch({
    type: types.STORE_OKYC_META_DATA,
    payload: data.payload,
  });
}

export function storeFinanceReportMetaData(data: { payload: any }) {
  setLocalKey(FINANCE_REPORT_META_DATA, data.payload || '');
  store.dispatch({
    type: types.STORE_FINANCE_REPORT_META_DATA,
    payload: data.payload,
  });
}

export function retrieveMagicLinkDetails(linkId: string) {
  return new Promise((resolve, reject) => {
    fetchCustomerData(linkId)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          storeLinkDetails({
            payload: response.data,
          });
          resolve(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
        console.log('Something went wrong. Please contact Freo', error);
      });
  });
}

export function getAdditionalData(linkId: string, action: string) {
  return new Promise((resolve, reject) => {
    getAdditionalDetails(linkId, action)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          resolve(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
        console.log('Something went wrong. Please contact Freo', error);
      });
  });
}

export function getAccessToken() {
  return new Promise((resolve, reject) => {
    bus.$emit('loader', {
      show: true,
      message: 'Fetching details',
    });
    getMagicLinkAccessToken()
      .then((response: any) => {
        if (response.ok) {
          setLocalKey(MAGIC_LINK_ACCESS_DETAILS, response.data);
          storeAccessDetails({ payload: response.data });
          resolve(response.data);
        } else {
          const status = get(response, 'status');
          let errorMessage = 'Unable to fetch details';
          if (get(response, 'data.error_description')) {
            errorMessage = get(response, 'data.error_description');
          } else if ([400, 404, 500, 502].includes(status)) {
            errorMessage = 'Unable to fetch details';
          }
          bus.$emit('notify', {
            type: 'error',
            title: 'Error',
            message: errorMessage,
            showClose: false,
          });
          store.dispatch(clearAccessDetails());
        }
      })
      .catch((err: any) => {
        reject({ error: err });
        bus.$emit('notify', {
          type: 'error',
          title: 'Error',
          message: 'Something went wrong. Please contact Freo',
          autoClose: 3000,
          showClose: false,
        });
      })
      .finally(() => {
        bus.$emit('loader');
      });
  });
}

export function refreshAccessToken(refreshToken: string) {
  return new Promise((resolve, reject) => {
    bus.$emit('loader', {
      show: true,
      message: 'Fetching details',
    });
    updateAccessToken(refreshToken)
      .then(async (response: any) => {
        if (response.ok) {
          storeAccessDetails(response.data);
          setLocalKey(MAGIC_LINK_ACCESS_DETAILS, response.data);
          resolve(true);
        } else {
          store.dispatch(clearAccessDetails());
          setLocalKey(MAGIC_LINK_ACCESS_DETAILS, {});
          resolve(false);
          const status = get(response, 'status');
          let errorMessage = 'Unable to fetch details';
          if (get(response, 'data.error_description')) {
            errorMessage = get(response, 'data.error_description');
          } else if ([400, 404, 500, 502].includes(status)) {
            errorMessage = 'Unable to fetch details';
          }
          bus.$emit('notify', {
            type: 'error',
            title: 'Error',
            message: errorMessage,
            showClose: false,
          });
        }
      })
      .catch((error: any) => {
        reject(error);
        bus.$emit('notify', {
          type: 'error',
          title: 'Error',
          message: 'Something went wrong. Please contact Freo',
          autoClose: 3000,
          showClose: false,
        });
      });
  }).finally(() => {
    bus.$emit('loader');
  });
}

export function clearAccessDetails() {
  return {
    type: types.CLEAR_ACCESS_DETAILS,
    payload: {
      access_token: '',
      refresh_token: '',
      expires_in: 0,
      scope: '',
      token_type: '',
    },
  };
}

export const resetActionId = (response: any) => {
  setCurrentActionId({ payload: { currentActionId: '' } });
  return response;
};

export function storeAccessDetails(data: { payload: any }) {
  store.dispatch({
    type: types.STORE_ACCESS_DETAILS,
    payload: data.payload,
  });
}
