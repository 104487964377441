export enum ApplicationStatusCodeComponent {
  COARSE,
  FINE,
  WHOLE,
}

export enum Precision {
  EXACTLY,
  GREATER_THAN,
  GREATER_THAN_OR_EQUALS,
  LESSER_THAN,
  LESSER_THAN_OR_EQUALS,
}

export enum BinaryPrecision {
  BETWEEN_INCLUSIVE_OPERAND_1,
  BETWEEN_INCLUSIVE_OPERAND_2,
  BETWEEN_INCLUSIVE,
  BETWEEN_EXCLUSIVE,
}
