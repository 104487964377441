import { hasOwnProperty, isEmpty } from '@/lib/helpers';

const localStore: any = (window as any).localStorage;

/**
 * Set a key-value pair in local storage
 * @param {String} key
 * @param {Object} data
 */
export function setLocalKey(key: string, data: any) {
  if (localStore) {
    localStore.setItem(key, JSON.stringify(data));
  }
}
/**
 * Set a key-value pair in localstorage with expiry
 * @param {string} key
 * @param {any} data
 * @param {number} ttl
 */
export function setLocalKeyWithExpiry(key: string, data: any, ttl: number) {
  const now = new Date();
  const item = {
    value: data,
    expiry: now.getTime() + ttl,
  };
  localStore.setItem(key, JSON.stringify(item));
}

/**
 * Returns the value for the key provided from local storage with or without expiry
 * @param {String} key
 * @returns {any}
 */
export function getLocalKey(key: string): any {
  try {
    if (localStore) {
      const item = JSON.parse(localStore.getItem(key) || '{}');
      if (
        item &&
        !isEmpty(item) &&
        hasOwnProperty(item, 'expiry') &&
        hasOwnProperty(item, 'value')
      ) {
        const now = new Date();
        if (now.getTime() > item.expiry) {
          localStorage.removeItem(key);
          return null;
        }
      }
      if (!isEmpty(item)) {
        return item;
      }
      return null;
    }
  } catch (err) {
    console.log('Error while fetching %s: ', key, err);
    deleteLocalKey(key);
    return null;
  }
  return null;
}

/**
 * Deletes a particular key-value pair
 * @param {String} key
 */
export function deleteLocalKey(key: string) {
  if (localStore && key) {
    localStore.removeItem(key);
  }
}
