import { get } from '@/lib/helpers';
import { updateLayoutConfig } from '@/lib/layout_config';
import { layoutConfig } from '@/lib/layout_consts';
export const loanRestructureRoutes = {
  path: '/loan-restructure',
  // name: 'loanRestructureLayout',
  meta: { requiresAuth: false },
  component: () =>
    import(
      /* webpackChunkName: "loan-restructure-home" */ '@/layouts/LoanRestructureLayout.vue'
    ),
  beforeEnter: (to: any, from: any, next: any) => {
    updateLayoutConfig('LOAN_RESTRUCTURE_LAYOUT');
    next();
  },
  children: [
    {
      path: '',
      name: get(layoutConfig, 'LOAN_RESTRUCTURE_LAYOUT.routes.aipHome', ''),
      props: true,
      component: () => import('@/views/loanRestructure/Home.vue'),
    },
    {
      path: 'summary',
      name: get(layoutConfig, 'LOAN_RESTRUCTURE_LAYOUT.routes.summary', ''),
      props: true,
      component: () => import('@/views/loanRestructure/Summary.vue'),
    },
    {
      path: 'otp',
      name: get(
        layoutConfig,
        'LOAN_RESTRUCTURE_LAYOUT.routes.otpConfirmation',
        ''
      ),
      props: true,
      component: () => import('@/views/loanRestructure/EnterOtp.vue'),
    },
    {
      path: 'success',
      name: get(
        layoutConfig,
        'LOAN_RESTRUCTURE_LAYOUT.routes.restructureSuccessful',
        ''
      ),
      props: true,
      component: () =>
        import('@/views/loanRestructure/RestructureSuccessful.vue'),
    },
    {
      path: 'failure',
      name: get(
        layoutConfig,
        'LOAN_RESTRUCTURE_LAYOUT.routes.paymentFailure',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "loan-restructure-status" */ '@/views/loanRestructure/PaymentFailure.vue'
        ),
    },
    {
      path: 'pending',
      name: get(
        layoutConfig,
        'LOAN_RESTRUCTURE_LAYOUT.routes.paymentPending',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "loan-restructure-pending" */ '@/views/loanRestructure/PaymentPending.vue'
        ),
    },
    {
      path: 'terms',
      name: 'loanRestructureTerms',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "loan-restructure-term" */ '@/views/loanRestructure/Terms.vue'
        ),
    },
  ],
};
