export const creditLineStatuses: Record<string, string> = {
  CM_LINE_STARTED: 'CM_LINE_STARTED',
  AIP_INTERESTED: 'AIP_INTERESTED',
  AIP_NOT_INTERESTED: 'AIP_NOT_INTERESTED',
};

export const applicationStates: Record<string, string> = {
  AIP_SUBMITTED: '30000.10000',
  QUAL_REJECTED: '90000.10000',
  AIP_SUCCESS: '30000.50000',
  AIP_REJECTED: '90000.30000',
  AIP_INTERESTED: '31000.50000',
  AIP_NOT_INTERESTED: '90000.31000',
  AIP_NOHIT: '30000.10900',
  UNKNOWN_REJECTED: '90000.00000',
  ONBRD_REJECTED: '90000.40000', // aka Banker Rejected
  CM_LINE_APPROVED: '80000.10000',
  CM_LINE_ISSUED: '80000.20000',
  CM_LINE_STARTED: '80000.30000',
  CM_COARSE: '80000',
  REJECTED_COARSE: '90000',
  AIP_PERFIOS_REQD: '30000.20000',
  AIP_PERFIOS_SUBMITTED: '30000.21000',
  AIP_CUSTOMER_INTERACTION_REQ: '30000.11100',
  AIP_ADDRESS_REQD: '30000.15000',
  AIP_CUSTOMER_INTERACTION_OVER: '30000.11109',
  LINE_CLOSED: '90000.99000',
  PORTAL_REJECT: '90000.80000',
};

export const appMode: Record<string, string> = {
  QUALIFICATION: 'QUALIFICATION',
  POST_AIP: 'POST_AIP',
  CREDIT_MANAGEMENT: 'CREDIT_MANAGEMENT',
  PROFILE_SUBMISSION: 'PROFILE_SUBMISSION',
};

export const qualBlockMilestones: Record<string, string> = {
  QUAL_BLOCK_CITY: 'QUAL.BLOCK.CITY',
  QUAL_BLOCK_AADHAAR: 'QUAL.BLOCK.AADHAAR',
  QUAL_BLOCK_AD_SOURCE: 'QUAL.BLOCK.AD.SOURCE',
  QUAL_BLOCK_AGE: 'QUAL.BLOCK.AGE',
  QUAL_BLOCK_APP_VERSION: 'QUAL.BLOCK.APP.VERSION',
  QUAL_BLOCK_COMPANY_NAME: 'QUAL.BLOCK.COMPANY.NAME',
  QUAL_BLOCK_COMPANY_TYPE_NAME: 'QUAL.BLOCK.COMPANY.TYPE.NAME',
  QUAL_BLOCK_CREDIT_FINANCE_HISTORY: 'QUAL.BLOCK.CREDIT_FINANCE.HISTORY',
  QUAL_BLOCK_EMPLOYMENT: 'QUAL.BLOCK.EMPLOYMENT',
  QUAL_BLOCK_EMPLOYMENT_JOB_TYPE: 'QUAL.BLOCK.EMPLOYMENT.JOB_TYPE',
  QUAL_BLOCK_EMPLOYMENT_EMPLOYMENT_TYPE:
    'QUAL.BLOCK.EMPLOYMENT.EMPLOYMENT_TYPE',
  QUAL_BLOCK_EMPLOYMENT_YEARS: 'QUAL.BLOCK.EMPLOYMENT.YEARS',
  QUAL_BLOCK_INVITE_CODE: 'QUAL.BLOCK.INVITE.CODE',
  QUAL_BLOCK_OFFICE_EMAIL: 'QUAL.BLOCK.OFFICE.EMAIL',
  QUAL_BLOCK_PARTNER_REAPPLICATION: 'QUAL.BLOCK.PARTNER.REAPPLICATION',
  QUAL_BLOCK_PHONE: 'QUAL.BLOCK.PHONE',
  QUAL_BLOCK_PINCODE: 'QUAL.BLOCK.PINCODE',
  QUAL_BLOCK_SALARY: 'QUAL.BLOCK.SALARY',
  QUAL_BLOCK_SIGNUP_SOURCE: 'QUAL.BLOCK.SIGNUP.SOURCE',
};
