import { isEmpty } from '@/lib/helpers';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { Nullable } from '@/types';

export default class IncomeInfo {
  public static getInstance(): IncomeInfo {
    if (!IncomeInfo.instance || !isCustomerAuthenticatedSync(authState())) {
      IncomeInfo.instance = new IncomeInfo();
    }
    return IncomeInfo.instance;
  }

  private static instance: IncomeInfo | null;

  private _declared?: Nullable<string>;
  private _mode?: Nullable<string>;
  private _bankIfscPrefixes?: string[];

  private constructor() {
    this._declared = null;
    this._mode = null;
    this._bankIfscPrefixes = [];
  }

  public get declared(): Nullable<string> {
    return this._declared || null;
  }

  public set declared(declared: Nullable<string>) {
    this._declared = declared;
  }

  public get mode(): Nullable<string> {
    return this._mode || null;
  }

  public set mode(mode: Nullable<string>) {
    this._mode = mode;
  }

  public get bankIfscPrefixes(): string[] {
    return this._bankIfscPrefixes || [];
  }

  public set bankIfscPrefixes(bankIfscPrefixes: string[]) {
    this._bankIfscPrefixes = bankIfscPrefixes;
  }

  public setIncomeInfo(incomeInfo: IncomeInfo) {
    if (!isEmpty(incomeInfo)) {
      this._declared = incomeInfo.declared;
      this._mode = incomeInfo.mode;
      this._bankIfscPrefixes = incomeInfo.bankIfscPrefixes;
    }
  }

  public clearInstance() {
    IncomeInfo.instance = null;
  }
}
