import { hasOwnProperty } from '@/lib/helpers';
import Vue from 'vue';
declare const ga: any;

const gaEventBus = new Vue({
  created() {
    this.$on('processEvent', this.handleEvent);
  },
  beforeDestroy() {
    this.$off('processEvent');
  },
  methods: {
    handleEvent(eventName: string, eventAttributes: any) {
      // const email = eventAttributes.hasOwnProperty('email') ? eventAttributes.email : '';
      const phone = hasOwnProperty(eventAttributes, 'phone')
        ? eventAttributes.phone
        : '';
      const { nonInteraction = false, ...attributes } = eventAttributes;
      try {
        ga('send', 'event', {
          eventCategory: eventName,
          eventAction: phone,
          eventLabel: JSON.stringify(attributes),
          nonInteraction,
          transport: 'beacon',
        });
      } catch (err) {
        console.log('Error sending events to GA', err);
      }
    },
  },
});

export default gaEventBus;
