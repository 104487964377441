import bus from '@/lib/event_bus';
import { get, hasOwnProperty, isEmpty } from '@/lib/helpers';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { doCtxLogout } from '@/store/actions/auth';
import { authState } from '@/store/getters/auth';
import { getLocalKey } from '@/store/local_store';
import { ACCESS_DETAILS } from '@/store/store_keys';

export const responseHelper =
  (onSuccess: any, onFailure: any) => (response: any) => {
    const { data } = response;
    if (data && data.success) {
      onSuccess(data);
    } else if (response.ok && response.status === 200) {
      onSuccess(data);
    } else {
      const { errorMessage, errorCode } = data;
      onFailure(errorCode, errorMessage, data);
    }
    return data;
  };

export const defaultCatch = (err: any, notification?: any) => {
  if (err.status === 477) {
    const errorResponse = get(err, 'data.error', {});
    if (!isEmpty(errorResponse)) {
      const {
        message = `Something went wrong ${
          notification && notification.action
            ? `while ${notification.action}`
            : ''
        }`,
      } = errorResponse;
      bus.$emit('notify', {
        type: 'error',
        title: (notification && notification.title) || 'Error',
        message,
      });
    }
  }
  if (err.status === 401) {
    bus.$emit('notify', {
      type: 'warning',
      title: 'Error',
      message: 'You have been logged out. Login to continue',
    });
    doCtxLogout(false);
  }
  console.log('Error occured while processing request', err);
};

export const fetchHandler = (response: any) => {
  const defaultResp = {
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
  };
  if (response.ok) {
    return response
      .json()
      .then((data: any) => {
        // the status was ok and there is a json body
        return Promise.resolve({ data, rawResp: response, ...defaultResp });
      })
      .catch((err: any) => {
        // the status was ok but there is no json body
        return Promise.resolve({
          data: err,
          rawResp: response,
          ...defaultResp,
        });
      });
  } else {
    return response
      .json()
      .catch((err: any) => {
        // the status was not ok and there is no json body
        return Promise.resolve({
          rawResp: response,
          data: err,
          ...defaultResp,
        });
      })
      .then((json: any) => {
        // the status was not ok but there is a json body
        return Promise.resolve({
          rawResp: response,
          data: json,
          ...defaultResp,
        });
      });
  }
};

export const getHeaders = (defaultHeaders: object) => {
  const isAuthenticated = isCustomerAuthenticatedSync(authState());
  let headers: object = defaultHeaders;
  if (isAuthenticated) {
    const { access_token: accessToken } = authState();
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
      'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
    };
    return headers;
  }
  const accessDetails: any = getLocalKey(ACCESS_DETAILS);
  if (
    accessDetails &&
    hasOwnProperty(accessDetails, 'access_token') &&
    accessDetails.access_token
  ) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessDetails.accessToken}`,
      'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
    };
    return headers;
  }
  return headers;
};

export function getErrorMessageFromResponse(
  soFetchResponse: any,
  defaultErrorMessage: string
) {
  if (hasOwnProperty(soFetchResponse, 'data')) {
    const { data } = soFetchResponse;
    return get(data, 'error.message', defaultErrorMessage);
  }
}
