import { SelectionValue, Tenure } from '@/interfaces/app_config';

export const companyTypes: SelectionValue[] = [
  {
    text: 'Public Limited Company',
    value: 'PUBLIC_LIMITED',
  },
  {
    text: 'Multinational',
    value: 'MULTINATIONAL',
  },
  {
    text: 'Central/State Government',
    value: 'CENTRAL_STATE_GOVT',
  },
  {
    text: 'Private Limited',
    value: 'PRIVATE_LIMITED',
  },
  {
    text: 'Proprietorship',
    value: 'PROPRIETORSHIP',
  },
  {
    text: 'Individual',
    value: 'INDIVIDUAL',
  },
  {
    text: 'Hospital',
    value: 'HOSPITAL',
  },
  {
    text: 'College',
    value: 'COLLEGE',
  },
  {
    text: 'LLP',
    value: 'LLP',
  },
  {
    text: 'Partnership Firm',
    value: 'PARTNERSHIP_FIRM',
  },
  {
    text: 'Hindu Undivided Family',
    value: 'HUF',
  },
  {
    text: 'Other including Society/Trust/AOP',
    value: 'OTHER',
  },
];

export const employmentTypes: SelectionValue[] = [
  {
    text: 'Full-time',
    value: 'FULL_TIME',
  },
  {
    text: 'Part-Time',
    value: 'PART_TIME',
  },
  {
    text: 'Contract',
    value: 'CONTRACT',
  },
];

export const jobTypes: SelectionValue[] = [
  {
    text: 'Salaried',
    value: 'SALARIED',
  },
  {
    text: 'Self Employed',
    value: 'SELF_EMPLOYED',
  },
];

export const residenceTypes: SelectionValue[] = [
  {
    text: 'Owned by Self / Family / Spouse',
    value: 'OWNED',
  },
  {
    text: 'Rented',
    value: 'RENTED',
  },
];

export const greaterHouseHoldIncome: SelectionValue[] = [
  {
    text: 'Yes',
    value: true,
  },
  {
    text: 'No',
    value: false,
  },
];

export const tenure: Tenure[] = [
  {
    text: 'Less than 1 year',
    value: '11month',
    tenure: {
      years: 0,
      months: 11,
    },
    tenureInMonths: 11,
  },
  {
    text: '1 - 3 years',
    value: '2years11month',
    tenure: {
      years: 2,
      months: 11,
    },
    tenureInMonths: 35,
  },
  {
    text: 'More than 3 years',
    value: '3years1month',
    tenure: {
      years: 3,
      months: 1,
    },
    tenureInMonths: 37,
  },
];

export const invalidNameParts: string[] = [
  'mr',
  'mrs',
  'miss',
  'ji',
  'shri',
  'shree',
  'jee',
  'col',
  'sri',
  'dr',
  'prof',
  'sir',
];

export const genderMapping: SelectionValue[] = [
  {
    text: 'Male',
    value: 'MALE',
  },
  {
    text: 'Female',
    value: 'FEMALE',
  },
  {
    text: 'Other',
    value: 'TRANSGENDER',
  },
];

export const isSalariedSelection: SelectionValue[] = [
  {
    text: 'Yes I am',
    value: true,
  },
  {
    text: 'Others',
    value: false,
  },
];

export const isAbove25kSelection: SelectionValue[] = [
  {
    text: 'Yes',
    value: true,
  },
  {
    text: 'No',
    value: false,
  },
];
