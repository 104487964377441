import Duration from './duration';
import Address from './address';
import { Nullable } from '@/types';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { isEmpty } from '@/lib/helpers';

export default class Home {
  public static getInstance() {
    if (!Home.instance || !isCustomerAuthenticatedSync(authState())) {
      Home.instance = new Home();
    }
    return Home.instance;
  }

  private static instance: Home | null;
  private _currentCityDuration?: Duration;
  private _currentAddressDuration?: Duration;
  private _currentCityDurationInMonths?: Nullable<number | string>;
  private _currentAddressDurationInMonths?: Nullable<number | string>;
  private _address?: Address;
  private _residenceType?: Nullable<string>;

  private constructor() {
    this._currentAddressDurationInMonths = null;
    this._currentCityDurationInMonths = null;
    this._currentAddressDuration = new Duration(0, 0);
    this._currentCityDuration = new Duration(0, 0);
    this._address = new Address();
    this._residenceType = null;
  }

  public get currentCityDuration(): Duration {
    return this._currentCityDuration || new Duration(0, 0);
  }

  public set currentCityDuration(currentCityDuration: Duration) {
    this._currentCityDuration = currentCityDuration;
  }

  public get currentAddressDuration(): Duration {
    return this._currentAddressDuration || new Duration(0, 0);
  }

  public set currentAddressDuration(currentAddressDuration: Duration) {
    this._currentAddressDuration = currentAddressDuration;
  }

  public get address(): Address {
    return this._address || new Address();
  }

  public set address(address: Address) {
    this._address = address;
  }

  public get residenceType(): string {
    return this._residenceType || '';
  }

  public set residenceType(residenceType: string) {
    this._residenceType = residenceType;
  }

  public get currentAddressDurationInMonths(): string | number {
    return this._currentAddressDurationInMonths || 0;
  }

  public set currentAddressDurationInMonths(
    currentAddressDurationInMonths: number | string
  ) {
    this._currentAddressDurationInMonths = currentAddressDurationInMonths;
  }

  public get currentCityDurationInMonths(): number | string {
    return this._currentCityDurationInMonths || 0;
  }

  public set currentCityDurationInMonths(
    currentCityDurationInMonths: number | string
  ) {
    this._currentCityDurationInMonths = currentCityDurationInMonths;
  }

  public setHomeDetails(homeDetails: Home) {
    if (!isEmpty(homeDetails)) {
      this._currentAddressDurationInMonths =
        homeDetails.currentAddressDurationInMonths;
      this._currentAddressDuration = new Duration().setDuration(
        homeDetails.currentAddressDuration
      );
      this._currentCityDuration = new Duration().setDuration(
        homeDetails.currentCityDuration
      );
      this._address = new Address().setAddress(homeDetails.address);
      this._residenceType = homeDetails.residenceType;
    }
  }

  public clearInstance() {
    Home.instance = null;
  }
}
