import { Nullable } from '@/types';
export default class AadhaarCard {
  public static getInstance(): AadhaarCard {
    if (!AadhaarCard.instance) {
      AadhaarCard.instance = new AadhaarCard();
    }
    return AadhaarCard.instance;
  }

  private static instance: AadhaarCard | null;

  private _firstName?: Nullable<string>;
  private _middleName?: Nullable<string>;
  private _lastName?: Nullable<string>;
  private _gender?: Nullable<string>;
  private _dateOfBirth?: Nullable<string>;
  private _fatherOrSpouseName?: Nullable<string>;
  private _yob?: Nullable<number>;
  private _email?: Nullable<string>;
  private _otpVerified?: boolean;
  private _aadhaarNumber?: Nullable<string>;
  private _demoVerified?: boolean;
  private _rawXml?: Nullable<string>;
  private _status?: Nullable<string>;
  private _displayableAadhaar?: Nullable<string>;

  private constructor() {
    if (AadhaarCard.instance) {
      return AadhaarCard.getInstance();
    }
    this._firstName = null;
    this._middleName = null;
    this._lastName = null;
    this._gender = null;
    this._dateOfBirth = null;
    this._fatherOrSpouseName = null;
    this._yob = null;
    this._email = null;
    this._otpVerified = false;
    this._aadhaarNumber = null;
    this._demoVerified = false;
    this._rawXml = null;
    this._status = 'AVAILABLE';
    this._displayableAadhaar = null;
  }

  public get firstName(): Nullable<string> {
    return this._firstName || null;
  }

  public set firstName(firstName: Nullable<string>) {
    this._firstName = firstName;
  }

  public get middleName(): Nullable<string> {
    return this._middleName || null;
  }

  public set middleName(middleName: Nullable<string>) {
    this._middleName = middleName;
  }

  public get lastName(): Nullable<string> {
    return this._lastName || null;
  }

  public set lastName(lastName: Nullable<string>) {
    this._lastName = lastName;
  }

  public get gender(): Nullable<string> {
    return this._gender || null;
  }

  public set gender(gender: Nullable<string>) {
    this._gender = gender;
  }

  public get dateOfBirth(): Nullable<string> {
    return this._dateOfBirth || null;
  }

  public set dateOfBirth(dateOfBirth: Nullable<string>) {
    this._dateOfBirth = dateOfBirth;
  }

  public get fatherOrSpouseName(): Nullable<string> {
    return this._fatherOrSpouseName || null;
  }

  public set fatherOrSpouseName(fatherOrSpouseName: Nullable<string>) {
    this._fatherOrSpouseName = fatherOrSpouseName;
  }

  public get yob(): Nullable<number> {
    return this._yob || null;
  }

  public set yob(yob: Nullable<number>) {
    this._yob = yob;
  }

  public get email(): Nullable<string> {
    return this._email || null;
  }

  public set email(email: Nullable<string>) {
    this._email = email;
  }

  public get otpVerified(): boolean {
    return this._otpVerified || false;
  }

  public set otpVerified(otpVerified: boolean) {
    this._otpVerified = otpVerified;
  }

  public get aadhaarNumber(): Nullable<string> {
    return this._aadhaarNumber || null;
  }

  public set aadhaarNumber(aadhaarNumber: Nullable<string>) {
    this._aadhaarNumber = aadhaarNumber;
  }

  public get demoVerified(): boolean {
    return this._demoVerified || false;
  }

  public set demoVerified(demoVerified: boolean) {
    this._demoVerified = demoVerified;
  }

  public get rawXml(): Nullable<string> {
    return this._rawXml || null;
  }

  public set rawXml(rawXml: Nullable<string>) {
    this._rawXml = rawXml;
  }

  public get status(): Nullable<string> {
    return this._status || 'AVAILABLE';
  }

  public set status(status: Nullable<string>) {
    this._status = status;
  }

  public get displayableAadhaar(): Nullable<string> {
    return this._displayableAadhaar || null;
  }

  public set displayableAadhaar(displayableAadhaar: Nullable<string>) {
    this._displayableAadhaar = displayableAadhaar;
  }

  // TODO: Implement lock, unlock and save

  public setAadhaarDetails(aadhaarDetails: AadhaarCard) {
    if (aadhaarDetails) {
      this._firstName = aadhaarDetails.firstName;
      this._middleName = aadhaarDetails.middleName;
      this._lastName = aadhaarDetails.lastName;
      this._gender = aadhaarDetails.gender;
      this._dateOfBirth = aadhaarDetails.dateOfBirth;
      this._fatherOrSpouseName = aadhaarDetails.fatherOrSpouseName;
      this._yob = aadhaarDetails.yob;
      this._email = aadhaarDetails.email;
      this._otpVerified = aadhaarDetails.otpVerified;
      this._aadhaarNumber = aadhaarDetails.aadhaarNumber;
      this._demoVerified = aadhaarDetails.demoVerified;
      this._rawXml = aadhaarDetails.rawXml;
      this._status = aadhaarDetails.status;
      this._displayableAadhaar = aadhaarDetails.displayableAadhaar;
    }
  }

  public clearInstance() {
    AadhaarCard.instance = null;
  }
}
