import { isMicroappsEnabled, uuid } from '@/lib/utils';
import { jsonApiClient, unsecureApiClient } from './fetch_helper';
import { fetchHandler } from './utils';
interface SendOtpModel {
  phone: string | number;
  purpose: string;
  token: any;
}
/**
 * POST Posts data of customer for sign up
 * Mandatory { clientId, clientSecret, phone, otp}
 * @param {Object} data
 * @returns {Promise}
 */
export function signup(data: object): Promise<any> {
  return unsecureApiClient.fetch('/v1/customer/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  });
}

/**
 * POST Signs in customer (Access Token is mandatory)
 * @returns {Promise}
 */
export function signIn(): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/login', {
    method: 'POST',
  });
}

/**
 * POST Send OTP for a signup to customer's registered phone number
 * @param {Object} context
 * @param {String} context.phone
 * @param {String} context.purpose
 * @param {String} context.token
 * @returns {Promise}
 */
export function sendOtp({
  phone,
  purpose = 'SIGNUP',
  token,
}: SendOtpModel): Promise<any> {
  return unsecureApiClient.post('/v1/otp/send', { phone, purpose, token });
}

/**
 * POST Get access token for the corresponding id token from google
 * Used for all subsequent requests
 * @param {String} userName
 * @param {String} password
 * @param {String} authType
 * @param {Number} authVersion
 * @returns {Promise}
 */
export function getAccessToken(
  userName: string | number,
  password: string | number,
  authType = '',
  authVersion = 2
): Promise<any> {
  const formData = `grant_type=password&username=${userName}&scope=read+write&password=${password}&auth_type=${
    authType || 'GMAIL'
  }&auth_version=${authVersion}`;
  let authHeader = '';
  if (isMicroappsEnabled()) {
    authHeader = btoa(
      `${process.env.VUE_APP_GPAY_APP_ID}:${process.env.VUE_APP_GPAY_APP_KEY}`
    );
  } else {
    authHeader = btoa(`${process.env.VUE_APP_ID}:${process.env.VUE_APP_KEY}`);
  }
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/oauth/token`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${authHeader}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
      'mt-request-id': uuid(),
    },
    body: formData,
  }).then(fetchHandler);
}

/**
 * POST Get access token for refresh token
 * Used for all subsequent requests
 * @param {String} refreshToken
 * @returns {Promise}
 */
export function updateAccessToken(refreshToken: string): Promise<any> {
  const formData = `grant_type=refresh_token&refresh_token=${refreshToken}&scope=read+write&auth_type=GMAIL`;
  let authHeader = '';
  if (isMicroappsEnabled()) {
    authHeader = btoa(
      `${process.env.VUE_APP_GPAY_APP_ID}:${process.env.VUE_APP_GPAY_APP_KEY}`
    );
  } else {
    authHeader = btoa(`${process.env.VUE_APP_ID}:${process.env.VUE_APP_KEY}`);
  }
  return fetch(`${process.env.VUE_APP_API_BASE_URL}/oauth/token`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${authHeader}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
      'mt-request-id': uuid(),
    },
    body: formData,
  }).then(fetchHandler);
}

/**
 * POST Logout logged in customer
 * @returns {Promise}
 */
export function doLogout(): Promise<any> {
  return jsonApiClient.fetch('/logout', {
    method: 'POST',
  });
}
