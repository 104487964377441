import BankDetail from '@/business/models/bank_detail';
import { EnachProvider } from '@/interfaces';
import { CustomerConsent } from '@/store/actions/auth';
import { get } from '@/lib/helpers';
import { isCustomerAuthenticated } from '@/lib/login_utils';
import { uuid } from '@/lib/utils';
import { authState } from '@/store/getters/auth';
import cloneDeep from 'lodash.clonedeep';
import { jsonApiClient, unsecureApiClient } from './fetch_helper';
import { fetchHandler, getHeaders } from './utils';

const jsonHeaders: { [key: string]: string } = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
};

/**
 * POST Sync with evently (Used for analytics)
 * @param {any} postData
 * @returns {Promise}
 */
export function syncEvently(postData: any): Promise<any> {
  return unsecureApiClient.fetch('/evently/sink', {
    method: 'POST',
    body: JSON.stringify(postData),
  });
}

/**
 * POST Validate invite code
 * @param {any} postData
 */
export function validateInviteCode(postData: any): Promise<any> {
  return unsecureApiClient.fetch('/v1/customer/inviteCode/validation', {
    method: 'POST',
    body: JSON.stringify(postData),
  });
}

/**
 * POST Save invite code
 * @param {string} customerId
 * @param {string} inviteCode
 */
export function saveInviteCode(
  customerId: string,
  inviteCode: string
): Promise<any> {
  return jsonApiClient.fetch(`/v1/customer/${customerId}/saveInviteCode`, {
    method: 'POST',
    body: JSON.stringify({ inviteCode }),
  });
}

/**
 * GET Get moneytap token
 * @param {String} url
 * @returns {Promise}
 */
export function getMtToken(url: string): Promise<any> {
  return jsonApiClient.fetch(url);
}

/**
 * GET Get customer details for a given customer id
 * @returns {Promise}
 */
export function getCustomerData(): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/customerData');
}

/**
 * POST Save the customer profile data
 * @param {Object} profile
 * @param {String} customerId
 * @returns {Promise}
 */
export function buildProfile(profile: any, customerId: string): Promise<any> {
  const customerData: any = cloneDeep(profile);
  customerData.customerExternalId = customerId;
  return jsonApiClient.fetch('/v2/customer/buildprofile', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      ...jsonHeaders,
      'mt-request-id': uuid(),
    },
    body: JSON.stringify(customerData),
  });
}

/**
 * Build profile version 2
 * @param {Object} profile
 * @returns {Promise}
 */
export function buildProfileV2(profile: any): Promise<any> {
  return jsonApiClient.fetch('/customer/profile', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      ...jsonHeaders,
      'mt-request-id': uuid(),
    },
    body: JSON.stringify(profile),
  });
}

/**
 * POST Update customer beneficiary details
 * @param {Object} payload
 * @returns {Promise}
 */
export function updateBeneficiary(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/beneficiary', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      ...jsonHeaders,
      'mt-request-id': uuid(),
    },
    body: JSON.stringify(payload),
  });
}

/**
 * POST Submit application after perfios validation
 * @param {String} customerId
 * @returns {Promise}
 */
export function submitApplication(customerId: string): Promise<any> {
  return jsonApiClient.fetch(
    '/v1/customer/creditline/application/submit/perfios',
    {
      method: 'POST',
      headers: {
        ...jsonHeaders,
        'mt-request-id': uuid(),
      },
      body: JSON.stringify({
        customerId,
        tuVersion: 3,
      }),
    }
  );
}

/**
 * GET Get application status when perfios validation is required
 * @param {String} customerId
 * @returns {Promise}
 */
export function getApplicationStatus(customerId: string): Promise<any> {
  return jsonApiClient.fetch(`/v1/customer/${customerId}/loanapply/`);
}

/**
 * POST Preapproval status from TU version 3
 * @param {any} payload
 * @returns {Promise}
 */
export function submitPreApproval(payload: any): Promise<any> {
  return jsonApiClient.post(
    '/v1/customer/creditline/application/submit',
    { ...payload },
    {
      credentials: 'same-origin',
    }
  );
}

/**
 * GET Get preapproval status of application
 * @param {String} customerId
 * @returns {Promise} returns loanResponseDetails
 */
export function getPreApprovalStatus(customerId: string): Promise<any> {
  return jsonApiClient.fetch(`/v1/customer/${customerId}/loanapply/pre`);
}

/**
 * POST Get perfios encrypted payload
 * @param {any} payload
 * @returns {Promise}
 */
export function getPerfiosRequestPayload(payload: any): Promise<any> {
  return jsonApiClient.post(`/v2/customer/netbanking/payload`, payload);
}

/**
 * POST Get status of transaction for a given transaction id
 * @param {any} payload
 * @param {String} customerId
 * @returns {Promise}
 */
export function getPerfiosTxnStatus(
  payload: any,
  customerId: string
): Promise<any> {
  return jsonApiClient.post('/v2/customer/netbanking/txnStatus', {
    customerId,
    transactionId:
      get(payload, 'txnId', '') ||
      get(payload, 'perfiosProcessPayload.transactionId', ''),
  });
}

/**
 * POST Get AA payload
 * @param {any} payload
 * @returns {Promise}
 */
export function getAARequestPayload(payload: any): Promise<any> {
  return jsonApiClient.post('/v2/accountaggregator/create-consent', payload);
}

/**
 * POST Get status of transaction for a given transaction id
 * @param {any} payload
 * @returns {Promise}
 */
export function getAATxnStatus(payload: any): Promise<any> {
  return jsonApiClient.post('/v2/accountaggregator/getStatus', payload);
}

/**
 * GET Get financial details for a given customer
 * @param {String} customerId
 * @returns {Promise}
 */
export function getFinanceDetails(customerId: string): Promise<any> {
  return jsonApiClient.fetch(`/v1/customer/${customerId}/netbanking/`);
}

/**
 * POST Submit selected bank account details for a given customer
 * @param {String} customerId
 * @param {BankDetail} bankAccount
 * @returns {Promise}
 */
export function submitSelectedBankAccount(
  customerId: string,
  bankAccount: BankDetail
): Promise<any> {
  return jsonApiClient.post(
    `/v1/customer/${customerId}/netbanking/salaryaccount`,
    bankAccount
  );
}

/**
 * POST Send salary upload link for a customer
 * @param {String} customerId
 * @returns {Promise}
 */
export function sendUploadLink(customerId: string): Promise<any> {
  return jsonApiClient.post(`/v1/salupload/${customerId}/senduploadlink`, {});
}

/**
 * GET Get all company names
 * @returns {Promise}
 */
export function getCompanyNames(): Promise<any> {
  return jsonApiClient.fetch('/v1/companylist/rbl');
}

/**
 * PUT Update the path info for a given customer
 * @param {String} customerId
 * @param {Object} pathinfo
 * @returns {Promise}
 */
export function updatePathInfo(
  customerId: string,
  pathinfo: any
): Promise<any> {
  return jsonApiClient.put(`/v1/customer/${customerId}/pathInfo`, {
    pathInfo: JSON.stringify(pathinfo),
  });
}

/**
 * GET Get path info for a given customer
 * @param {String} customerId
 * @returns {Promise}
 */
export function getPathInfo(customerId: string): Promise<any> {
  return jsonApiClient.fetch(`/v1/customer/${customerId}/pathInfo`);
}

/**
 * GET Get EMI calculation data for a customer
 * @returns {Promise}
 */
export function getEmiCalculationData(): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/creditline/emiCalculationData');
}

/**
 * POST Send otp for a specific purpose
 * @param {any} payload
 * @returns {Promise}
 */
export function sendOtp(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/otp/send', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Verify OTP
 * @param {any} payload
 * @returns {Promise}
 */
export function verifyOtp(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/otp/verify', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST to get esign DOC
 * @param {Object} postData
 * @returns {Promise}
 */
export async function getEsignDoc(): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/doc/esign', {
    method: 'POST',
    credentials: 'same-origin',
  });
}

/**
 * POST Initiate dmi ekyc
 * @param {Object} payload
 * @returns {Promise}
 */
export function dmiEkyc(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/aadhaar/txn', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST to get ekyc DMI response
 * @param {String} txnId
 * @param {Object} postData
 * @returns {Promise}
 */
export function dmiEkycCallbackResp(payload: any, txnId: string): Promise<any> {
  return jsonApiClient.fetch(`/callback/aadhaar/EKYC/${txnId}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST To get esign document
 * @param {Object} postData
 * @returns {Promise}
 */
export function signTnc(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/doc/tnc', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Update user interest Status
 * @param {Object} postData
 * @returns {Promise}
 */
export function postInterestedStatus(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/creditline/status', {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      ...jsonHeaders,
      'mt-request-id': uuid(),
    },
    credentials: 'same-origin',
  });
}

/**
 * POST Initiate E-mandate
 * @param {EnachProvider} payload
 * @returns {Promise}
 */
export function initiateEmandate(payload: EnachProvider): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/repayment/init', {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      ...jsonHeaders,
      'mt-request-id': uuid(),
    },
    credentials: 'same-origin',
  });
}

/**
 * POST Handle Emandate success
 * @param {any} payload
 * @returns {Promise}
 */
export function intimateEmandateSuccess(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/repayment/handle', {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      ...jsonHeaders,
      'mt-request-id': uuid(),
    },
    credentials: 'same-origin',
  });
}

export async function uploadFile(
  payload: any,
  customerId: string,
  source: string = 'APPUSER'
): Promise<any> {
  const headers: any = getHeaders({
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
    'mt-request-id': uuid(),
    'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
  });
  const response: any = await fetch(
    `${process.env.VUE_APP_API_BASE_URL}/v1/customer/${customerId}/upload/files?source=${source}`,
    {
      method: 'POST',
      body: payload,
      headers,
    }
  );
  return fetchHandler(response);
}

export async function uploadAvatar(
  payload: any,
  customerId: string
): Promise<any> {
  const headers: any = getHeaders({
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
    'mt-request-id': uuid(),
    'app-platform': 'ooneipiDei0Tequ0Thaepheech8ietoh',
  });
  const response: any = await fetch(
    `${process.env.VUE_APP_API_BASE_URL}/v1/customer/${customerId}/file/avatar`,
    {
      method: 'POST',
      body: payload,
      headers,
    }
  );
  return fetchHandler(response);
}

/**
 * @deprecated
 * GET Get customer profile picture of customer
 * @param {String} customerId
 * @returns {Promise}
 */
export async function getCustomerProfilePicture(
  imageUrl: string
): Promise<any> {
  let headers: any;
  const isAuthenticated = await isCustomerAuthenticated(authState());
  if (isAuthenticated) {
    const { access_token: accessToken } = authState();
    headers = {
      Authorization: `Bearer ${accessToken}`,
      'Cache-Control': 'no-cache, no-store',
      Pragma: 'no-cache',
    };
    return fetch(imageUrl, {
      method: 'GET',
      headers,
    });
  }
  return new Promise((resolve, reject) => {
    reject();
  });
}

/**
 * POST Get Esign Status data for a customer
 * @param {Object} payload
 * @returns {Promise}
 */
export async function getAadhaarEsignStatus(payload: any): Promise<any> {
  return await jsonApiClient.fetch(`/v1/aadhaar/status/`, {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST get aadhar info for a customer
 * @param {Object} payload
 * @returns {Promise}
 */
export async function getCustomerAadhaarInfo(payload: any): Promise<any> {
  return await jsonApiClient.fetch(`/v1/aadhaar/info/`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * POST save aadhar info for a customer
 * @param {Object} payload
 * @returns {Promise}
 */
export async function saveAadharRblEsignAdditionalInfo(
  payload: any
): Promise<any> {
  return await jsonApiClient.fetch(`/v1/aadhaar/additionalinfo/`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

/**
 * GET details of application status
 * @param {String} customerId
 * @returns {Promise}
 */
export async function getApplicationStatusTrackerDetails(
  customerId: string
): Promise<any> {
  return await jsonApiClient.fetch(
    `/v1/customer/${customerId}/application/status`
  );
}

/**
 * POST Initiate reapplication process
 * @param payload
 * @returns {Promise}
 */
export function inititateReApplication(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/reapply', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}
/**
 * POST Save gpay order
 * @param {Record<string, string>} payload
 * @returns {Promise}
 */
export function submitGpayOrderDetails(payload: Record<string, string>) {
  return jsonApiClient.fetch('/v1/googlepay/order', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}
/**
 * GET Get an existing google pay order if present
 * @returns {Promise}
 */
export function getExistingGooglePayOrder(): Promise<any> {
  return jsonApiClient.fetch('/v1/googlepay/order/exists');
}

/**
 * POST Update email id from google pay
 * @param emailId
 * @returns {Promise}
 */
export function updatePersonalEmail(emailId: string): Promise<any> {
  return jsonApiClient.fetch('/v1/googlepay/updateProfile', {
    method: 'POST',
    body: JSON.stringify({ emailId }),
    credentials: 'same-origin',
  });
}

/**
 * GET Get gpay order details
 * @returns {Promise}
 */
export function getGpayOrderDetails(): Promise<any> {
  return jsonApiClient.fetch('/v1/googlepay/order');
}

/**
 * GET Get moneytap token
 * @param {String} url
 * @returns {Promise}
 */
export function getNewGpayPublicKeys(): Promise<any> {
  return fetch(
    `https://www.googleapis.com/service_accounts/v1/metadata/x509/microapps-signingservice@system.gserviceaccount.com`
  ).then((response: any) => response.json());
}

/**
 * GET Check if UIDAI service is running
 */
export async function checkAadhaarService() {
  try {
    const response: any = await fetch(
      `${process.env.VUE_APP_OKYC_ENDPOINT}/assisted/isUidaiUp`,
      {
        method: 'POST',
        mode: 'no-cors',
      }
    );
    console.log(response);
  } catch (error) {
    console.log(error);
  }
}

/**
 * GET Get info to start okyc
 */
export function initiateAadhaarOkyc() {
  return jsonApiClient.fetch('/v1/okyc/init');
}

/**
 * POST Process okyc response
 * @param payload
 */
export function processOkycResponse(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/okyc/process', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}
/**
 * POST Submit credit eligibility answers
 * @param payload
 * @returns {Promise}
 */
export function submitCreditEligibilityAnswers(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/credit/verify/answers', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Initiate IMPS transaction to get reference number
 * @param payload
 * @returns {Promise}
 */
export function initiateImpsTransaction(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/txn/genrefno', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Request for IMPS withdrawal
 * @param payload
 * @returns {Promise}
 */
export function requestImpsWithdrawal(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/txn/withdraw', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * GET Get info to initiate payment for loan restructuring
 * @param amount
 * @returns {Promise}
 */
export function initiateRestructuringPayment(amount: string): Promise<any> {
  return jsonApiClient.fetch(
    `/v1/customer/initiatePayment/restructure?amount=${amount}`
  );
}

/**
 * GET Get loan restructuring information
 * @returns {Promise}
 */
export function getLoanRestructingDetails(): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/loan/restructure/estimate');
}

/**
 * POST Send back loan restructuring preference to save in db
 * @param payload
 * @returns {Promise}
 */
export function saveLoanRestructuringDetails(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/loan/restructure/create', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Send payment response
 * @param payload
 * @returns {Promise}
 */
export function handleRestructuringPaymentResponse(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/restructure/payment/handle', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * GET Get payment status of current order for a customer
 * @returns {Promise}
 */
export function getGooglePayPaymentStatus(): Promise<any> {
  return jsonApiClient.fetch('/v1/googlepay/paymentStatus');
}

/**
 * POST Get payment details for repayment
 * @param payload
 * @returns {Promise}
 */
export function getPaymentDetails(payload: Record<string, any>): Promise<any> {
  return jsonApiClient.fetch('/v1/googlepay/post/initiatePayment', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}
/**
 * POST Initiate hyperverge okyc
 * @param payload
 * @returns {Promise}
 */
export function initiateHypervergeOkyc(
  payload: Record<string, string>
): Promise<any> {
  return jsonApiClient.fetch('/kyc/v2/okyc/initiate', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Verify captcha and send otp
 * @param payload
 * @returns {Promise}
 */
export function verifyCaptchaAndSendOtp(
  payload: Record<string, string>
): Promise<any> {
  return jsonApiClient.fetch('/kyc/v2/okyc/get-otp', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Verify otp and get aadhaar details
 * @param payload
 * @returns {Promise}
 */
export function verifyOtpAndGetAadhaarDetails(
  payload: Record<string, string>
): Promise<any> {
  return jsonApiClient.fetch('/kyc/v2/okyc/get-aadhaar-details', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Capture customer consent
 * @param payload
 * @returns {Promise}
 */
export function captureCustomerConsent(payload: CustomerConsent): Promise<any> {
  return jsonApiClient.fetch('/customer/consent', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Re route application
 * @returns {Promise}
 */
export function reRouteApplication(
  payload: Record<string, string>
): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/reroute', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Capture loan restructuring feedback
 * @param payload
 * @returns {Promise}
 */
export function captureLoanRestructureFeedback(
  payload: Record<string, string>
): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/loan/restructure/feedback', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Submit esign document
 * @returns {Promise}
 */
export function submitEsignDocument(): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/action/esign', {
    method: 'POST',
    credentials: 'same-origin',
  });
}

/**
 * POST PF Initiate API
 * @param payload
 * @returns {Promise}
 */
export function initiatePfApi(payload: Record<string, string>): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/pf/initiate', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * GET Get city and state from pincode
 * @param pincode
 * @returns {Promise}
 */
export function lookupPincode(pincode: string): Promise<any> {
  if (pincode && pincode.length === 6) {
    return jsonApiClient.fetch(`/lookup/pincode/${pincode}`);
  }
  return Promise.reject('Pincode cannot be empty');
}

/**
 * POST PF OTP verification
 * @param payload
 * @returns {Promise}
 */
export function pfOtpVerification(
  payload: Record<string, string>
): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/pf/otpVerify', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST Confirm PF id
 * @param payload
 * @returns {Promise}
 */
export function confirmId(payload: string): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/pf/idConfirm', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * GET Get appointment slots for pickup purpose
 * @returns {Promise}
 */
export function getAppointmentSlots(
  purpose: string,
  mode: string
): Promise<any> {
  return jsonApiClient.fetch(
    `/v2/appointment/slots?purpose=${purpose}&mode=${mode}`
  );
}

/**
 * POST Schedule appointment for purpose specified
 * @param payload
 * @returns {Promise}
 */
export function scheduleAppointment(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v2/appointment/schedule', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * POST
 * @param payload
 * @returns {Promise}
 */

export function getKfsPdf(payload: any): Promise<any> {
  return jsonApiClient.post(
    '/v1/customer/document/details',
    {
      ...payload,
    },
    {
      credentials: 'same-origin',
    }
  );
}

/**
 * POST Evaluate loan details
 * @param payload
 * @returns {Promise}
 */

export function evaluateLoan(payload: any): Promise<any> {
  return jsonApiClient.post(
    '/v1/customer/loan/evaluate',
    {
      ...payload,
    },
    {
      credentials: 'same-origin',
    }
  );
}

/**
 * POST Update email
 * @param payload
 * @returns {Promise}
 */
export function updateEmail(payload: any): Promise<any> {
  return jsonApiClient.post(
    '/customer/update_email',
    {
      ...payload,
    },
    {
      credentials: 'same-origin',
    }
  );
}

/**
 * POST Update phone number
 * @param payload
 * @returns {Promise}
 */
export function updateMobile(payload: any): Promise<any> {
  return jsonApiClient.post(
    '/customer/update_mobile',
    {
      ...payload,
    },
    {
      credentials: 'same-origin',
    }
  );
}

/**
 * GET Get hyperverge access token
 * @returns {Promise}
 */
export async function getHypervergeToken(): Promise<any> {
  return jsonApiClient.fetch('/kyc/v1/akyc/auth');
}

/**
 * GET Get creditLine Info
 * @returns {Promise}
 */
export function getCreditLineInfo(): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/creditline/info');
}

/**
 * POST Request for Loan Details
 * @param payload
 * @returns {Promise}
 */
export function requestLoanDetails(payload: any): Promise<any> {
  return jsonApiClient.fetch('/v2/customer/loan/info', {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'same-origin',
  });
}

/**
 * GET Get kyc metadata
 * @returns {Promise}
 */
export function getKycMetadata(): Promise<any> {
  return jsonApiClient.fetch('/v1/customer/unified_kyc/metadata');
}

/**
 * POST Get kyc details for digi kyc
 * @returns {Promise}
 */
export function initiateDigiKyc(payload: any): Promise<any> {
  return jsonApiClient.post(
    '/kyc/v1/dkyc/initiate',
    {
      ...payload,
    },
    {
      credentials: 'same-origin',
    }
  );
}

/**
 * POST Get digi KYC status
 * @returns {Promise}
 */
export function getDigiKycStatus(payload: any): Promise<any> {
  return jsonApiClient.post(
    '/kyc/v1/dkyc/get-status',
    {
      ...payload,
    },
    {
      credentials: 'same-origin',
    }
  );
}

/**
 * POST Capture timeline event
 * @param payload
 * @returns {Promise}
 */
export function captureTimelineEvent(payload: any): Promise<any> {
  return jsonApiClient.fetch('/customer/timeline/event', {
    method: 'POST',
    body: JSON.stringify({
      event: payload.event,
      eventOrg: 'MONEYTAP',
      category: payload.category,
      appName: 'moneytap',
      appProduct: 'moneytap',
      module: 'authentication',
      clientPlatform: 'web',
      eventId: uuid(),
      metadata: {
        ...payload.utmParameters,
      },
    }),
    credentials: 'same-origin',
  });
}

/**
 * GET Get pending step offers
 * @param {String} customerId
 * @returns {Promise}
 */
export function getPendingStepIncentivisationOffers(
  customerId: string
): Promise<any> {
  return jsonApiClient.fetch(
    `/v1/customer/offer/eligibleOffers?customerId=${customerId}`
  );
}

export function updatePendingStepIncentivisationOffers(
  payload: any
): Promise<any> {
  return jsonApiClient.post(
    '/v1/customer/offer/updateOfferStatus',
    { ...payload },
    { credentials: 'same-origin' }
  );
}

export function getFreoConnectOffers(): Promise<any> {
  return jsonApiClient.post('/v1/customer/connect/options', {
    accounts: ['connect'],
  });
}
