import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { Nullable } from '@/types';

export default class ApplicationModel {
  public static getInstance(): ApplicationModel {
    if (
      !ApplicationModel.instance ||
      !isCustomerAuthenticatedSync(authState())
    ) {
      ApplicationModel.instance = new ApplicationModel();
    }
    return ApplicationModel.instance;
  }
  private static instance: ApplicationModel | null;
  private _hasAadhaarLinkedMobile?: Nullable<string>;
  private _isCurrentAddressAadhaarLinkedAddress?: Nullable<string>;
  private _aadhaarUsageConsentDate?: Nullable<string>;
  private _partnerTncAgreedOn?: Nullable<string>;
  private _preferredLineType?: Nullable<string>;

  private constructor() {
    this._hasAadhaarLinkedMobile = null;
    this._isCurrentAddressAadhaarLinkedAddress = null;
    this._aadhaarUsageConsentDate = null;
    this._partnerTncAgreedOn = null;
    this._preferredLineType = null;
  }

  public get hasAadhaarLinkedMobile(): Nullable<string> {
    return this._hasAadhaarLinkedMobile || null;
  }

  public set hasAadhaarLinkedMobile(hasAadhaarLinkedMobile: Nullable<string>) {
    this._hasAadhaarLinkedMobile = hasAadhaarLinkedMobile;
  }

  public get isCurrentAddressAadhaarLinkedAddress(): Nullable<string> {
    return this._isCurrentAddressAadhaarLinkedAddress || null;
  }

  public set isCurrentAddressAadhaarLinkedAddress(
    isCurrentAddressAadhaarLinkedAddress: Nullable<string>
  ) {
    this._isCurrentAddressAadhaarLinkedAddress =
      isCurrentAddressAadhaarLinkedAddress;
  }

  public get aadhaarUsageConsentDate(): Nullable<string> {
    return this._aadhaarUsageConsentDate || null;
  }

  public set aadhaarUsageConsentDate(
    aadhaarUsageConsentDate: Nullable<string>
  ) {
    this._aadhaarUsageConsentDate = aadhaarUsageConsentDate;
  }

  public get partnerTncAgreedOn(): Nullable<string> {
    return this._partnerTncAgreedOn || null;
  }

  public set partnerTncAgreedOn(partnerTncAgreedOn: Nullable<string>) {
    this._partnerTncAgreedOn = partnerTncAgreedOn;
  }

  public get preferredLineType(): Nullable<string> {
    return this._preferredLineType || null;
  }

  public set preferredLineType(preferredLineType: Nullable<string>) {
    this._preferredLineType = preferredLineType;
  }

  public setApplicationModel(applicationModel: ApplicationModel) {
    if (applicationModel) {
      this._hasAadhaarLinkedMobile = applicationModel.hasAadhaarLinkedMobile;
      this._isCurrentAddressAadhaarLinkedAddress =
        applicationModel.isCurrentAddressAadhaarLinkedAddress;
      this._aadhaarUsageConsentDate = applicationModel.aadhaarUsageConsentDate;
      this._partnerTncAgreedOn = applicationModel.partnerTncAgreedOn;
      this._preferredLineType = applicationModel.preferredLineType;
    }
  }

  public clearInstance() {
    ApplicationModel.instance = null;
  }
}
