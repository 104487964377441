export const ACCESS_DETAILS: string = 'ACCESS_DETAILS';
export const CUSTOMER_BASIC_PROFILE: string = 'CUSTOMER_BASIC_PROFILE';
export const LINK_ID: string = 'LINK_ID';
export const CURRENT_ACTION_ID: string = 'CURRENT_ACTION_ID';
export const MAGIC_LINK_ACCESS_DETAILS: string = 'MAGIC_LINK_ACCESS_DETAILS';
export const MT_PROFILE: string = 'MT_PROFILE';
export const PREFERRED_LINE_TYPE: string = 'PREFERRED_LINE_TYPE';
export const DEVICE_SOURCE: string = 'DEVICE_SOURCE';
export const LAST_BIG_COOKIE_CALL: string = 'LAST_BIG_COOKIE_CALL';
export const KYC_PROVIDER: string = 'KYC_PROVIDER';
export const MAGIC_LINK_DATA: string = 'MAGIC_LINK_DATA';
export const FINANCE_REPORT_META_DATA: string = 'FINANCE_REPORT_META_DATA';
export const SESSION_ID: string = 'SESSION_ID';
export const QUAL_FLOW_TYPE: string = 'QUAL_FLOW_TYPE';
export const INITIAL_RBL = 'INITIAL_RBL';
export const CUSTOMER_DETAILS: string = 'CUSTOMER_DETAILS';
export const RAW_CUSTOMER_PROFILE: string = 'RAW_CUSTOMER_PROFILE';
