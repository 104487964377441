import BigCookie from '@/business/models/big_cookie';
import { hasOwnProperty } from '@/lib/helpers';
import { getLocalKey } from '@/store/local_store';
import { SESSION_ID } from '@/store/store_keys';
import Vue from 'vue';
declare const clarity: any;

const clarityEventBus = new Vue({
  created() {
    this.$on('processEvent', this.handleEvent);
  },
  beforeDestroy() {
    this.$off('processEvent');
  },
  methods: {
    handleEvent(eventName: string, eventAttributes: any) {
      const phone = hasOwnProperty(eventAttributes, 'phone')
        ? eventAttributes.phone
        : '';
      const customerId = BigCookie.getInstance().customerId;
      const sessionId = getLocalKey(SESSION_ID);
      const { nonInteraction = false, ...attributes } = eventAttributes;
      try {
        if (nonInteraction) {
          clarity(
            'identify',
            attributes.customerId || customerId || '',
            sessionId || '',
            eventName,
            phone
          );
          clarity('set', 'EVENT', eventName);
          clarity('set', eventName, JSON.stringify(attributes));
        } else if (attributes.pageView) {
          clarity(
            'identify',
            customerId || '',
            sessionId || '',
            attributes.page || '',
            phone || ''
          );
        }
      } catch (err) {
        console.log('Error sending events to GA', err);
      }
    },
  },
});

export default clarityEventBus;
