import CreditLineInfo from '../models/credit_line_info';
import BigCookie from '../models/big_cookie';
import { isEmpty, isNil } from '@/lib/helpers';
import ChannelPartner from '../models/channel_partner';
import CreditBalanceTerm from '../models/credit_balance_term';

export default class CreditLineManager {
  public static getInstance(): CreditLineManager {
    if (!CreditLineManager.instance) {
      CreditLineManager.instance = new CreditLineManager();
    }
    return CreditLineManager.instance;
  }
  private static instance: CreditLineManager;

  public readonly MODE: Record<string, string> = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
  };

  public readonly LINE_TYPE: Record<string, string> = {
    CASH_LINE: 'CASH_LINE',
    FAST_CASH: 'FASH_CASH',
    CONSUMER_FINANCE: 'CONSUMER_FINANCE',
  };

  public readonly CHANNEL: Record<string, string> = {
    IMPS: 'IMPS',
    GIFT_CARD: 'QC_GIFT_VOUCHER',
    CHECKOUT: 'CHECKOUT',
  };

  public readonly ACTIVE_STATUS: Record<string, string> = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  };

  public readonly STATUS_CODE: Record<string, string> = {
    AIP_SUCCESS: 'AIP_SUCCESS',
    AIP_INTERESTED: 'AIP_INTERESTED',
    AIP_SOFT_NI_1: 'AIP_SOFT_NI_1',
    AIP_SOFT_NI_2: 'AIP_SOFT_NI_2',
  };

  private constructor() {}

  public isSingleApprovedLine(): boolean {
    return this.getCreditLines().length === 1;
  }

  public isMultipleApprovedLines(): boolean {
    return this.getCreditLines().length > 1;
  }

  public isCashLine(): boolean {
    return (
      !isEmpty(this.getActiveCreditLine()) &&
      this.LINE_TYPE.CASH_LINE === this.getActiveCreditLine().lineType
    );
  }

  public isConsumerFinanceLine(): boolean {
    return (
      !isEmpty(this.getActiveCreditLine()) &&
      this.LINE_TYPE.CONSUMER_FINANCE === this.getActiveCreditLine().lineType
    );
  }

  public getActiveCreditLine(): CreditLineInfo {
    let activeLine: any = null;
    this.getCreditLines().forEach((creditLine: CreditLineInfo) => {
      if (isNil(activeLine)) {
        activeLine = creditLine;
      } else if (creditLine.priority < activeLine.priority) {
        activeLine = creditLine;
      }
    });
    return activeLine;
  }

  public getActiveChannel(channel: string = 'IMPS') {
    for (const creditLineInfo of this.getCreditLines()) {
      for (const channelPartner of creditLineInfo.channelPartners) {
        if (
          this.ACTIVE_STATUS.ACTIVE === channelPartner.activeStatus &&
          channelPartner.channel === channel
        ) {
          return channelPartner;
        }
      }
    }
    return null;
  }

  public isOnline(channelCode: string) {
    for (const creditLineInfo of this.getCreditLines()) {
      for (const channelPartner of creditLineInfo.channelPartners) {
        if (
          channelCode === channelPartner.channelPartner &&
          this.MODE.ONLINE === channelPartner.mode
        ) {
          return true;
        }
      }
    }
    return false;
  }

  public isOffline(channelCode: string) {
    for (const creditLineInfo of this.getCreditLines()) {
      for (const channelPartner of creditLineInfo.channelPartners) {
        if (
          channelCode === channelPartner.channelPartner &&
          this.MODE.OFFLINE === channelPartner.mode
        ) {
          return true;
        }
      }
    }
    return false;
  }

  public getOnlineChannelPartners() {
    const onlinePartners: ChannelPartner[] = [];
    for (const channelPartner of this.getActiveCreditLine().channelPartners) {
      if (this.MODE.ONLINE === channelPartner.mode) {
        onlinePartners.push(channelPartner);
      }
    }
    return onlinePartners;
  }

  public getOfflineChannelPartners() {
    const offlinePartners: ChannelPartner[] = [];
    for (const channelPartner of this.getActiveCreditLine().channelPartners) {
      if (this.MODE.OFFLINE === channelPartner.mode) {
        offlinePartners.push(channelPartner);
      }
    }
    return offlinePartners;
  }

  public getOfflineActiveChannelPartners() {
    const offlinePartners: ChannelPartner[] = [];
    for (const channelPartner of this.getActiveCreditLine().channelPartners) {
      if (
        this.MODE.OFFLINE === channelPartner.mode &&
        this.ACTIVE_STATUS.ACTIVE === channelPartner.activeStatus
      ) {
        offlinePartners.push(channelPartner);
      }
    }
    return offlinePartners;
  }

  public getChannelPartnerFromName(channelPartnerName: string) {
    if (!isEmpty(channelPartnerName)) {
      return null;
    }
    const creditLineInfo: CreditLineInfo = this.getActiveCreditLine();
    for (const channelPartner of creditLineInfo.channelPartners) {
      if (
        channelPartnerName.toLowerCase() ===
        channelPartner.channelPartner.toLowerCase()
      ) {
        return channelPartner;
      }
    }
    return null;
  }

  public isInsufficientBalanceCashLine() {
    if (this.isCashLine()) {
      const channelPartnerList: ChannelPartner[] =
        this.getActiveCreditLine().channelPartners;
      if (channelPartnerList.length === 1) {
        const channelPartner = channelPartnerList[0];
        const creditLimit = channelPartner.limit;
        const availableLimit = creditLimit.available;
        const termsMinimum = this.getTermsMinimum(channelPartner.tenures);
        return availableLimit < termsMinimum;
      }
    }
    return false;
  }

  public getTermsMinimum(balanceTerms: CreditBalanceTerm[]) {
    let minimum = 0;
    if (isEmpty(balanceTerms) && balanceTerms.length === 0) {
      return 0;
    }
    for (const term of balanceTerms) {
      if (term.minimumWithdrawal < minimum || minimum === 0) {
        minimum = term.minimumWithdrawal;
      }
    }
    return minimum;
  }

  private getCreditLines(): CreditLineInfo[] {
    let creditLineInfo = BigCookie.getInstance().creditLineInfo;
    if (isEmpty(creditLineInfo)) {
      creditLineInfo = [];
    }
    return creditLineInfo;
  }
}
