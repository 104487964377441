import { isEmpty, isFunction } from '@/lib/helpers';
const availablePolyfills = [
  {
    test: () => isFunction(window.fetch),
    load: () => require.ensure([], () => require('whatwg-fetch')),
  },
];

/**
 * Load polyfills and call initialize.
 * @param {Function} initialize
 */
export default function loadPolyfills(initialize: any) {
  const polyfillPromises = availablePolyfills
    .filter((polyfill: any) => polyfill.test())
    .map((polyfill: any) => polyfill.load());

  if (!isEmpty(polyfillPromises)) {
    Promise.all(polyfillPromises)
      .then(() => initialize())
      .catch((err: any) => {
        console.log(err);
      });
  } else {
    // load without polyfills
    initialize();
  }
}
