import GeoLocation from './geo_location';
import { isEmpty } from '@/lib/helpers';
export default class Address {
  private _addressLine1: string;
  private _addressLine2?: string;
  private _city: string;
  private _state: string;
  private _pincode: number;
  private _geoLocation?: GeoLocation;

  constructor() {
    this._addressLine1 = '';
    this._addressLine2 = '';
    this._city = '';
    this._state = '';
    this._pincode = NaN;
    this._geoLocation = new GeoLocation();
  }

  public get addressLine1(): string {
    return this._addressLine1;
  }

  public set addressLine1(addressLine1: string) {
    this._addressLine1 = addressLine1;
  }

  public get addressLine2(): string {
    return this._addressLine2 || '';
  }

  public set addressLine2(addressLine2: string) {
    this._addressLine2 = addressLine2;
  }

  public get city(): string {
    return this._city;
  }

  public set city(city: string) {
    this._city = city;
  }

  public get state(): string {
    return this._state;
  }

  public set state(state: string) {
    this._state = state;
  }

  public get pincode(): number {
    return this._pincode;
  }

  public set pincode(pincode: number) {
    this._pincode = pincode;
  }

  public get geoLocation(): GeoLocation {
    return this._geoLocation || { latitude: 0, longitude: 0 };
  }

  public set geoLocation(geoLocation: GeoLocation) {
    this._geoLocation = geoLocation;
  }

  public setAddress(address: Address) {
    if (address) {
      if (!isEmpty(address.addressLine1)) {
        this._addressLine1 = address.addressLine1;
      }
      if (!isEmpty(address.addressLine2)) {
        this._addressLine2 = address.addressLine2;
      }
      if (!isEmpty(address.city)) {
        this._city = address.city;
      }
      if (!isEmpty(address.state)) {
        this._state = address.state;
      }
      if (!isEmpty(address.pincode)) {
        this._pincode = address.pincode;
      }
    }
    return this;
  }
}
