import { ApplicationStateModel } from '@/interfaces/application_state';
import {
  BinaryPrecision,
  ApplicationStatusCodeComponent,
  Precision,
} from '@/enums/application_state';
import { hasOwnProperty, isEmpty, isNull } from '@/lib/helpers';
import {
  creditLineStatuses,
  applicationStates,
  appMode,
} from '@/app_config/app_state_config';
import { LINE_TYPES, CONFIN_ELIGIBLE_PARTNERS } from '@/lib/app_consts';
import CreditLineManager from '../manager/credit_line_manager';

export default class ApplicationState implements ApplicationStateModel {
  public static getInstance(): ApplicationState {
    if (!ApplicationState.instance) {
      ApplicationState.instance = new ApplicationState();
    }
    return ApplicationState.instance;
  }
  private static instance: ApplicationState | null;
  private _availablePartnerCodes?: string[] | null;
  private _qualBlockMilestones?: string[] | null;
  private _applicationStatus?: string;
  private _creditLineStatus?: string;
  private _applicationStatusCode?: string;
  private _tuSubmitStatus?: string;
  private _perfiosSubmitStatus?: string;
  private _canEsign?: boolean;
  private _esignStatus?: string;
  private _currentPartnerCode?: string;
  private _newApplicationStatus?: string;
  private _appPermissionsRequired?: string[] | null;
  private _userInterestStatus?: Record<string, string> | null;

  private constructor() {
    this._availablePartnerCodes = null;
    this._qualBlockMilestones = null;
    this._applicationStatus = '';
    this._creditLineStatus = '';
    this._applicationStatusCode = '';
    this._tuSubmitStatus = '';
    this._perfiosSubmitStatus = '';
    this._canEsign = false;
    this._esignStatus = '';
    this._currentPartnerCode = '';
    this._newApplicationStatus = '';
    this._appPermissionsRequired = null;
    this._userInterestStatus = null;
  }

  get availablePartnerCodes() {
    return this._availablePartnerCodes || [];
  }

  set availablePartnerCodes(availablePartnerCodes: string[]) {
    this.availablePartnerCodes = availablePartnerCodes;
  }

  get qualBlockMilestones() {
    return this._qualBlockMilestones || [];
  }

  set qualBlockMilestones(qualBlockMilestones: string[]) {
    this._qualBlockMilestones = qualBlockMilestones;
  }

  get applicationStatus() {
    return this._applicationStatus || '';
  }

  set applicationStatus(applicationStatus: string) {
    this._applicationStatus = applicationStatus;
  }

  get creditLineStatus() {
    return this._creditLineStatus || '';
  }

  set creditLineStatus(creditLineStatus: string) {
    this._creditLineStatus = creditLineStatus;
  }

  get applicationStatusCode() {
    return this._applicationStatusCode || '';
  }

  set applicationStatusCode(applicationStatusCode: string) {
    this._applicationStatusCode = applicationStatusCode;
  }

  get tuSubmitStatus() {
    return this._tuSubmitStatus || '';
  }

  set tuSubmitStatus(tuSubmitStatus: string) {
    this._tuSubmitStatus = tuSubmitStatus;
  }

  get perfiosSubmitStatus() {
    return this._perfiosSubmitStatus || '';
  }

  set perfiosSubmitStatus(perfiosSubmitStatus: string) {
    this._perfiosSubmitStatus = perfiosSubmitStatus;
  }

  get canEsign() {
    return this._canEsign || false;
  }

  set canEsign(canEsign: boolean) {
    this._canEsign = canEsign;
  }

  get esignStatus() {
    return this._esignStatus || '';
  }

  set esignStatus(esignStatus: string) {
    this._esignStatus = esignStatus;
  }

  get currentPartnerCode() {
    return this._currentPartnerCode || '';
  }

  set currentPartnerCode(currentPartnerCode: string) {
    this._currentPartnerCode = currentPartnerCode;
  }

  get newApplicationStatus() {
    return this._newApplicationStatus || '';
  }

  set newApplicationStatus(newApplicationStatus: string) {
    this._newApplicationStatus = newApplicationStatus;
  }

  get appPermissionsRequired() {
    return this._appPermissionsRequired || [];
  }

  set appPermissionsRequired(appPermissionsRequired: string[]) {
    this._appPermissionsRequired = appPermissionsRequired;
  }

  get userInterestStatus() {
    return this._userInterestStatus || {};
  }

  set userInterestStatus(userInterestStatus: { [key: string]: string }) {
    this._userInterestStatus = userInterestStatus;
  }

  public clearInstance() {
    ApplicationState.instance = null;
  }

  public isQualBlock(qualBlocks: string | string[] | null): boolean {
    if (
      isNull(this._qualBlockMilestones) ||
      isEmpty(this._qualBlockMilestones)
    ) {
      return false;
    }
    if (Array.isArray(qualBlocks)) {
      for (const qualBlock in qualBlocks) {
        if (
          this._qualBlockMilestones &&
          this._qualBlockMilestones.includes(qualBlocks[qualBlock])
        ) {
          return true;
        }
      }
    } else {
      const qualBlock: string = qualBlocks || '';
      const isQualBlocked = this._qualBlockMilestones
        ? !isEmpty(this._qualBlockMilestones) &&
          this._qualBlockMilestones.includes(qualBlock)
        : false;
      return isQualBlocked;
    }
    return false;
  }

  public isCreditLineStarted(): boolean {
    return creditLineStatuses.CM_LINE_STARTED === this._applicationStatus;
  }

  public isInCreditManagement(): boolean {
    return this.isApplicationStatusCodeBinary(
      BinaryPrecision.BETWEEN_INCLUSIVE_OPERAND_1,
      ApplicationStatusCodeComponent.WHOLE,
      applicationStates.CM_LINE_APPROVED,
      applicationStates.REJECTED_COARSE
    );
  }

  public isCustomerInteractionOver(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.EXACTLY,
      applicationStates.AIP_CUSTOMER_INTERACTION_OVER
    );
  }

  public isCustomerInteractionReqd(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.EXACTLY,
      applicationStates.AIP_CUSTOMER_INTERACTION_REQ
    );
  }

  public isBankerPortalRejected(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.GREATER_THAN,
      applicationStates.AIP_REJECTED
    );
  }

  public isAipNotInterested(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.EXACTLY,
      applicationStates.AIP_NOT_INTERESTED
    );
  }

  public isPerfiosRequired(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.EXACTLY,
      applicationStates.AIP_PERFIOS_REQD
    );
  }

  public isPerfiosSubmitted(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.EXACTLY,
      applicationStates.AIP_PERFIOS_SUBMITTED
    );
  }

  public isBankerPortalRejectedCanReroute(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.GREATER_THAN,
      applicationStates.AIP_NOT_INTERESTED
    );
  }

  public isAipRejected(): boolean {
    return this.isApplicationStatusCodeBinary(
      BinaryPrecision.BETWEEN_INCLUSIVE,
      ApplicationStatusCodeComponent.WHOLE,
      applicationStates.UNKNOWN_REJECTED,
      applicationStates.AIP_REJECTED
    );
  }
  public isExactAipSuccess(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.EXACTLY,
      applicationStates.AIP_SUCCESS
    );
  }

  public isAipSuccess(): boolean {
    return this.isApplicationStatusCodeBinary(
      BinaryPrecision.BETWEEN_INCLUSIVE_OPERAND_1,
      ApplicationStatusCodeComponent.WHOLE,
      applicationStates.AIP_SUCCESS,
      applicationStates.CM_LINE_APPROVED
    );
  }
  public isAipAddressReq(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.EXACTLY,
      applicationStates.AIP_ADDRESS_REQD
    );
  }

  public isFinalResultPending(): boolean {
    return (
      !this.isPerfiosRequired() &&
      !this.isCustomerInteractionReqd() &&
      this.isApplicationStatusCodeBinary(
        BinaryPrecision.BETWEEN_INCLUSIVE_OPERAND_1,
        ApplicationStatusCodeComponent.WHOLE,
        applicationStates.AIP_SUBMITTED,
        applicationStates.AIP_SUCCESS
      )
    );
  }

  public isAipSuccessExact(): boolean {
    return this.isApplicationStatusCodeWhole(
      Precision.EXACTLY,
      applicationStates.AIP_SUCCESS
    );
  }

  public isUserInterested(actionables: any[]): boolean {
    const ctxApplicationState: ApplicationState =
      ApplicationState.getInstance();
    const activeCreditProduct =
      CreditLineManager.getInstance().getActiveCreditLine();
    const userInterestStatus = ctxApplicationState.userInterestStatus;
    if (isEmpty(userInterestStatus)) {
      if (
        activeCreditProduct &&
        activeCreditProduct.lineType === LINE_TYPES.CF &&
        CONFIN_ELIGIBLE_PARTNERS.includes(
          ctxApplicationState.currentPartnerCode
        )
      ) {
        return false;
      }
      return (actionables && actionables.length > 0) || false;
    }
    if (
      !isEmpty(activeCreditProduct) &&
      hasOwnProperty(userInterestStatus, activeCreditProduct.lineType)
    ) {
      const currentUserInterestStatus =
        userInterestStatus[activeCreditProduct.lineType];
      if (currentUserInterestStatus) {
        const status = currentUserInterestStatus.toUpperCase() === 'YES';
        if (status) {
          if (
            activeCreditProduct &&
            activeCreditProduct.lineType === LINE_TYPES.CF &&
            CONFIN_ELIGIBLE_PARTNERS.includes(
              ctxApplicationState.currentPartnerCode
            )
          ) {
            return false;
          }
        }
        return status || (actionables && actionables.length > 0);
      }
    }
    return (actionables && actionables.length > 0) || false;
  }

  public isApplicationStatusCodeWhole(
    precision: Precision,
    statusCode: string
  ): boolean {
    return this.isApplicationStatusCode(
      precision,
      ApplicationStatusCodeComponent.WHOLE,
      statusCode
    );
  }

  // TODO: Change statusCode to a definite type
  public isApplicationStatusCode(
    precision: Precision,
    applicationStatusCodeComponent: ApplicationStatusCodeComponent,
    statusCode: string
  ): boolean {
    const comparisonResult: number = this.getApplicationStatusCodeComponent(
      applicationStatusCodeComponent
    ).localeCompare(statusCode);
    switch (precision) {
      case Precision.GREATER_THAN:
        return comparisonResult > 0;
      case Precision.GREATER_THAN_OR_EQUALS:
        return comparisonResult >= 0;
      case Precision.LESSER_THAN:
        return comparisonResult < 0;
      case Precision.LESSER_THAN_OR_EQUALS:
        return comparisonResult <= 0;
      case Precision.EXACTLY:
      default:
        return comparisonResult === 0;
    }
  }

  public isApplicationStatusCodeBinary(
    precision: BinaryPrecision,
    applicationStatusCodeComponent: ApplicationStatusCodeComponent,
    statusCodeOperand1: string,
    statusCodeOperand2: string
  ): boolean {
    switch (precision) {
      case BinaryPrecision.BETWEEN_INCLUSIVE_OPERAND_1:
        return (
          this.isApplicationStatusCode(
            Precision.GREATER_THAN_OR_EQUALS,
            applicationStatusCodeComponent,
            statusCodeOperand1
          ) &&
          this.isApplicationStatusCode(
            Precision.LESSER_THAN,
            applicationStatusCodeComponent,
            statusCodeOperand2
          )
        );
      case BinaryPrecision.BETWEEN_INCLUSIVE_OPERAND_2:
        return (
          this.isApplicationStatusCode(
            Precision.GREATER_THAN,
            applicationStatusCodeComponent,
            statusCodeOperand1
          ) &&
          this.isApplicationStatusCode(
            Precision.LESSER_THAN_OR_EQUALS,
            applicationStatusCodeComponent,
            statusCodeOperand2
          )
        );
      case BinaryPrecision.BETWEEN_INCLUSIVE:
        return (
          this.isApplicationStatusCode(
            Precision.GREATER_THAN_OR_EQUALS,
            applicationStatusCodeComponent,
            statusCodeOperand1
          ) &&
          this.isApplicationStatusCode(
            Precision.LESSER_THAN_OR_EQUALS,
            applicationStatusCodeComponent,
            statusCodeOperand2
          )
        );
      case BinaryPrecision.BETWEEN_EXCLUSIVE:
        return (
          this.isApplicationStatusCode(
            Precision.GREATER_THAN,
            applicationStatusCodeComponent,
            statusCodeOperand1
          ) &&
          this.isApplicationStatusCode(
            Precision.LESSER_THAN,
            applicationStatusCodeComponent,
            statusCodeOperand2
          )
        );
      default:
        return false;
    }
  }

  public getCurrentAppMode() {
    if (this.isInCreditManagement()) {
      return appMode.CREDIT_MANAGEMENT;
    }
    if (
      this.isApplicationStatusCodeWhole(
        Precision.GREATER_THAN_OR_EQUALS,
        applicationStates.AIP_SUCCESS
      )
    ) {
      return appMode.POST_AIP;
    }
    if (
      this.isApplicationStatusCodeWhole(
        Precision.GREATER_THAN_OR_EQUALS,
        applicationStates.AIP_SUBMITTED
      )
    ) {
      return appMode.PROFILE_SUBMISSION;
    }
    return appMode.QUALIFICATION;
  }

  public isPostAip() {
    const currentAppMode: string = this.getCurrentAppMode();
    return (
      appMode.POST_AIP === currentAppMode ||
      appMode.CREDIT_MANAGEMENT === currentAppMode
    );
  }

  public isCreditLineManagement() {
    const currentAppMode: string = this.getCurrentAppMode();
    return appMode.CREDIT_MANAGEMENT === currentAppMode;
  }

  public isApplicationSubmitted() {
    const currentAppMode: string = this.getCurrentAppMode();
    return appMode.PROFILE_SUBMISSION === currentAppMode;
  }

  public setApplicationState(applicationState: ApplicationState) {
    this._availablePartnerCodes =
      applicationState.availablePartnerCodes || null;
    this._qualBlockMilestones = applicationState.qualBlockMilestones || null;
    this._applicationStatus = applicationState.applicationStatus || '';
    this._creditLineStatus = applicationState.creditLineStatus || '';
    this._applicationStatusCode = applicationState.applicationStatusCode || '';
    this._tuSubmitStatus = applicationState.tuSubmitStatus || '';
    this._perfiosSubmitStatus = applicationState.perfiosSubmitStatus || '';
    this._canEsign = applicationState.canEsign;
    this._esignStatus = applicationState.esignStatus || '';
    this._currentPartnerCode = applicationState.currentPartnerCode || '';
    this._newApplicationStatus = applicationState.newApplicationStatus || '';
    this._appPermissionsRequired =
      applicationState.appPermissionsRequired || null;
    this._userInterestStatus = applicationState.userInterestStatus || null;
  }

  private getApplicationStatusCodeComponent(
    applicationStatusCodeComponent: ApplicationStatusCodeComponent
  ): string {
    if (this._applicationStatusCode) {
      const applicationStatusCodeComponents: string[] =
        this._applicationStatusCode.split('.');
      if (applicationStatusCodeComponents.length !== 2) {
        return this._applicationStatusCode;
      }

      switch (applicationStatusCodeComponent) {
        case ApplicationStatusCodeComponent.COARSE:
          return applicationStatusCodeComponents[0];

        case ApplicationStatusCodeComponent.FINE:
          return applicationStatusCodeComponents[1];

        case ApplicationStatusCodeComponent.WHOLE:
        default:
          return this._applicationStatusCode;
      }
    }
    return this._applicationStatusCode || '';
  }
}
