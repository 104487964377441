import { isEmpty } from '@/lib/helpers';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { authState } from '@/store/getters/auth';
import { Nullable } from '@/types';

export default class Customer {
  public static getInstance(): Customer {
    if (!Customer.instance || !isCustomerAuthenticatedSync(authState())) {
      Customer.instance = new Customer();
    }
    return Customer.instance;
  }
  private static instance: Customer | null;

  private _emailId?: Nullable<string>;
  private _phone?: Nullable<string>;
  private _firstName?: Nullable<string>;
  private _middleName?: Nullable<string>;
  private _lastName?: Nullable<string>;
  private _imageUrl?: Nullable<string>;
  private _customerId?: Nullable<string>;
  private _phoneVerified?: boolean;
  private _aadhaarLink?: Nullable<string>;
  private _maritalstatus?: Nullable<string>;
  private _loanStatus?: Nullable<string>;
  private _isAcctLocked?: Nullable<string>;
  private _currentCity?: Nullable<string>;
  private _source?: Nullable<string>;
  private _displayName?: Nullable<string>;
  private _appStatus?: Nullable<string>;
  private _motherMaidenName?: Nullable<string>;
  private _tcAccepted?: boolean;
  private _mobileVerificationSource?: Nullable<string>;
  private _privacyPolicyAccepted?: boolean;
  private _spouseName?: Nullable<string>;
  private _nationality?: Nullable<string>;
  private _idToken?: Nullable<string>;
  private _authType?: Nullable<string>;
  private _clientId?: Nullable<string>;
  private _clientSecret?: Nullable<string>;
  private _inviteCode?: Nullable<string>;
  private _ekycUrl?: Nullable<string>;
  private _tcAcceptedAt?: Nullable<string>;
  private _privacyPolicyAcceptedAt?: Nullable<string>;
  private _whatsAppOptIn?: boolean;

  private constructor() {
    this._emailId = null;
    this._phone = null;
    this._firstName = null;
    this._middleName = null;
    this._lastName = null;
    this._imageUrl = null;
    this._customerId = null;
    this._phoneVerified = false;
    this._aadhaarLink = null;
    this._maritalstatus = null;
    this._loanStatus = null;
    this._isAcctLocked = null;
    this._currentCity = null;
    this._source = null;
    this._displayName = null;
    this._appStatus = null;
    this._motherMaidenName = null;
    this._tcAccepted = false;
    this._mobileVerificationSource = null;
    this._privacyPolicyAccepted = false;
    this._spouseName = null;
    this._nationality = null;
    this._idToken = null;
    this._authType = null;
    this._clientId = null;
    this._clientSecret = null;
    this._inviteCode = null;
    this._ekycUrl = null;
    this._tcAcceptedAt = null;
    this._privacyPolicyAcceptedAt = null;
    this._whatsAppOptIn = false;
  }

  public setCustomerDetails(customerDetails: Customer | null) {
    if (customerDetails && !isEmpty(customerDetails)) {
      this._emailId = customerDetails.emailId || null;
      this._phone = customerDetails.phone || null;
      this._firstName = customerDetails.firstName || null;
      this._middleName = customerDetails.middleName || null;
      this._lastName = customerDetails.lastName || null;
      this._imageUrl = customerDetails.imageUrl || null;
      this._customerId = customerDetails.customerId || null;
      this._phoneVerified = customerDetails.phoneVerified || false;
      this._aadhaarLink = customerDetails.aadhaarLink || null;
      this._maritalstatus = customerDetails.maritalstatus || null;
      this._loanStatus = customerDetails.loanStatus || null;
      this._isAcctLocked = customerDetails.isAcctLocked || null;
      this._currentCity = customerDetails.currentCity || null;
      this._source = customerDetails.source || null;
      this._displayName = customerDetails.displayName || null;
      this._appStatus = customerDetails.appStatus || null;
      this._motherMaidenName = customerDetails.motherMaidenName || null;
      this._tcAccepted = customerDetails.tcAccepted || false;
      this._mobileVerificationSource =
        customerDetails.mobileVerificationSource || null;
      this._privacyPolicyAccepted =
        customerDetails.privacyPolicyAccepted || false;
      this._spouseName = customerDetails.spouseName || null;
      this._nationality = customerDetails.nationality || null;
      this._idToken = customerDetails.idToken || null;
      this._authType = customerDetails.authType || null;
      this._clientId = customerDetails.clientId || null;
      this._clientSecret = customerDetails.clientSecret || null;
      this._inviteCode = customerDetails.inviteCode || null;
      this._ekycUrl = customerDetails.ekycUrl || null;
      this._tcAcceptedAt = customerDetails.tcAcceptedAt || null;
      this._privacyPolicyAcceptedAt =
        customerDetails.privacyPolicyAcceptedAt || null;
      this._whatsAppOptIn = customerDetails.whatsAppOptIn || false;
    }
  }

  public get emailId(): Nullable<string> {
    return this._emailId || null;
  }

  public set emailId(emailId: Nullable<string>) {
    this._emailId = emailId;
  }

  public get phone(): Nullable<string> {
    return this._phone || null;
  }

  public set phone(phone: Nullable<string>) {
    this._phone = phone;
  }

  public get firstName(): Nullable<string> {
    return this._firstName || null;
  }

  public set firstName(firstName: Nullable<string>) {
    this._firstName = firstName;
  }

  public get middleName(): Nullable<string> {
    return this._middleName || null;
  }

  public set middleName(middleName: Nullable<string>) {
    this._middleName = middleName;
  }

  public get lastName(): Nullable<string> {
    return this._lastName || null;
  }

  public set lastName(lastName: Nullable<string>) {
    this._lastName = lastName;
  }

  public get imageUrl(): Nullable<string> {
    return this._imageUrl || null;
  }

  public set imageUrl(imageUrl: Nullable<string>) {
    this._imageUrl = imageUrl;
  }

  public get customerId(): Nullable<string> {
    return this._customerId || null;
  }

  public set customerId(customerId: Nullable<string>) {
    this._customerId = customerId;
  }

  public get phoneVerified(): boolean {
    return this._phoneVerified || false;
  }

  public set phoneVerified(phoneVerified: boolean) {
    this._phoneVerified = phoneVerified;
  }

  public get aadhaarLink(): Nullable<string> {
    return this._aadhaarLink || null;
  }

  public set aadhaarLink(aadhaarLink: Nullable<string>) {
    this._aadhaarLink = aadhaarLink;
  }

  public get maritalstatus(): Nullable<string> {
    return this._maritalstatus || null;
  }

  public set maritalstatus(maritalstatus: Nullable<string>) {
    this._maritalstatus = maritalstatus;
  }

  public get loanStatus(): Nullable<string> {
    return this._loanStatus || null;
  }

  public set loanStatus(loanStatus: Nullable<string>) {
    this._loanStatus = loanStatus;
  }

  public get isAcctLocked(): Nullable<string> {
    return this._isAcctLocked || 'OPEN';
  }

  public set isAcctLocked(isAcctLocked: Nullable<string>) {
    this._isAcctLocked = isAcctLocked;
  }

  public get currentCity(): Nullable<string> {
    return this._currentCity || null;
  }

  public set currentCity(currentCity: Nullable<string>) {
    this._currentCity = currentCity;
  }

  public get source(): Nullable<string> {
    return this._source || null;
  }

  public set source(source: Nullable<string>) {
    this._source = source;
  }

  public get displayName(): Nullable<string> {
    return this._displayName || null;
  }

  public set displayName(displayName: Nullable<string>) {
    this._displayName = displayName;
  }

  public get appStatus(): Nullable<string> {
    return this._appStatus || null;
  }

  public set appStatus(appStatus: Nullable<string>) {
    this._appStatus = appStatus;
  }

  public get motherMaidenName(): Nullable<string> {
    return this._motherMaidenName || null;
  }

  public set motherMaidenName(motherMaidenName: Nullable<string>) {
    this._motherMaidenName = motherMaidenName;
  }

  public get tcAccepted(): boolean {
    return this._tcAccepted || false;
  }

  public set tcAccepted(tcAccepted: boolean) {
    this._tcAccepted = tcAccepted;
  }

  public get mobileVerificationSource(): Nullable<string> {
    return this._mobileVerificationSource || null;
  }

  public set mobileVerificationSource(
    mobileVerificationSource: Nullable<string>
  ) {
    this._mobileVerificationSource = mobileVerificationSource;
  }

  public get privacyPolicyAccepted(): boolean {
    return this._privacyPolicyAccepted || false;
  }

  public set privacyPolicyAccepted(privacyPolicyAccepted: boolean) {
    this._privacyPolicyAccepted = privacyPolicyAccepted;
  }

  public get spouseName(): Nullable<string> {
    return this._spouseName || null;
  }

  public set spouseName(spouseName: Nullable<string>) {
    this._spouseName = spouseName;
  }

  public get nationality(): Nullable<string> {
    return this._nationality || null;
  }

  public set nationality(nationality: Nullable<string>) {
    this._nationality = nationality;
  }

  public get idToken(): Nullable<string> {
    return this._idToken || null;
  }

  public set idToken(idToken: Nullable<string>) {
    this._idToken = idToken;
  }

  public get authType(): Nullable<string> {
    return this._authType || null;
  }

  public set authType(authType: Nullable<string>) {
    this._authType = authType;
  }

  public get clientId(): Nullable<string> {
    return this._clientId || null;
  }

  public set clientId(clientId: Nullable<string>) {
    this._clientId = clientId;
  }

  public get clientSecret(): Nullable<string> {
    return this._clientSecret || null;
  }

  public set clientSecret(clientSecret: Nullable<string>) {
    this._clientSecret = clientSecret;
  }

  public get inviteCode(): Nullable<string> {
    return this._inviteCode || null;
  }

  public set inviteCode(inviteCode: Nullable<string>) {
    this._inviteCode = inviteCode;
  }

  public get ekycUrl(): Nullable<string> {
    return this._ekycUrl || null;
  }

  public set ekycUrl(ekycUrl: Nullable<string>) {
    this._ekycUrl = ekycUrl;
  }

  public get tcAcceptedAt(): Nullable<string> {
    return this._tcAcceptedAt || null;
  }

  public set tcAcceptedAt(tcAcceptedAt: Nullable<string>) {
    this._tcAcceptedAt = tcAcceptedAt;
  }

  public get privacyPolicyAcceptedAt(): Nullable<string> {
    return this._privacyPolicyAcceptedAt || null;
  }

  public set privacyPolicyAcceptedAt(
    privacyPolicyAcceptedAt: Nullable<string>
  ) {
    this._privacyPolicyAcceptedAt = privacyPolicyAcceptedAt;
  }

  public get whatsAppOptIn(): boolean {
    return this._whatsAppOptIn || false;
  }

  public set whatsAppOptIn(whatsAppOptIn: boolean) {
    this._whatsAppOptIn = whatsAppOptIn;
  }

  public clearInstance() {
    Customer.instance = null;
  }
}
