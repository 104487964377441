import cloneDeep from 'lodash.clonedeep';
import { setLocalKey, getLocalKey, deleteLocalKey } from '@/store/local_store';
import { isEmpty, deleteInPath } from '@/lib/helpers';
import { MT_PROFILE } from '@/store/store_keys';
import MtProfile from './models/mt_profile';
import Address from './models/address';
import Duration from './models/duration';
import eventBus from '../lib/event_bus';
import { get } from '../lib/helpers';
import BigCookie from './models/big_cookie';

export default class MtProfileBuilder {
  public static instance: MtProfileBuilder;

  public static getInstance() {
    if (!MtProfileBuilder.instance) {
      MtProfileBuilder.instance = new MtProfileBuilder();
    }
    return MtProfileBuilder.instance;
  }

  private profile: any;

  private constructor() {
    this.profile = {};
  }

  public addProperty(model: string, value: any, property?: string) {
    if (property) {
      this.profile = {
        ...this.profile,
        [model]: {
          ...this.profile[model],
          [property]: cloneDeep(value),
        },
      };
    } else {
      this.profile = {
        ...this.profile,
        [model]: cloneDeep(value),
      };
    }
    return this;
  }

  public removeProperty(model: string, property: string) {
    if (this.profile[model][property]) {
      deleteInPath(this.profile, `${model}.${property}`);
    }
    return this;
  }

  public build(persistProfile: boolean = true) {
    MtProfile.getInstance().updateMtProfile(this.profile);
    if (persistProfile) {
      this.persistMtProfile();
    }
    return this.profile;
  }

  public generateNewProfile() {
    deleteLocalKey(MT_PROFILE);
    MtProfileBuilder.instance = new MtProfileBuilder();
    return new MtProfileBuilder();
  }

  public persistMtProfile() {
    setLocalKey(MT_PROFILE, this.profile);
  }

  public retrievePersistedProfile() {
    const profile = getLocalKey(MT_PROFILE) || {};
    if (!isEmpty(profile)) {
      this.profile = cloneDeep(profile);
      MtProfile.getInstance().updateMtProfile(this.profile);
    } else {
      this.profile = {};
      MtProfile.getInstance().clearInstance();
      MtProfile.getInstance().setMtProfile();
    }
    return this.profile;
  }

  public buildApplicationDetails() {
    const customer = BigCookie.getInstance().customerDetails;
    const aadhaarCard = BigCookie.getInstance().aadhaarDetails;
    const creditProfile = BigCookie.getInstance().creditProfileModel;
    const homeDetails = BigCookie.getInstance().homeDetails;
    const businessCard = BigCookie.getInstance().businessDetails;
    const panCard = BigCookie.getInstance().panDetails;
    const incomeInfo = BigCookie.getInstance().incomeInfo;

    if (!isEmpty(customer.firstName)) {
      this.addProperty(
        MtProfile.MODEL_CUSTOMER,
        customer.firstName,
        MtProfile.CUSTOMER_FIRST_NAME
      );
    }
    if (!isEmpty(customer.middleName)) {
      this.addProperty(
        MtProfile.MODEL_CUSTOMER,
        customer.middleName,
        MtProfile.CUSTOMER_MIDDLE_NAME
      );
    }
    if (!isEmpty(customer.lastName)) {
      this.addProperty(
        MtProfile.MODEL_CUSTOMER,
        customer.lastName,
        MtProfile.CUSTOMER_LAST_NAME
      );
    }
    if (!isEmpty(aadhaarCard.firstName)) {
      this.addProperty(
        MtProfile.MODEL_AADHAAR_CARD,
        aadhaarCard.firstName,
        MtProfile.AADHAAR_CARD_FIRST_NAME
      );
    }
    if (!isEmpty(aadhaarCard.middleName)) {
      this.addProperty(
        MtProfile.MODEL_AADHAAR_CARD,
        aadhaarCard.middleName,
        MtProfile.AADHAAR_CARD_MIDDLE_NAME
      );
    }
    if (!isEmpty(aadhaarCard.lastName)) {
      this.addProperty(
        MtProfile.MODEL_AADHAAR_CARD,
        aadhaarCard.lastName,
        MtProfile.AADHAAR_CARD_LAST_NAME
      );
    }
    if (!isEmpty(customer.phone)) {
      this.addProperty(
        MtProfile.MODEL_CUSTOMER,
        customer.phone,
        MtProfile.CUSTOMER_PHONE
      );
    }
    if (!isEmpty(aadhaarCard.gender) && aadhaarCard.gender !== 'UNKNOWN') {
      this.addProperty(
        MtProfile.MODEL_AADHAAR_CARD,
        aadhaarCard.gender,
        MtProfile.AADHAAR_CARD_GENDER
      );
    }
    if (!isEmpty(aadhaarCard.dateOfBirth)) {
      this.addProperty(
        MtProfile.MODEL_AADHAAR_CARD,
        aadhaarCard.dateOfBirth,
        MtProfile.AADHAAR_CARD_DATE_OF_BIRTH
      );
    }
    if (!isEmpty(customer.currentCity)) {
      this.addProperty(
        MtProfile.MODEL_CUSTOMER,
        customer.currentCity,
        MtProfile.CUSTOMER_CURRENT_CITY
      );
    }
    if (
      !isEmpty(creditProfile.hasCreditCard) &&
      creditProfile.hasCreditCard !== 'UNKNOWN'
    ) {
      this.addProperty(
        MtProfile.MODEL_CREDIT_PROFILE,
        creditProfile.hasCreditCard,
        MtProfile.CREDIT_PROFILE_HAS_CREDIT_CARD
      );
    }
    if (
      !isEmpty(creditProfile.hasLoan) &&
      creditProfile.hasLoan !== 'UNKNOWN'
    ) {
      this.addProperty(
        MtProfile.MODEL_CREDIT_PROFILE,
        creditProfile.hasLoan,
        MtProfile.CREDIT_PROFILE_HAS_LOAN
      );
    }
    if (!isEmpty(homeDetails.residenceType)) {
      this.addProperty(
        MtProfile.MODEL_HOME,
        homeDetails.residenceType,
        MtProfile.HOME_RESIDENCE_TYPE
      );
    }
    if (!isEmpty(homeDetails.address)) {
      this.addProperty(
        MtProfile.MODEL_HOME,
        this.getAddress(homeDetails.address),
        MtProfile.HOME_ADDRESS
      );
    }
    if (!isEmpty(homeDetails.currentAddressDuration)) {
      const currentAddressDuration = get(
        this.profile,
        'homeModel.currentAddressDuration',
        new Duration()
      );
      const months =
        currentAddressDuration._months || currentAddressDuration.months;
      const years =
        currentAddressDuration._years || currentAddressDuration.years;
      const addressDuration = new Duration(months, years);
      this.addProperty(
        MtProfile.MODEL_HOME,
        this.getDuration(homeDetails.currentAddressDuration, addressDuration),
        MtProfile.HOME_CURRENT_ADDRESS_DURATION
      );
    }
    if (!isEmpty(homeDetails.currentCityDuration)) {
      const currentCityDuration = get(
        this.profile,
        'homeModel.currentCityDuration',
        new Duration()
      );
      const months = currentCityDuration._months || currentCityDuration.months;
      const years = currentCityDuration._months || currentCityDuration.months;
      const addressDuration = new Duration(months, years);
      this.addProperty(
        MtProfile.MODEL_HOME,
        this.getDuration(homeDetails.currentCityDuration, addressDuration),
        MtProfile.HOME_CURRENT_CITY_DURATION
      );
    }
    if (!isEmpty(businessCard.companyName)) {
      this.addProperty(
        MtProfile.MODEL_BUSINESS_CARD,
        businessCard.companyName,
        MtProfile.BUSINESS_CARD_COMPANY_NAME
      );
    }
    if (!isEmpty(businessCard.companyType)) {
      this.addProperty(
        MtProfile.MODEL_BUSINESS_CARD,
        businessCard.companyType,
        MtProfile.BUSINESS_CARD_COMPANY_TYPE
      );
    }
    if (!isEmpty(businessCard.currentExperienceDuration)) {
      const currentExperienceDuration = get(
        this.profile,
        'businessCardModel.currentExperienceDuration',
        new Duration()
      );
      const months =
        currentExperienceDuration._months || currentExperienceDuration.months;
      const years =
        currentExperienceDuration._years || currentExperienceDuration.years;
      const addressDuration = new Duration(months, years);
      this.addProperty(
        MtProfile.MODEL_BUSINESS_CARD,
        this.getDuration(
          businessCard.currentExperienceDuration,
          addressDuration
        ),
        MtProfile.BUSINESS_CARD_CURRENT_EXPERIENCE_DURATION
      );
    }
    if (!isEmpty(businessCard.totalExperienceDuration)) {
      const totalExperienceDuration = get(
        this.profile,
        'businessCardModel.totalExperienceDuration',
        new Duration()
      );
      const months =
        totalExperienceDuration._months || totalExperienceDuration.months;
      const years =
        totalExperienceDuration._years || totalExperienceDuration.years;
      const addressDuration = new Duration(months, years);
      this.addProperty(
        MtProfile.MODEL_BUSINESS_CARD,
        this.getDuration(businessCard.totalExperienceDuration, addressDuration),
        MtProfile.BUSINESS_CARD_TOTAL_EXPERIENCE_DURATION
      );
    }
    if (!isEmpty(businessCard.employmentType)) {
      this.addProperty(
        MtProfile.MODEL_BUSINESS_CARD,
        businessCard.employmentType,
        MtProfile.BUSINESS_CARD_EMPLOYMENT_TYPE
      );
    }
    if (!isEmpty(businessCard.jobType)) {
      this.addProperty(
        MtProfile.MODEL_BUSINESS_CARD,
        businessCard.jobType,
        MtProfile.BUSINESS_CARD_JOB_TYPE
      );
    }
    if (!isEmpty(businessCard.jobTitle)) {
      this.addProperty(
        MtProfile.MODEL_BUSINESS_CARD,
        businessCard.jobTitle,
        MtProfile.BUSINESS_CARD_JOB_TITLE
      );
    }
    if (!isEmpty(businessCard.officeEmail)) {
      this.addProperty(
        MtProfile.MODEL_BUSINESS_CARD,
        businessCard.officeEmail,
        MtProfile.BUSINESS_CARD_EMAIL
      );
    }
    if (!isEmpty(panCard.panNumber)) {
      this.addProperty(
        MtProfile.MODEL_PAN_CARD,
        panCard.panNumber,
        MtProfile.PAN_CARD_PAN_NUMBER
      );
    }
    if (!isEmpty(incomeInfo.declared)) {
      this.addProperty(
        MtProfile.MODEL_INCOME_INFO,
        incomeInfo.declared,
        MtProfile.INCOME_INFO_DECLARED
      );
    }
    if (!isEmpty(incomeInfo.mode)) {
      this.addProperty(
        MtProfile.MODEL_INCOME_INFO,
        incomeInfo.mode,
        MtProfile.INCOME_INFO_MODE
      );
    }
    if (!isEmpty(incomeInfo.bankIfscPrefixes)) {
      this.addProperty(
        MtProfile.MODEL_INCOME_INFO,
        incomeInfo.bankIfscPrefixes,
        MtProfile.INCOME_INFO_BANK_IFSC_PREFIXES
      );
    }
    this.build();
    eventBus.$emit('big-cookie-updated', true);
  }

  private getAddress(address: Address) {
    const address1: Address = get(this.profile, 'homeModel.address', {});
    if (!isEmpty(address.addressLine1)) {
      address1.addressLine1 = address.addressLine1;
    }
    if (!isEmpty(address.addressLine2)) {
      address1.addressLine2 = address.addressLine2;
    }
    if (!isEmpty(address.city)) {
      address1.city = address.city;
    }
    if (!isEmpty(address.state)) {
      address1.state = address.state;
    }
    if (!isEmpty(address.pincode)) {
      address1.pincode = address.pincode;
    }
    return address1;
  }

  private getDuration(duration1: Duration, duration2: Duration) {
    if (duration1.months > 0 || duration1.years > 0) {
      return { months: duration1.months, years: duration1.years };
    }
    return { months: duration2.months, years: duration2.years };
  }
}
