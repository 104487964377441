import { isNil, head, isEmpty } from '@/lib/helpers';
import { Nullable } from '@/types';

import AadhaarCard from './aadhaar_card';
import BusinessCard from './business_card';
import Home from './home';
import Customer from './customer';
import PanCard from './pan_card';
import BankDetail from './bank_detail';
import LoanRequest from './loan_request';
import LoanResponse from './loan_response';
import FinanceHistory from './finance_history';
import BankInfo from './bank_info';
import CreditProfile from './credit_profile';
import CustomerProfile from './customer_profile';
import ApplicationModel from './application_model';
import ApplicationState from './application_state';
import IncomeInfo from './income_info';
import FulfillmentModel from './fulfillment_model';
import cloneDeep from 'lodash.clonedeep';
import CreditLineInfo from './credit_line_info';
import Actionable from './actionable';
import MtProfileBuilder from '../mt_profile_builder';
import { Options } from '@/interfaces/app_config';
// import LoanInsuranceDetails from './loan_insurance_details';
import AppPermission from './app_permission';

export default class BigCookie {
  public static readonly MODEL_CUSTOMER_ID = 'customerId';
  public static readonly MODEL_AADHAAR_CARD = 'aadhaarDetails';
  public static readonly MODEL_BUSINESS_CARD = 'businessDetails';
  public static readonly MODEL_HOME = 'homeDetails';
  public static readonly MODEL_CUSTOMER = 'customerDetails';
  public static readonly MODEL_PAN_CARD = 'panDetails';
  public static readonly MODEL_BANK_DETAILS = 'bankDetails';
  public static readonly MODEL_LOAN_REQUEST = 'loanRequestDetails';
  public static readonly MODEL_LOAN_RESPONSE = 'loanResponseDetails';
  public static readonly MODEL_FINANCE_HISTORY = 'financeHistoryModel';
  public static readonly MODEL_BANK_INFO = 'beneficiaryInfoModel';
  public static readonly MODEL_CREDIT_PROFILE = 'creditProfileModel';
  public static readonly MODEL_CUSTOMER_PROFILE = 'customerProfileModel';
  public static readonly MODEL_APPLICATION_MODEL = 'applicationModel';
  public static readonly MODEL_APPLICATION_STATE = 'applicationState';
  public static readonly MODEL_ACTIONABLES = 'actionables';
  public static readonly CAN_RE_APPLY = 'canReApply';
  public static readonly CAN_RE_ROUTE = 'canReRoute';
  public static readonly MODEL_INCOME_INFO = 'incomeInfo';
  public static readonly MODEL_FULFILLMENT_MODEL = 'fulfillmentReferenceModel';
  public static readonly CURRENT_OBLIGATION = 'currentObligation';
  public static readonly MAX_ALLOWED_OBLIGATION = 'maxAllowedObligation';
  public static readonly MODEL_INSURANCE_DETAILS = 'insuranceDetails';
  public static readonly OFFICE_ADDRESS_REQUIRED = 'officeAddressRequired';
  public static readonly MODEL_APP_PERMISSIONS = 'appPermissions';
  public static getInstance() {
    if (!BigCookie.instance) {
      BigCookie.instance = new BigCookie();
    }
    return BigCookie.instance;
  }

  private static instance: BigCookie | null;

  private _customerId: string;
  private _aadhaarDetails: AadhaarCard;
  private _businessDetails: BusinessCard;
  private _homeDetails: Home;
  private _customerDetails: Customer;
  private _panDetails: PanCard;
  private _bankDetails: BankDetail[];
  private _loanRequestDetails: LoanRequest;
  private _loanResponseDetails: LoanResponse;
  private _financeHistoryModel: FinanceHistory;
  private _beneficiaryInfoModel: BankInfo;
  private _creditProfileModel: CreditProfile;
  private _customerProfileModel: CustomerProfile;
  private _applicationModel: ApplicationModel;
  private _applicationState: ApplicationState;
  private _actionables: Actionable[];
  private _canReApply: boolean;
  private _canReRoute: boolean;
  private _incomeInfo: IncomeInfo;
  private _fulfillmentReferenceModel: FulfillmentModel;
  private _creditLineInfo: CreditLineInfo[];
  private _creditLineOptions: Options[];
  private _currentObligation: number;
  private _maxAllowedObligation: number;
  private _officeAddressRequired: boolean;
  // private _insuranceDetails: LoanInsuranceDetails;
  private _appPermissions: AppPermission[];

  private constructor() {
    this._customerId = '';
    this._aadhaarDetails = AadhaarCard.getInstance();
    this._businessDetails = BusinessCard.getInstance();
    this._homeDetails = Home.getInstance();
    this._customerDetails = Customer.getInstance();
    this._panDetails = PanCard.getInstance();
    this._bankDetails = [];
    this._loanRequestDetails = LoanRequest.getInstance();
    this._loanResponseDetails = LoanResponse.getInstance();
    this._financeHistoryModel = FinanceHistory.getInstance();
    this._beneficiaryInfoModel = BankInfo.getInstance();
    this._creditProfileModel = CreditProfile.getInstance();
    this._customerProfileModel = CustomerProfile.getInstance();
    this._applicationModel = ApplicationModel.getInstance();
    this._applicationState = ApplicationState.getInstance();
    this._actionables = [];
    this._canReApply = false;
    this._canReRoute = false;
    this._incomeInfo = IncomeInfo.getInstance();
    this._fulfillmentReferenceModel = new FulfillmentModel();
    this._creditLineInfo = [];
    this._creditLineOptions = [];
    this._currentObligation = 0;
    this._maxAllowedObligation = 0;
    this._officeAddressRequired = true;
    // this._insuranceDetails = LoanInsuranceDetails.getInstance();
    this._appPermissions = [];
  }

  public get customerId(): string {
    return this._customerId;
  }

  public set customerId(customerId: string) {
    this._customerId = customerId;
  }

  public get aadhaarDetails(): AadhaarCard {
    return this._aadhaarDetails;
  }

  public set aadhaarDetails(aadhaarDetails: AadhaarCard) {
    this._aadhaarDetails.setAadhaarDetails(aadhaarDetails);
  }

  public get businessDetails(): BusinessCard {
    return this._businessDetails;
  }

  public set businessDetails(businessDetails: BusinessCard) {
    this._businessDetails = businessDetails;
  }

  public get homeDetails(): Home {
    return this._homeDetails;
  }

  public set homeDetails(homeDetails: Home) {
    this._homeDetails = homeDetails;
  }

  public get customerDetails(): Customer {
    return this._customerDetails;
  }

  public set customerDetails(customerDetails: Customer) {
    this._customerDetails = customerDetails;
  }

  public get panDetails(): PanCard {
    return this._panDetails;
  }

  public set panDetails(panDetails: PanCard) {
    this._panDetails = panDetails;
  }

  public get bankDetails(): BankDetail[] {
    return this._bankDetails;
  }

  public set bankDetails(bankDetails: BankDetail[]) {
    this._bankDetails = bankDetails;
  }

  public get loanRequestDetails(): LoanRequest {
    return this._loanRequestDetails;
  }

  public set loanRequestDetails(loanRequestDetails: LoanRequest) {
    this._loanRequestDetails = loanRequestDetails;
  }

  public get loanResponseDetails(): LoanResponse {
    return this._loanResponseDetails;
  }

  public set loanResponseDetails(loanResponseDetails: LoanResponse) {
    this._loanResponseDetails = loanResponseDetails;
  }

  public get financeHistoryModel(): FinanceHistory {
    return this._financeHistoryModel;
  }

  public set financeHistoryModel(financeHistoryModel: FinanceHistory) {
    this._financeHistoryModel = financeHistoryModel;
  }

  public get beneficiaryInfoModel(): BankInfo {
    return this._beneficiaryInfoModel;
  }

  public set beneficiaryInfoModel(beneficiaryInfoModel: BankInfo) {
    this._beneficiaryInfoModel = beneficiaryInfoModel;
  }

  public get creditProfileModel(): CreditProfile {
    return this._creditProfileModel;
  }

  public set creditProfileModel(creditProfileModel: CreditProfile) {
    this._creditProfileModel = creditProfileModel;
  }

  public get customerProfileModel(): CustomerProfile {
    return this._customerProfileModel;
  }

  public set customerProfileModel(customerProfileModel: CustomerProfile) {
    this._customerProfileModel = customerProfileModel;
  }

  public get applicationModel(): ApplicationModel {
    return this._applicationModel;
  }

  public set applicationModel(applicationModel: ApplicationModel) {
    this._applicationModel = applicationModel;
  }

  public get applicationState(): ApplicationState {
    return this._applicationState;
  }

  public set applicationState(applicationState: ApplicationState) {
    this._applicationState = applicationState;
  }

  public get actionables(): Actionable[] {
    return this._actionables;
  }

  public set actionables(actionables: Actionable[]) {
    this._actionables = actionables;
  }

  public get canReApply(): boolean {
    return this._canReApply;
  }

  public set canReApply(canReApply: boolean) {
    this._canReApply = canReApply;
  }

  public get canReRoute(): boolean {
    return this._canReRoute;
  }

  public set canReRoute(canReRoute: boolean) {
    this._canReRoute = canReRoute;
  }

  public get incomeInfo(): IncomeInfo {
    return this._incomeInfo;
  }

  public set incomeInfo(incomeInfo: IncomeInfo) {
    this._incomeInfo = incomeInfo;
  }

  public get fulfillmentReferenceModel(): FulfillmentModel {
    return this._fulfillmentReferenceModel;
  }

  public set fulfillmentReferenceModel(
    fulfillmentReferenceModel: FulfillmentModel
  ) {
    this._fulfillmentReferenceModel = fulfillmentReferenceModel;
  }

  public get creditLineInfo(): CreditLineInfo[] {
    return this._creditLineInfo;
  }

  public set creditLineInfo(creditLineInfo: CreditLineInfo[]) {
    this._creditLineInfo = creditLineInfo;
  }

  public get currentObligation(): number {
    return this._currentObligation;
  }

  public set currentObligation(currentObligation: number) {
    this._currentObligation = currentObligation;
  }

  public get maxAllowedObligation(): number {
    return this._maxAllowedObligation;
  }

  public set maxAllowedObligation(maxAllowedObligation: number) {
    this._maxAllowedObligation = maxAllowedObligation;
  }

  public get officeAddressRequired(): boolean {
    return this._officeAddressRequired;
  }

  public set officeAddressRequired(officeAddressRequired: boolean) {
    this._officeAddressRequired = officeAddressRequired;
  }

  public get creditLineOptions(): Options[] {
    return this._creditLineOptions;
  }

  public set creditLineOptions(creditLineOptions: Options[]) {
    this._creditLineOptions = creditLineOptions;
  }

  // public get insuranceDetails(): LoanInsuranceDetails {
  //   return this._insuranceDetails;
  // }

  // public set insuranceDetails(insuranceDetails: LoanInsuranceDetails) {
  //   this._insuranceDetails = insuranceDetails;
  // }

  public get appPermissions(): AppPermission[] {
    return this._appPermissions;
  }

  public set appPermissions(appPermissions: AppPermission[]) {
    this._appPermissions = appPermissions;
  }

  public clearInstance() {
    BigCookie.instance = null;
  }

  public getBankDetail(): Nullable<BankDetail> {
    const bankDetails = BigCookie.getInstance().bankDetails;
    if (isNil(bankDetails) || bankDetails.length <= 0) {
      return null;
    }

    for (const bankDetail of bankDetails) {
      if (bankDetail.isPrimary) {
        return bankDetail;
      }
    }
    return head(bankDetails);
  }

  public setBigCookie(bigCookie: BigCookie) {
    if (bigCookie) {
      MtProfileBuilder.getInstance().buildApplicationDetails();
      this._maxAllowedObligation = this.getPropertyValue(
        bigCookie,
        BigCookie.MAX_ALLOWED_OBLIGATION,
        null
      );
      this._currentObligation = this.getPropertyValue(
        bigCookie,
        BigCookie.CURRENT_OBLIGATION,
        0
      );
      this._customerId = this.getPropertyValue(
        bigCookie,
        BigCookie.MODEL_CUSTOMER_ID,
        ''
      );
      this._canReApply = this.getPropertyValue(
        bigCookie,
        BigCookie.CAN_RE_APPLY,
        false
      );
      this._canReRoute = this.getPropertyValue(
        bigCookie,
        BigCookie.CAN_RE_ROUTE,
        false
      );
      this._officeAddressRequired = this.getPropertyValue(
        bigCookie,
        BigCookie.OFFICE_ADDRESS_REQUIRED,
        true
      );
      this._bankDetails = cloneDeep(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_BANK_DETAILS, [])
      );
      this._actionables = cloneDeep(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_ACTIONABLES, [])
      );
      this._aadhaarDetails.setAadhaarDetails(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_AADHAAR_CARD, null)
      );
      this._businessDetails.setBusinessCardDetails(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_BUSINESS_CARD, null)
      );
      this._homeDetails.setHomeDetails(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_HOME, null)
      );
      this._customerDetails.setCustomerDetails(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_CUSTOMER, null)
      );
      this._panDetails.setPanDetails(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_PAN_CARD, null)
      );
      this._loanRequestDetails.setLoanRequest(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_LOAN_REQUEST, null)
      );
      this._loanResponseDetails.setLoanResponse(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_LOAN_RESPONSE, null)
      );
      this._financeHistoryModel.setFinanceHistory(
        this.getPropertyValue(
          bigCookie,
          BigCookie.MODEL_FINANCE_HISTORY,
          FinanceHistory.getInstance()
        )
      );
      this._beneficiaryInfoModel.setBankInfo(
        this.getPropertyValue(
          bigCookie,
          BigCookie.MODEL_BANK_INFO,
          BankInfo.getInstance()
        )
      );
      this._creditProfileModel.setCreditProfile(
        this.getPropertyValue(
          bigCookie,
          BigCookie.MODEL_CREDIT_PROFILE,
          CreditProfile.getInstance()
        )
      );
      this._customerProfileModel.setCustomerProfile(
        this.getPropertyValue(
          bigCookie,
          BigCookie.MODEL_CUSTOMER_PROFILE,
          CustomerProfile.getInstance()
        )
      );
      this._applicationModel.setApplicationModel(
        this.getPropertyValue(
          bigCookie,
          BigCookie.MODEL_APPLICATION_MODEL,
          ApplicationModel.getInstance()
        )
      );
      this._applicationState.setApplicationState(
        this.getPropertyValue(
          bigCookie,
          BigCookie.MODEL_APPLICATION_STATE,
          ApplicationState.getInstance()
        )
      );
      this._incomeInfo.setIncomeInfo(
        this.getPropertyValue(
          bigCookie,
          BigCookie.MODEL_INCOME_INFO,
          IncomeInfo.getInstance()
        )
      );
      if (!isEmpty(bigCookie.creditLineInfo)) {
        this._creditLineInfo = bigCookie.creditLineInfo;
      }
      // this._insuranceDetails.setInsuranceDetails(
      //   this.getPropertyValue(bigCookie, BigCookie.MODEL_INSURANCE_DETAILS, LoanInsuranceDetails.getInstance())
      // );
      this._appPermissions = cloneDeep(
        this.getPropertyValue(bigCookie, BigCookie.MODEL_APP_PERMISSIONS, [])
      );
    }
  }

  private getPropertyValue(
    bigCookie: BigCookie,
    propertyName: string,
    defaultValue: any
  ) {
    if (bigCookie && (bigCookie as any)[propertyName]) {
      return cloneDeep((bigCookie as any)[propertyName]);
    }
    return defaultValue;
  }
}
